import React, { useState } from "react";

export default function Pagination({
    style,
    onPageChange,
    className,
    pageTotal = [1],
    limit = 1,
    currentPage,
}) {
    return (
        <div className={`d-flex justify-content-center ${className}`} style={style}>
            <nav>
                <ul className="pagination">
                    <li
                        className="page-item"
                        onClick={() => {
                            if (onPageChange) {
                                if (currentPage <= 1) {
                                    onPageChange(1);
                                } else {
                                    onPageChange(currentPage - 1);
                                }
                            }
                        }}
                    >
                        <a className="page-link" href="javascript:void(0)">
                        <i className="fas fa-chevron-left"/>
                        </a>
                    </li>
                    {pageTotal?.map((page, index) => (
                        <React.Fragment>
                            {page <= limit ? (
                                <li
                                    className={`page-item ${currentPage === page ? "active" : null
                                        }`}
                                    key={index}
                                    onClick={() => {
                                        if (onPageChange) onPageChange(page);
                                    }}
                                >
                                    <a className="page-link" href="javascript:void(0)">
                                        {page}
                                    </a>
                                </li>
                            ) : null}
                        </React.Fragment>
                    ))}
                    <li>
                        <select
                            className="form-control py-0"
                            style={{ height: 36.75, color: "#125aa7" }}
                            onChange={(e) => {
                                if (onPageChange) onPageChange(parseInt(e.target.value));
                            }}
                            hidden={pageTotal?.length <= 6 ? true : false}
                        >
                            <option value="0">...</option>
                            {pageTotal?.map((page, index) => (
                                <React.Fragment>
                                    {page >= limit ? (
                                        <option
                                            key={index}
                                            value={page}
                                            selected={currentPage === page ? true : false}
                                        >
                                            {page}
                                        </option>
                                    ) : null}
                                </React.Fragment>
                            ))}
                        </select>
                    </li>
                    <li
                        className="page-item"
                        onClick={() => {
                            if (onPageChange) {
                                if (currentPage >= pageTotal.length) {
                                    onPageChange(currentPage);
                                } else {
                                    onPageChange(currentPage + 1);
                                }
                            }
                        }}
                    >
                        <a className="page-link" href="javascript:void(0)">
                            <i className="fas fa-chevron-right" />

                            
                        </a>
                    </li>
                </ul>
            </nav> 

        </div>
    );
}