import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import placeholder from "../img/placeholder.png";
export default function PreviewImage({ image, className }) {
  const [show, setShow] = useState(false);
  const urlImage = image.split("/").pop();

  return (
    <React.Fragment>
      <div className="avatar sm">
        <a onClick={() => setShow(true)}>
          <img
            src={
              urlImage
                ? image
                : "https://via.placeholder.com/150"
            }
            className={className}
          />
        </a>
      </div>
      <Modal
        show={show}
        centered
        scrollable
        size="lg"
        onHide={() => setShow(false)}
        className="text-center text-primary"
      >
        <Modal.Body className="custom-modal-body text-center text-primary" style={{ backgroundColor: "transparent" }}>
          <a href="javascript:void(0)"
            onClick={() => setShow(false)}
            className="float-end mb-3">
            <i className="icon-x fs-2" />
          </a>
          <img
            src={urlImage ? image : placeholder}
            className="img-fluid w-100"
            alt=""
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
