import { gql } from "apollo-boost";

export const QUERY_TYPE_DOCUMENT = gql`
query DocumentTypes($where: DocumentTypeWhereInput, $orderBy: OrderByInput, $skip: Int, $limit: Int) {
  documentTypes(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
    total
    data {
      _id
      typeDocumentID
      title
      description
      departmentID {
        _id
        title_lao
      }
    }
  }
}
`;
export const QUERY_DOCUMENT_FOLDERS = gql`
query DocumentFolders($where: DocumentFolderWhereInput, $orderBy: OrderByInput, $skip: Int, $limit: Int) {
  documentFolders(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
    total
    data {
      _id
      folderNumber
      title
      departmentID {
        _id
        title_lao
      }
      loggerID {
        _id
        loggerID
        title
      }
    }
  }
}
`;
export const CREATE_TYPE_DOCUMENT = gql`
mutation CreateDocumentType($data: DocumentTypeInput!) {
  createDocumentType(data: $data) {
    _id
  }
}
`;

export const UPDATE_TYPE_DOCUMENT = gql`
mutation UpdateDocumentType($data: DocumentTypeInput!, $where: DocumentTypeWhereInputOne!) {
  updateDocumentType(data: $data, where: $where) {
    _id
  }
}
`;

export const DELETE_TYPE_DOCUMENT = gql`
mutation DeleteDocumentType($where: DocumentTypeWhereInputOne!) {
  deleteDocumentType(where: $where) {
    _id
  }
}
`;
