// import { useLazyQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import { v4 as uuidv4 } from "uuid";
import {
  gcp_docs,
  gcpUpload,
  messageError,
  messageSuccess,
  valiDate,
} from "../../helper";
import { UPDATE_GROUP } from "./apollo";
export default function EditGroup({ data, newLoad, setNewLoad }) {
  const [show, setShow] = useState(false);
  const [updateGroup] = useMutation(UPDATE_GROUP);
  const handleClose = () => setShow(false);
  const [fileNames, setFileNames] = useState("");
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (data) {
      setFile(true);
      setFileNames(data?.profileImage);
    }
  }, [data]);

  const handleUpload = async (event) => {
    try {
      const _fileName =
        uuidv4() + "." + event.target.files[0].type.split("/")[1];
      const _file = event.target.files[0];
      setFile(_file);
      const res = await gcpUpload({
        file: _file,
        bucketName: "ansx",
        pathname: "docs",
        filename: _fileName,
      });
      if (res?.publicUrl) {
        setFile(_file);
        setFileNames(_fileName);
        messageSuccess("ການດຳເນິນງານສຳເລັດແລ້ວ");
      } else {
        messageError("ການດຳເນິນງານບໍ່ສຳເລັດ");
        setFile(null);
        setFileNames("");
      }
    } catch (error) {
      console.log(error);
      messageError("ການດຳເນິນງານບໍ່ສຳເລັດ");
    }
  };

  return (
    <Formik
      initialValues={{
        title: data?.title,
      }}
      enableReinitialize={true}
      validate={(values) => {
        const errors = {};
        if (!values.title) {
          errors.title = "error";
        }
        return errors;
      }}
      onSubmit={async (values) => {
        try {
          let __updateGroup = await updateGroup({
            variables: {
              data: {
                title: String(values?.title),
                profileImage: String(fileNames),
              },
              where: {
                _id: data?._id,
              },
            },
          });

          if (__updateGroup) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setShow(false);
            setNewLoad(!newLoad);
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການເຂົ້າລະບົບມີຄວາມຂັດຂ້ອງ");
        }
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <React.Fragment>
          <button
            className="btn btn-primary "
            onClick={() => setShow(true)}
          >
            <i className="icon-edit" /> ແກ້ໄຂ
          </button>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            animation={false}
            centered
            backdrop="static"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title className="fs-5">
                <svg
                  className="icon-30 me-2"
                  width="30"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z"
                    fill="currentColor"
                  ></path>
                </svg>{" "}
                ແກ້ໄຂກຸ່ມ
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ textAlign: "center" }}>
                <input
                  type="file"
                  id="file-upload"
                  onChange={handleUpload}
                  accept="image/png, image/gif, image/jpeg"
                  hidden
                />
                <label for="file-upload">
                  <div>
                    {file ? (
                      <div className="card bg-transparent shadow-none">
                        <div
                          className="iq-dashed-border"
                          style={{
                            backgroundImage: `url(${gcp_docs + fileNames})`,
                            display: "flex",
                            height: 250,
                            width: 250,
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        />
                      </div>
                    ) : (
                      <div className="card bg-transparent shadow-none">
                        <div className="iq-dashed-border">
                          <div
                            className="align-items-center justify-content-center p-5"
                            style={{
                              width: 250,
                              height: 250,
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <svg
                              class="icon-42 m-5"
                              width="42"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.4"
                                d="M2 7.916V16.084C2 19.623 4.276 22 7.665 22H16.335C19.724 22 22 19.623 22 16.084V7.916C22 4.378 19.723 2 16.334 2H7.665C4.276 2 2 4.378 2 7.916Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M7.72033 12.8555L11.4683 16.6205C11.7503 16.9035 12.2493 16.9035 12.5323 16.6205L16.2803 12.8555C16.5723 12.5615 16.5713 12.0865 16.2773 11.7945C15.9833 11.5025 15.5093 11.5025 15.2163 11.7965L12.7493 14.2735V7.91846C12.7493 7.50346 12.4133 7.16846 11.9993 7.16846C11.5853 7.16846 11.2493 7.50346 11.2493 7.91846V14.2735L8.78333 11.7965C8.63633 11.6495 8.44433 11.5765 8.25133 11.5765C8.06033 11.5765 7.86833 11.6495 7.72233 11.7945C7.42933 12.0865 7.42833 12.5615 7.72033 12.8555Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                            <br />
                            <h6 className="text-body mb-0">ອັບໂຫຼດໄຟລ໌</h6>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </label>
              </div>
              <div className="form-group">
                <label>ຊື່ກຸ່ມ {valiDate()}</label>
                <input
                  type="text"
                  className={
                    errors.title
                      ? "form-control form-control-lg mb-3 mt-2 is-invalid"
                      : "form-control form-control-lg mb-3 mt-2 invalid"
                  }
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  placeholder="ປ້ອນຂໍ້ມູນ..."
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary btn-lg "
                onClick={() => handleSubmit()}
              >
                <i className="icon-check-circle me-1" />
                ບັນທຶກ
              </button>
              <button
                type="button"
                className="btn btn-light btn-lg "
                onClick={() => handleClose()}
              >
                <i className="icon-x me-2" />
                ຍົກເລີກ
              </button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      )}
    </Formik>
  );
}
