import React, { useState } from "react";
import moment from "moment";
import { io } from "socket.io-client";
import Notiflix, { Loading } from "notiflix";
import loading from "../img/loading.gif";
import word from "../img/word.png";
import fileText from "../img/icon-file.png";
import excel from "../img/excel.png";
import ppt from "../img/powerpoint.png";
import pdf from "../img/pdf.png";
import jpg from "../img/jpg.png";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";

var start_year = new Date().getFullYear();
export const ITEM_PER_PAGE = 50;

//google
export const aws_url_emp =
  "https://storage.googleapis.com/anousith-bucket/employesImage/";
// export const gcp_docs =
//   "https://anousith-bucket.s3.ap-southeast-1.amazonaws.com/docs/";
export const gcp_docs = "https://storage.cloud.google.com/ansx/docs/";

export const placeholderImage = (size) => {
  return `https://via.placeholder.com/${size}`;
};

export const socketServer = io("https://api.services.anousith.express");

export const CHECK_BOX_STYLE = {
  width: "20px",
  height: "20px",
};

// best color
export const PRIMARY_COLOR = "#f54f02";
export const SECONDARY_COLOR = "#1fbb62";
export const TERTIARY_COLOR = "#1b97c8";

export const USER_KEY = "DOCUMENT_ADMIN_USER";
//get staff login
export const getStaffLogin = () => {
  let json = localStorage.getItem(USER_KEY);
  let staff = JSON.parse(json);
  return staff;
};
export const userRole = () => {
  const _token = getStaffLogin()?.accessToken;
  const _object = parseJwt(_token);
  return _object?.role;
};

//clear localStorage
export const clearLocalStorage = () => {
  localStorage.removeItem(USER_KEY);
};

//get local branch
export const getLocalBranch = () => {
  let local = localStorage.getItem("BRANCH");
  if (local) {
    local = JSON.parse(local);
    return local;
  } else {
    let userLogin = getStaffLogin();
    userLogin = userLogin?.data?.branch_id;
    return userLogin;
  }
};

// get user role from token
const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const randomBillNo = (pr = "SO-") => {
  for (let i = 1; i <= 6; i++) pr += ~~(Math.random() * 10);
  return pr;
};

// province
export const getLocalProvince = () => {
  let local = localStorage.getItem("PROVINCE");
  if (local) {
    local = JSON.parse(local);
    return local;
  } else {
    let userLogin = getStaffLogin();
    userLogin = userLogin?.data?.province;
    return userLogin;
  }
};

// isMain and isPublic
export const createdAt_gte = (date) => {
  const _date = date ? date : new Date();
  return moment(_date).format("YYYY-MM-DD");
};

export const createdAt_lte = (date) => {
  const _date = date ? date : new Date();
  return moment(_date).add(1, "days").format("YYYY-MM-DD");
};

export const currentDate = (date) => {
  const _date = date ? date : new Date();
  return moment(_date).format("DD-MM-YYYY");
};
export const getUserLogin = () => {
  const _local = JSON.parse(localStorage.getItem(USER_KEY));
  const _data = _local?.data?.employeeLogin?.data;
  return _data;
};
export const unixTimeFormat = (x) => {
  let res = moment.unix(x).format("YYYY-MM-DD HH:SS");
  if (res) return res;
};
// year
export const getYeear = () => {
  let getYeear = [];
  for (let date = 1990; date < start_year + 1; date++) {
    getYeear.push(date);
  }
  return getYeear;
};
//  category_financetype
export const financetype = (item) => {
  if (item === "INCOME") {
    return "ລາຍຮັບ";
  } else {
    return "ລາຍຈ່າຍ";
  }
};

//  unitCurrency
export const unitCurrency = (item) => {
  if (item === "COD") {
    return "ເງິນສົດ";
  } else if (item === "TRANSFER") {
    return "ເງິນໂອນ";
  } else {
    return "-";
  }
};

// status category_financetype
export const statusFinancetype = (item) => {
  if (item === false) {
    return "ປິດໃຊ້ງານ";
  } else {
    return "ເປີດໃຊ້ງານ";
  }
};

// educationlevel
export const getEducationLevel = () => {
  let getYear = [];
  var start_year = new Date().getFullYear();
  for (var i = start_year; i < start_year + 10; i++) {
    getYear.push(i - 1 + "-" + i);
  }
  return getYear;
};

// ກຳນົດ ເພດ
export const setGender = (SetGender) => {
  let res = "";
  switch (SetGender) {
    case "MALE":
      res = "ຊາຍ";
      break;
    case "FEMALE":
      res = "ຍິງ";
      break;
    case "OTHER":
      res = "ອື່ນໆ";
      break;
  }
  return res;
};

// ກຳນົດ ສະຖານະປິດເປີດ
export const setSwich = (string) => {
  let res = "";
  switch (string) {
    case true:
      res = "ເປີດ";
      break;
    case false:
      res = "ປິດ";
      break;
  }
  return res;
};

export const ITEM_PAGE_LIST = [
  { itemPage: "1" },
  { itemPage: "2" },
  { itemPage: "3" },
  { itemPage: "4" },
  { itemPage: "5" },
  { itemPage: "ທັງໜົດ" },
];

export const bracket = (tag) => {
  let st = "(";
  let en = ")";
  let i = st + tag + en;
  return i;
};

// ວັນທີເດືອນປີເລີ່ມວັນທີ ເລີ່ມເດືອນ ເລີ່ມປີ
export const threeOfMonth = () => {
  return moment().subtract(3, "months").startOf("month").format("YYYY-MM-DD");
};

// ວັນທີເດືອນປີເລີ່ມວັນທີ ເລີ່ມເດືອນ ເລີ່ມປີ
export const startOfMonth = () => {
  return moment().clone().startOf("month").format("YYYY-MM-DD");
};

// ວັນທີເດືອນປີ ທ້າຍວັນທີ ທ້າຍເດືອນ ທ້າຍປີ
export const endOfMonth = () => {
  return moment().clone().endOf("month").format("YYYY-MM-DD");
};

//  ກຳນົດ ອາຍຸ
export const age = (age) => {
  let today = new Date();
  let y = today.getFullYear();
  let dob = moment(age).format("YYYY");
  return y - dob;
};

// ກຳນົດ ຟໍແມັດເງິນ
export const currency = (value) => {
  let currencys = new Intl.NumberFormat("en-CA").format(value);
  if (value !== 0) return currencys;
  else if (value === 0) return "0";
  else return "";
};

// ກຳນົດ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const formatDateTime = (dateTime) => {
  // moment.locale("lo");
  let resp = moment(dateTime).format("DD-MM-YYYY, HH:mm");
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(ພາສາລາວ)
export const formatDate = (dateTime) => {
  // moment.locale("lo");
  let resp = moment(dateTime).format("DD MMMM YYYY");
  if (dateTime) return resp;
  else return "-";
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(/)
export const formateDateSlash = (slashData) => {
  let resp = moment(slashData).format("YYYY/MM/DD");
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const formatDateDash = (dashDate) => {
  let resp = moment(dashDate).format("YYYY-MM-DD");
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(ຫຼັງ 7 ວັນ)
export const nextSevenDay = () => {
  var nextSevenDay = moment().add(-7, "days");
  var nextSevenDays = moment(nextSevenDay).format("YYYY-MM-DD");
  return nextSevenDays;
};
// ກຳນົດ ວັນທີເວລາປັດຈຸບັນ(/)
export const formateDateSlashes = (slashData) => {
  let resp = moment(slashData).format("DD/MM/YYYY");
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(ຖັດໄປ 1 ມື້)
export const nextOneDay = () => {
  var nextOneDay = moment().add(1, "days");
  var nextOneDays = moment(nextOneDay).format("YYYY-MM-DD");
  return nextOneDays;
};

// ກຳນົດ ວັນທີ ແລະ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const toDay = () => {
  moment.locale("lo");
  var today = new Date();
  var todays = moment(today).format("YYYY/MM/DD, HH:mm");
  return todays;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const toDayDash = () => {
  var today = new Date();
  var todays = moment(today).format("YYYY-MM-DD");
  return todays;
};

export const reconvertDay = (day) => {
  let result = "";
  switch (day) {
    case "MONDAY":
      result = "ຈັນ";
      break;
    case "TUESDAY":
      result = "ອັງຄານ";
      break;
    case "WEDNESDAY":
      result = "ພຸດ";
      break;
    case "THURSDAY":
      result = "ພະຫັດ";
      break;
    case "FRIDAY":
      result = "ສຸກ";
      break;
    case "SATURDAY":
      result = "ເສົາ";
      break;
    case "SUNDAY":
      result = "ອາທິດ";
      break;
    default:
      result = "ຈັນ";
  }
  return result;
};

// ກຳນົດ ເຄື່ອງໝາຍ ບັງຄັບໃຫ້ປ້ອນຂໍ້ມູນ
export const valiDate = () => {
  return <font style={{ color: "red" }}> * </font>;
};

// ກຳນົດ ການຈັດລຽງຕາມຄ່າຕ່າງໆ
export const SortBy = ({ sort }) => {
  return (
    <div
      onClick={() => {
        sort();
      }}
      style={{
        float: "right",
        marginRight: 5,
        cursor: "pointer",
      }}
    >
      <i className="fa fa-sort"></i>
    </div>
  );
};

export const paymentMethodFinance = (item) => {
  if (item === "PAY_ONLINE") {
    return "ອອນລາຍ";
  } else if (item === "PAY_CASH") {
    return "ເງີນສົດ";
  } else {
    return "-";
  }
};

export const startLoading = () => {
  return Notiflix.Loading.standard("Loading...");
};
export const stopLoading = () => {
  return Notiflix.Loading.remove();
};

export const messageSuccess = (e) => {
  Notiflix.Notify.init({ position: "center-top" });
  Notiflix.Notify.success(e);
};

export const messageError = (e) => {
  Notiflix.Notify.init({ position: "center-top" });
  Notiflix.Notify.failure(e);
};
export const messageWarning = (e) => {
  Notiflix.Notify.init({ position: "center-top" });
  Notiflix.Notify.warning(e);
};

export const messageConfirm = (title, action) => {
  Notiflix.Confirm.show(
    "ເເຈ້ງເຕືອນ",
    title,
    "ຕົກລົງ",
    "ຍົກເລີກ",
    async function () {
      action();
    },
    function () {
      return false;
    }
  );
};

export const _paymentStatus = [
  { name: "SOMETIME" },
  { name: "WEEKLY" },
  { name: "MONTH" },
  { name: "YEARLY" },
];

export const _payCurrency = [
  { name: "LAK" },
  { name: "BAHT" },
  { name: "DOLLAR" },
];

export const statusSections = [
  { title: "NORTHERN" },
  { title: "CENTRAL" },
  { title: "SOUTHERN" },
];

export const covertSection = (status) => {
  if (status === "NORTHERN") {
    return "ພາກເໜືອ";
  } else if (status === "CENTRAL") {
    return "ພາກກາງ";
  } else if (status === "SOUTHERN") {
    return "ພາກໃຕ້";
  } else {
    return "-";
  }
};

export const covertOrderStatus = (orderStatus) => {
  if (orderStatus === "PACKING") {
    return <span className="text-danger">ກຳລັງປຸງແຕ່ງ</span>;
  } else if (orderStatus === "DELIVERY") {
    return <span className="text-info">ກຳລັງຈັດສົ່ງ</span>;
  } else if (orderStatus === "CANCELED") {
    return <span className="text-danger">ຖຶກຍົກເລິກ</span>;
  } else if (orderStatus === "DELETE") {
    return <span className="text-danger">ຖຶກລຶບ</span>;
  } else if (orderStatus === "COMPLETED") {
    return <span className="text-success">ສົ່ງສຳເລັດ</span>;
  } else {
    return "-";
  }
};

export const statusCurrencyPayment = (_status) => {
  if (_status === "LAK") {
    return "ກີບ [LAK]";
  } else if (_status === "BAHT") {
    return "ບາດ [BAHT]";
  } else {
    return "ໂດລາ [US]";
  }
};

export const formatStartDate = (date) => {
  let _date = moment(date).format("YYYY-MM-DD");
  return _date;
};
export const formatEndDate = (date) => {
  let _endDate = moment(date).add(1, "days").format("YYYY-MM-DD");
  return _endDate;
};

export const loadingData = (size, content) => {
  return (
    <>
      <img
        src={loading}
        alt="loading"
        style={{ width: size ? size : 25, height: size ? size : 25 }}
      />{" "}
      {content}
    </>
  );
};

export const loadingScreen = () => {
  return Loading.standard({
    backgroundColor: "#fa5f5f6e",
    svgColor: "#ff0d2f",
  });
};
export const closeLoadingScreen = () => {
  return Loading.move();
};

export const loadingDatalist = (size = 25, content) => {
  return (
    <span>
      <img src={loading} alt="loading" style={{ width: size }} />{" "}
      <span className="text-primary">{content || "ກຳລັງໂຫຼດຂໍ້ມູນ..."}</span>
    </span>
  );
};

export const selectedAll = (e, className = "item-list-check") => {
  if (e.target.checked) {
    const array = [];
    document.querySelectorAll(`.${className}`).forEach((elm) => {
      elm.checked = true;
      let _id = elm.value;
      array.push(_id);
    });
    return array;
  } else {
    document.querySelectorAll(".item-list-check").forEach((elm) => {
      elm.checked = false;
    });
    const array = [];
    return array;
  }
};

export const selectedOne = (e, className = "item-list-check") => {
  const elmClass = document.querySelectorAll(`.${className}`);
  if (e.target.value) {
    const array = [];
    elmClass.forEach((elm) => {
      if (elm.checked) {
        array.push(elm.value);
      }
    });
    return array;
  }
};

export const unselected = (
  id = "selected-all",
  className = "item-list-check"
) => {
  const elmClass = document.querySelectorAll(`.${className}`);
  document.querySelector(`#${id}`).checked = false;
  elmClass.forEach((elm) => {
    elm.checked = false;
  });
  const result = [];
  return result;
};
//set params
export const setParams = (key, val) => {
  const urlSearch = new URL(window.location.href)?.search;
  if (urlSearch.search(key) === -1) {
    const _symbol = urlSearch ? "&" : "";
    const _search = `${urlSearch}${_symbol}${key}=${val ? val : ""}`;
    return _search;
  } else {
    const params = new URLSearchParams(urlSearch);
    const oldValue = params.get(key);
    const newUrl = urlSearch.replace(
      `${key}=${oldValue}`,
      `${key}=${val ? val : ""}`
    );
    return newUrl;
  }
  return null;
};

export const suserStatus = (item) => {
  if (item === "SUPER_ADMIN") {
    return "ບໍລິຫານສູງສຸດ";
  } else if (item === "IT") {
    return "ໄອທີ";
  } else if (item === "HR") {
    return "ບຸກຄາລະກອນ";
  } else if (item === "ACCOUNTANT") {
    return "ບັນຊີ";
  } else if (item === "FINANCE") {
    return "ການເງິນ";
  } else if (item === "LAW") {
    return "ກົດໝາຍ";
  } else if (item === "STATE_DIRECTOR") {
    return "ຫົວໜ້າບໍລິຫານ";
  } else if (item === "BRANCH_DIRECTOR") {
    return "ຫົວໜ້າສາຂາ";
  } else if (item === "ADMIN") {
    return "ເອັດມິນ";
  } else if (item === "CALL_CENTER") {
    return "ພະນັກງານຕ້ອນຮັບ";
  } else if (item === "DRIVER_DIRECTOR") {
    return "ຫົົວໜ້າໂຊເເຟີ";
  } else if (item === "CUSTOMER_SERVICE") {
    return "ບໍລິການລູກຄ້າ";
  } else if (item === "DRIVER") {
    return "ໄລເດີ້";
  } else if (item === "AUDITION_DIRECTOR") {
    return "ຫົວໜ້າສູນຄັດແຍກ";
  } else if (item === "AUDITION_STAFF") {
    return "ພ/ງ ສູນຄັດແຍກ";
  } else {
    return "-";
  }
};

//upload file to google storage
export const gcpUpload = async ({ file, bucketName, pathname, filename }) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("bucketName", bucketName);
    formData.append("pathname", pathname);
    if (filename !== "" && filename !== "null") {
      formData.append("filename", filename ? filename : "");
    }
    // https://storage.cloud.google.com/ansx/docs

    const res = await fetch(
      // "http://localhost:7002/google/upload",
      "https://api.services.anousith.express/google/upload",

      {
        method: "POST",
        body: formData,
      }
    );
    const resWithBody = await res.json();
    return resWithBody;
  } catch (error) {
    throw new Error(error);
  }
};

export const previewFiles = (handleClick, file) => {
  // check Extension file
  const fileExtension = file.name.split(".").pop();
  if (
    fileExtension === "jpeg" ||
    fileExtension === "jpg" ||
    fileExtension === "png" ||
    fileExtension === "gif" ||
    fileExtension === "svg"
  ) {
    return (
      <>
        <img src={jpg} alt="images" className="w-50" onClick={handleClick} />
        <p className="mt-3">{file?.name}</p>
      </>
    );
  } else if (fileExtension === "pdf") {
    return (
      <>
        <img src={pdf} alt="pdf" className="w-50" onClick={handleClick} />;
        <p className="mt-3">{file?.name}</p>
      </>
    );
  } else if (fileExtension === "docx" || fileExtension === "doc") {
    return (
      <>
        <img src={word} alt="doc" className="w-50" onClick={handleClick} />
        <p className="mt-3">{file?.name}</p>
      </>
    );
  } else if (fileExtension === "xlsx" || fileExtension === "xls") {
    return (
      <>
        <img src={excel} alt="xls" className="w-50" onClick={handleClick} />
        <p className="mt-3">{file?.name}</p>
      </>
    );
  } else if (fileExtension === "pptx" || fileExtension === "ppt") {
    return (
      <>
        <img src={ppt} alt="ppt" className="w-50" onClick={handleClick} />
        <p className="mt-3">{file?.name}</p>
      </>
    );
  }
};

export const DisPlayFiles = ({ file, className, width, height, item }) => {
  const [show, setShow] = useState(false);
  // check Extension file
  if (!file) {
    return loadingData(30, "ກຳລັງໂຫຼດ...");
  } else {
    const fileExtension = file.split(".").pop();
    if (
      fileExtension === "jpeg" ||
      fileExtension === "jpg" ||
      fileExtension === "png" ||
      fileExtension === "gif" ||
      fileExtension === "svg"
    ) {
      return (
        <React.Fragment>
          <>
            <img
              src={jpg}
              alt="images"
              className={className}
              style={{ width: width || 50, height: height || 50 }}
              onClick={() => setShow(true)}
            />
          </>
          <Modal
            show={show}
            centered
            scrollable
            size="lg"
            onHide={() => setShow(false)}
            className="text-center"
          >
            <Modal.Header closeButton className="custom-modal-header">
              <Modal.Title>ລາຍລະອຽດເອກະສານ</Modal.Title>
            </Modal.Header>
            <Modal.Body
              className="custom-modal-body text-center"
              style={{ backgroundColor: "transparent" }}
            >
              <div className="row">
                <div className="col-4">
                  {disPlayFiles(item?.files, "file", 220, 250)}
                </div>
                <div className="col-8">
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ເລກທີ່ເອກະສານ: {item?.documentID ? item?.documentID : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3 ">
                    ໝວດເອກະສານ:{" "}
                    {item?.typeDocument?.title
                      ? item?.typeDocument?.title
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3 ">
                    ເອກະສານ: {item?.title ? item?.title : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ວັນທີ່ເອກະສານ:{" "}
                    {item?.documentDate
                      ? formatDateDash(item?.documentDate)
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ວັນທີ່ສ້າງ:{" "}
                    {item?.createdAt ? formatDateDash(item?.createdAt) : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ສ້າງໂດຍ:{" "}
                    {item?.createdBy?.firstName
                      ? item?.createdBy?.firstName
                      : "-"}{" "}
                    {item?.createdBy?.lastName
                      ? item?.createdBy?.lastName
                      : "-"}{" "}
                  </div>
                  {item?.updatedBy?.firstName !== null ? (
                    <>
                      <div className="d-flex align-items-center mb-3 gap-3">
                        ວັນທີ່ແກໄຂ:{" "}
                        {item?.updatedAt
                          ? formatDateDash(item?.updatedAt)
                          : "-"}
                      </div>
                      <div className="d-flex align-items-center mb-3 gap-3">
                        ແກ້ໄຂໂດຍ:{" "}
                        {item?.updatedBy?.firstName
                          ? item?.updatedBy?.firstName
                          : "-"}{" "}
                        {item?.updatedBy?.lastName
                          ? item?.updatedBy?.lastName
                          : "-"}{" "}
                      </div>
                    </>
                  ) : null}

                  <div className="d-flex align-items-center mb-3 gap-3">
                    ພະແນກ:{" "}
                    {item?.departmentID?.title_lao
                      ? item?.departmentID?.title_lao
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ແຟ້ມ:{" "}
                    {item?.folderID?.folderNumber
                      ? item?.folderID?.folderNumber
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ເນື້ອໃນເອກະສານ:{" "}
                    {item?.description ? item?.description : "-"}
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      );
    } else if (fileExtension === "pdf") {
      return (
        <React.Fragment>
          <img
            src={pdf}
            alt="pdf"
            className={className}
            style={{ width: width || 50, height: height || 50 }}
            onClick={() => setShow(true)}
          />
          <Modal
            show={show}
            centered
            scrollable
            size="lg"
            onHide={() => setShow(false)}
            className="text-center"
          >
            <Modal.Header closeButton className="custom-modal-header">
              <Modal.Title>ລາຍລະອຽດເອກະສານ</Modal.Title>
            </Modal.Header>
            <Modal.Body
              className="custom-modal-body text-center"
              style={{ backgroundColor: "transparent" }}
            >
              <div className="row">
                <div className="col-4">
                  {disPlayFiles(item?.files, "file", 220, 250)}
                </div>
                <div className="col-8">
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ເລກທີ່ເອກະສານ: {item?.documentID ? item?.documentID : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3 ">
                    ໝວດເອກະສານ:{" "}
                    {item?.typeDocument?.title
                      ? item?.typeDocument?.title
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3 ">
                    ເອກະສານ: {item?.title ? item?.title : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ວັນທີ່ເອກະສານ:{" "}
                    {item?.documentDate
                      ? formatDateDash(item?.documentDate)
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ວັນທີ່ສ້າງ:{" "}
                    {item?.createdAt ? formatDateDash(item?.createdAt) : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ສ້າງໂດຍ:{" "}
                    {item?.createdBy?.firstName
                      ? item?.createdBy?.firstName
                      : "-"}{" "}
                    {item?.createdBy?.lastName
                      ? item?.createdBy?.lastName
                      : "-"}{" "}
                  </div>
                  {item?.updatedBy?.firstName !== null ? (
                    <>
                      <div className="d-flex align-items-center mb-3 gap-3">
                        ວັນທີ່ແກໄຂ:{" "}
                        {item?.updatedAt
                          ? formatDateDash(item?.updatedAt)
                          : "-"}
                      </div>
                      <div className="d-flex align-items-center mb-3 gap-3">
                        ແກ້ໄຂໂດຍ:{" "}
                        {item?.updatedBy?.firstName
                          ? item?.updatedBy?.firstName
                          : "-"}{" "}
                        {item?.updatedBy?.lastName
                          ? item?.updatedBy?.lastName
                          : "-"}{" "}
                      </div>
                    </>
                  ) : null}

                  <div className="d-flex align-items-center mb-3 gap-3">
                    ພະແນກ:{" "}
                    {item?.departmentID?.title_lao
                      ? item?.departmentID?.title_lao
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ແຟ້ມ:{" "}
                    {item?.folderID?.folderNumber
                      ? item?.folderID?.folderNumber
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ເນື້ອໃນເອກະສານ:{" "}
                    {item?.description ? item?.description : "-"}
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      );
    } else if (
      fileExtension === "docx" ||
      fileExtension === "doc" ||
      fileExtension === "docm" ||
      fileExtension === "dotx" ||
      fileExtension === "document"
    ) {
      return (
        <React.Fragment>
          <img
            src={word}
            alt="doc"
            className={className}
            style={{ width: width || 50, height: height || 50 }}
            onClick={() => setShow(true)}
          />
          <Modal
            show={show}
            centered
            scrollable
            size="lg"
            onHide={() => setShow(false)}
            className="text-center"
          >
            <Modal.Header closeButton className="custom-modal-header">
              <Modal.Title>ລາຍລະອຽດເອກະສານ</Modal.Title>
            </Modal.Header>
            <Modal.Body
              className="custom-modal-body text-center"
              style={{ backgroundColor: "transparent" }}
            >
              <div className="row">
                <div className="col-4">
                  {disPlayFiles(item?.files, "file", 220, 250)}
                </div>
                <div className="col-8">
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ເລກທີ່ເອກະສານ: {item?.documentID ? item?.documentID : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3 ">
                    ໝວດເອກະສານ:{" "}
                    {item?.typeDocument?.title
                      ? item?.typeDocument?.title
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3 ">
                    ເອກະສານ: {item?.title ? item?.title : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ວັນທີ່ເອກະສານ:{" "}
                    {item?.documentDate
                      ? formatDateDash(item?.documentDate)
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ວັນທີ່ສ້າງ:{" "}
                    {item?.createdAt ? formatDateDash(item?.createdAt) : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ສ້າງໂດຍ:{" "}
                    {item?.createdBy?.firstName
                      ? item?.createdBy?.firstName
                      : "-"}{" "}
                    {item?.createdBy?.lastName
                      ? item?.createdBy?.lastName
                      : "-"}{" "}
                  </div>
                  {item?.updatedBy?.firstName !== null ? (
                    <>
                      <div className="d-flex align-items-center mb-3 gap-3">
                        ວັນທີ່ແກໄຂ:{" "}
                        {item?.updatedAt
                          ? formatDateDash(item?.updatedAt)
                          : "-"}
                      </div>
                      <div className="d-flex align-items-center mb-3 gap-3">
                        ແກ້ໄຂໂດຍ:{" "}
                        {item?.updatedBy?.firstName
                          ? item?.updatedBy?.firstName
                          : "-"}{" "}
                        {item?.updatedBy?.lastName
                          ? item?.updatedBy?.lastName
                          : "-"}{" "}
                      </div>
                    </>
                  ) : null}

                  <div className="d-flex align-items-center mb-3 gap-3">
                    ພະແນກ:{" "}
                    {item?.departmentID?.title_lao
                      ? item?.departmentID?.title_lao
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ແຟ້ມ:{" "}
                    {item?.folderID?.folderNumber
                      ? item?.folderID?.folderNumber
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ເນື້ອໃນເອກະສານ:{" "}
                    {item?.description ? item?.description : "-"}
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      );
    } else if (
      fileExtension === "xlsx" ||
      fileExtension === "xls" ||
      fileExtension === "xlsm" ||
      fileExtension === "sheet"
    ) {
      return (
        <React.Fragment>
          <img
            src={excel}
            alt="xls"
            className={className}
            style={{ width: width || 50, height: height || 50 }}
            onClick={() => setShow(true)}
          />
          <Modal
            show={show}
            centered
            scrollable
            size="lg"
            onHide={() => setShow(false)}
            className="text-center"
          >
            <Modal.Header closeButton className="custom-modal-header">
              <Modal.Title>ລາຍລະອຽດເອກະສານ</Modal.Title>
            </Modal.Header>
            <Modal.Body
              className="custom-modal-body text-center"
              style={{ backgroundColor: "transparent" }}
            >
              <div className="row">
                <div className="col-4">
                  {disPlayFiles(item?.files, "file", 220, 250)}
                </div>
                <div className="col-8">
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ເລກທີ່ເອກະສານ: {item?.documentID ? item?.documentID : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3 ">
                    ໝວດເອກະສານ:{" "}
                    {item?.typeDocument?.title
                      ? item?.typeDocument?.title
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3 ">
                    ເອກະສານ: {item?.title ? item?.title : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ວັນທີ່ເອກະສານ:{" "}
                    {item?.documentDate
                      ? formatDateDash(item?.documentDate)
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ວັນທີ່ສ້າງ:{" "}
                    {item?.createdAt ? formatDateDash(item?.createdAt) : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ສ້າງໂດຍ:{" "}
                    {item?.createdBy?.firstName
                      ? item?.createdBy?.firstName
                      : "-"}{" "}
                    {item?.createdBy?.lastName
                      ? item?.createdBy?.lastName
                      : "-"}{" "}
                  </div>
                  {item?.updatedBy?.firstName !== null ? (
                    <>
                      <div className="d-flex align-items-center mb-3 gap-3">
                        ວັນທີ່ແກໄຂ:{" "}
                        {item?.updatedAt
                          ? formatDateDash(item?.updatedAt)
                          : "-"}
                      </div>
                      <div className="d-flex align-items-center mb-3 gap-3">
                        ແກ້ໄຂໂດຍ:{" "}
                        {item?.updatedBy?.firstName
                          ? item?.updatedBy?.firstName
                          : "-"}{" "}
                        {item?.updatedBy?.lastName
                          ? item?.updatedBy?.lastName
                          : "-"}{" "}
                      </div>
                    </>
                  ) : null}

                  <div className="d-flex align-items-center mb-3 gap-3">
                    ພະແນກ:{" "}
                    {item?.departmentID?.title_lao
                      ? item?.departmentID?.title_lao
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ແຟ້ມ:{" "}
                    {item?.folderID?.folderNumber
                      ? item?.folderID?.folderNumber
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ເນື້ອໃນເອກະສານ:{" "}
                    {item?.description ? item?.description : "-"}
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      );
    } else if (
      fileExtension === "pptx" ||
      fileExtension === "ppt" ||
      fileExtension === "pptm" ||
      fileExtension === "presentation"
    ) {
      return (
        <React.Fragment>
          <img
            src={ppt}
            alt="ppt"
            className={className}
            style={{ width: width || 50, height: height || 50 }}
            onClick={() => setShow(true)}
          />
          <Modal
            show={show}
            centered
            scrollable
            size="lg"
            onHide={() => setShow(false)}
            className="text-center"
          >
            <Modal.Header closeButton className="custom-modal-header">
              <Modal.Title>ລາຍລະອຽດເອກະສານ</Modal.Title>
            </Modal.Header>
            <Modal.Body
              className="custom-modal-body text-center"
              style={{ backgroundColor: "transparent" }}
            >
              <div className="row">
                <div className="col-4">
                  {disPlayFiles(item?.files, "file", 220, 250)}
                </div>
                <div className="col-8">
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ເລກທີ່ເອກະສານ: {item?.documentID ? item?.documentID : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3 ">
                    ໝວດເອກະສານ:{" "}
                    {item?.typeDocument?.title
                      ? item?.typeDocument?.title
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3 ">
                    ເອກະສານ: {item?.title ? item?.title : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ວັນທີ່ເອກະສານ:{" "}
                    {item?.documentDate
                      ? formatDateDash(item?.documentDate)
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ວັນທີ່ສ້າງ:{" "}
                    {item?.createdAt ? formatDateDash(item?.createdAt) : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ສ້າງໂດຍ:{" "}
                    {item?.createdBy?.firstName
                      ? item?.createdBy?.firstName
                      : "-"}{" "}
                    {item?.createdBy?.lastName
                      ? item?.createdBy?.lastName
                      : "-"}{" "}
                  </div>
                  {item?.updatedBy?.firstName !== null ? (
                    <>
                      <div className="d-flex align-items-center mb-3 gap-3">
                        ວັນທີ່ແກໄຂ:{" "}
                        {item?.updatedAt
                          ? formatDateDash(item?.updatedAt)
                          : "-"}
                      </div>
                      <div className="d-flex align-items-center mb-3 gap-3">
                        ແກ້ໄຂໂດຍ:{" "}
                        {item?.updatedBy?.firstName
                          ? item?.updatedBy?.firstName
                          : "-"}{" "}
                        {item?.updatedBy?.lastName
                          ? item?.updatedBy?.lastName
                          : "-"}{" "}
                      </div>
                    </>
                  ) : null}

                  <div className="d-flex align-items-center mb-3 gap-3">
                    ພະແນກ:{" "}
                    {item?.departmentID?.title_lao
                      ? item?.departmentID?.title_lao
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ແຟ້ມ:{" "}
                    {item?.folderID?.folderNumber
                      ? item?.folderID?.folderNumber
                      : "-"}
                  </div>
                  <div className="d-flex align-items-center mb-3 gap-3">
                    ເນື້ອໃນເອກະສານ:{" "}
                    {item?.description ? item?.description : "-"}
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      );
    }
  }
};

export const disPlayFiles = (file, className, width, height) => {
  // check Extension file
  if (!file) {
    return loadingData(30, "ກຳລັງໂຫຼດ...");
  } else {
    const fileExtension = file.split(".").pop();
    if (
      fileExtension === "jpeg" ||
      fileExtension === "jpg" ||
      fileExtension === "png" ||
      fileExtension === "gif" ||
      fileExtension === "svg"
    ) {
      return (
        <>
          <img
            src={jpg}
            alt="images"
            className={className}
            style={{ width: width || 50, height: height || 50 }}
          />
        </>
      );
    } else if (fileExtension === "txt") {
      return (
        <img
          src={fileText}
          alt="txt"
          className={className}
          style={{ width: width || 50, height: height || 50 }}
        />
      );
    } else if (fileExtension === "pdf") {
      return (
        <img
          src={pdf}
          alt="pdf"
          className={className}
          style={{ width: width || 50, height: height || 50 }}
        />
      );
    } else if (
      fileExtension === "docx" ||
      fileExtension === "doc" ||
      fileExtension === "docm" ||
      fileExtension === "dotx" ||
      fileExtension === "document"
    ) {
      return (
        <img
          src={word}
          alt="doc"
          className={className}
          style={{ width: width || 50, height: height || 50 }}
        />
      );
    } else if (
      fileExtension === "xlsx" ||
      fileExtension === "xls" ||
      fileExtension === "xlsm" ||
      fileExtension === "sheet"
    ) {
      return (
        <img
          src={excel}
          alt="xls"
          className={className}
          style={{ width: width || 50, height: height || 50 }}
        />
      );
    } else if (
      fileExtension === "pptx" ||
      fileExtension === "ppt" ||
      fileExtension === "pptm" ||
      fileExtension === "presentation"
    ) {
      return (
        <img
          src={ppt}
          alt="ppt"
          className={className}
          style={{ width: width || 50, height: height || 50 }}
        />
      );
    }
  }
};

// useQuery
export const useSearchParams = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
