/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  formatDateTime,
  gcp_docs,
  messageError,
  messageSuccess,
  placeholderImage,
  toDayDash,
  startOfMonth,
  createdAt_gte,
  createdAt_lte,
  getStaffLogin,
  aws_url_emp,
  currency,
  disPlayFiles,
  socketServer,
} from "../../helper";
import {
  DELETE_GROUP,
  DELETE_GROUP_INVITE,
  QUERY_GROUPS,
  QUERY_GROUP_INVITE,
} from "./apollo";
import Notiflix from "notiflix";
import useRouter from "use-react-router";
import EditGroup from "./editGroup";
import InviteMemberGroup from "./invite";
import NewDocument from "./addDocument";
import { DELETE_DOCUMENT, QUERY_DOCUMENTS } from "../documents/apollo";
import EditDocument from "./editDocument";
import InviteAdminGroup from "./inviteAdmin";
import DeleteAdmin from "./deleteAdmin";
import PreviewImage from "../../helper/previewImage";

export default function DocumentsGroupDetail() {
  const { match, history } = useRouter();
  const _id = match.params.id;
  const _user = getStaffLogin().data;
  const [startDate, setStartDate] = useState(startOfMonth());
  const [endDate, setEndDate] = useState(toDayDash());
  const [searchValueDocs, setSearchValueDocs] = useState("");
  const [searchMember, setSearchMember] = useState("");
  const [newLoadData, setNewLoadData] = useState(false);
  const [newLoadMember, setNewLoadMember] = useState(false);
  const [deleteGroup] = useMutation(DELETE_GROUP);
  const [deleteDocument] = useMutation(DELETE_DOCUMENT);
  const [deleteGroupInvite] = useMutation(DELETE_GROUP_INVITE);
  const [listGroup, setListGroup] = useState([]);
  const [listDocument, setListDocument] = useState([]);
  const [listGroupInvite, setListGroupInvite] = useState([]);
  const [checkout, setListCheckout] = useState([]);

  const [queryFolder, { data: resultData }] = useLazyQuery(QUERY_GROUPS, {
    fetchPolicy: "cache-and-network",
  });

  const [queryDocument, { data: resultDocument }] = useLazyQuery(
    QUERY_DOCUMENTS,
    { fetchPolicy: "cache-and-network" }
  );

  const [queryGroupInvite, { data: resultGroupInvite }] = useLazyQuery(
    QUERY_GROUP_INVITE,
    { fetchPolicy: "cache-and-network" }
  );
  const [queryGroupInviteCheckout, { data: resultCheckot }] = useLazyQuery(
    QUERY_GROUP_INVITE,
    { fetchPolicy: "cache-and-network" }
  );

  useEffect(() => {
    queryFolder({
      variables: {
        where: {
          _id: _id,
        },
        limit: 1,
        orderBy: "createdAt_DESC",
      },
    });
  }, [newLoadData]);

  useEffect(() => {
    queryDocument({
      variables: {
        where: {
          groupID: _id,
          title: searchValueDocs ? String(searchValueDocs) : undefined,
          createdAt_gte: createdAt_gte(startDate),
          createdAt_lte: createdAt_lte(endDate),
        },
        limit: 1000,
        orderBy: "createdAt_DESC",
      },
    });
  }, [searchValueDocs, newLoadData, startDate, endDate]);

  useEffect(() => {
    queryGroupInvite({
      variables: {
        where: {
          userID: searchMember ? String(searchMember) : undefined,
          groupID: parseInt(_id),
        },
        limit: 1000,
        orderBy: "createdAt_DESC",
      },
    });

    queryGroupInviteCheckout({
      variables: {
        where: {
          userID: _user?._id,
          groupID: parseInt(_id),
        },
        limit: 1,
        orderBy: "createdAt_DESC",
      },
    });
  }, [newLoadMember, searchMember]);

  useEffect(() => {
    if (resultData) {
      setListGroup(resultData?.documentGroups?.data[0]);
    }
  }, [resultData]);

  useEffect(() => {
    if (resultDocument) {
      setListDocument(resultDocument?.documents?.data);
    }
  }, [resultDocument]);

  useEffect(() => {
    if (resultGroupInvite) {
      setListGroupInvite(resultGroupInvite.documentGroupInvites?.data);
    }
  }, [resultGroupInvite]);

  useEffect(() => {
    if (resultCheckot) {
      setListCheckout(resultCheckot.documentGroupInvites?.data[0]);
    }
  }, [resultCheckot]);

  const _handleDelete = () => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການລຶບຂໍ້ມູນ ນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _deleteGroup = await deleteGroup({
            variables: {
              where: {
                _id: parseInt(_id),
              },
            },
          });
          if (_deleteGroup) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            history.push("/document_group");
          }
        } catch (error) {
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };
  //ລີບກຸ່ມ
  const _handleDeleteDocument = (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການລຶບຂໍ້ມູນ ນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _deleteDocument = await deleteDocument({
            variables: {
              where: {
                _id: parseInt(id),
              },
            },
          });
          if (_deleteDocument) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            setNewLoadData(!newLoadData);
          }
        } catch (error) {
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  //ອອກຈາກກຸ່ມ
  const _handleCheckout = async (status, id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການອອກກຸ່ມ ນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          let __deleteGroupInvite = await deleteGroupInvite({
            variables: {
              data: {
                status: String(status),
              },
              where: {
                _id: id,
              },
            },
          });
          if (__deleteGroupInvite) {
            messageSuccess("ການດຳເນິນການສຳເລັດ");
            setNewLoadMember(!newLoadMember);
          }
        } catch (error) {
          messageError("ການເຂົ້າລະບົບມີຄວາມຂັດຂ້ອງ");
        }
      },
      () => {
        return false;
      }
    );
  };

  useEffect(() => {
    socketServer.on("groupInvite", (data) => {
      setNewLoadMember(!newLoadMember);
    });
    socketServer.on("removeGroupInvite", (data) => {
      setNewLoadMember(!newLoadMember);
    });
  }, []);

  return (
    <>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex align-items-center justify-content-between mb-3 gap-4 flex-wrap">
              <div className="group-info d-flex align-items-center gap-3">
                <div>
                  <PreviewImage
                    image={gcp_docs + listGroup?.profileImage}
                    className={"rounded-circle img-fluid avatar-100"}
                  />
                </div>
                <div className="info">
                  <h4>{listGroup?.title ? listGroup?.title : "No Title"}</h4>
                  <p className="mb-0 d-flex justify-content-center align-items-center">
                    <svg
                      width={20}
                      className="me-1"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.7688 8.71387H16.2312C18.5886 8.71387 20.5 10.5831 20.5 12.8885V17.8254C20.5 20.1308 18.5886 22 16.2312 22H7.7688C5.41136 22 3.5 20.1308 3.5 17.8254V12.8885C3.5 10.5831 5.41136 8.71387 7.7688 8.71387ZM11.9949 17.3295C12.4928 17.3295 12.8891 16.9419 12.8891 16.455V14.2489C12.8891 13.772 12.4928 13.3844 11.9949 13.3844C11.5072 13.3844 11.1109 13.772 11.1109 14.2489V16.455C11.1109 16.9419 11.5072 17.3295 11.9949 17.3295Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.4"
                        d="M17.523 7.39595V8.86667C17.1673 8.7673 16.7913 8.71761 16.4052 8.71761H15.7447V7.39595C15.7447 5.37868 14.0681 3.73903 12.0053 3.73903C9.94257 3.73903 8.26594 5.36874 8.25578 7.37608V8.71761H7.60545C7.20916 8.71761 6.83319 8.7673 6.47754 8.87661V7.39595C6.4877 4.41476 8.95692 2 11.985 2C15.0537 2 17.523 4.41476 17.523 7.39595Z"
                        fill="currentColor"
                      />
                    </svg>
                    ສະມາຊິກກຸ່ມ.{" "}
                    {resultGroupInvite?.documentGroupInvites?.total > 0
                      ? currency(resultGroupInvite?.documentGroupInvites?.total)
                      : 0}{" "}
                    ຄົນ
                  </p>
                </div>
              </div>
              <div className="group-member d-flex align-items-center gap-3">
                {listGroupInvite.map((item, index) => (
                  <div className="iq-media-group">
                    <a href="#" className="iq-media" key={index}>
                      <img
                        className="img-fluid avatar-40 rounded-circle"
                        src={
                          item?.userID?.profileImage
                            ? aws_url_emp + item?.userID?.profileImage
                            : placeholderImage(100)
                        }
                        alt=""
                        loading="lazy"
                      />
                    </a>
                  </div>
                ))}
                <InviteMemberGroup
                  groupID={_id}
                  newLoad={newLoadMember}
                  setNewLoad={(res) => setNewLoadMember(res)}
                />
              </div>
            </div>
          </div>
          <NewDocument
            groupID={_id}
            newLoad={newLoadData}
            setNewLoad={(res) => {
              setNewLoadData(res);
            }}
          />
          <div className="col-lg-8">
            <div className="row mb-3">
              <div className="col-6">
                <input
                  type="text"
                  className="form-control shadow-none border-0 "
                  placeholder="ຄົ້ນຫາເອກະສານ..."
                  onChange={(e) => setSearchValueDocs(e.target.value)}
                />
              </div>
              <div className="col-3">
                <input
                  type="date"
                  className="form-control  shadow-none border-0"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="col-3">
                <input
                  type="date"
                  className="form-control shadow-none border-0"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            <div className="card">
              <div className="card-header border-bottom">
                <h4 className="mb-2">
                  <svg
                    class="icon-32 me-2 text-primary"
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M22 12C22 17.524 17.523 22 12 22C6.477 22 2 17.524 2 12C2 6.478 6.477 2 12 2C17.523 2 22 6.478 22 12Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M15.5739 15.8145C15.4429 15.8145 15.3109 15.7805 15.1899 15.7095L11.2639 13.3675C11.0379 13.2315 10.8989 12.9865 10.8989 12.7225V7.67554C10.8989 7.26154 11.2349 6.92554 11.6489 6.92554C12.0629 6.92554 12.3989 7.26154 12.3989 7.67554V12.2965L15.9589 14.4195C16.3139 14.6325 16.4309 15.0925 16.2189 15.4485C16.0779 15.6835 15.8289 15.8145 15.5739 15.8145Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  ການເຄື່ອນໄຫວ
                </h4>
              </div>
              <div className="card-body">
                {listDocument?.map((item) => (
                  <>
                    <div className="border-bottom mb-5">
                      <div className="d-flex align-items-center mb-3 gap-3">
                        <a
                          data-fslightbox="gallery"
                          href={`${gcp_docs + item?.files}`}
                          target="_blank"
                        >
                          {item?.files ? (
                            disPlayFiles(
                              item?.files,
                              "img-fluid rounded-circle p-1 border border-2 border-primary border-dotted",
                              100,
                              100
                            )
                          ) : (
                            <img
                              className="img-fluid rounded-circle p-1 border border-2 border-primary border-dotted"
                              src={placeholderImage(90)}
                              alt=""
                              loading="lazy"
                            />
                          )}
                        </a>
                        <div className="w-100">
                          <strong className="text-primary fs-4">
                            {item?.title ? item?.title : "No title"}
                          </strong>
                          <div className="mt-2 text-secondary">
                            ສ້າງໂດຍ:{" "}
                            {item?.createdBy?.firstName +
                              " " +
                              item?.createdBy?.lastName}
                          </div>
                          <div className="mt-2 text-secondary">
                            ສ້າງເມື່ອ:{" "}
                            {item?.createdAt
                              ? formatDateTime(item?.createdAt)
                              : "No Date"}
                          </div>
                        </div>
                      </div>
                      {_user?._id === item?.createdBy?._id && (
                        <div className="d-flex align-items-center mt-3 mb-3">
                          <div className="btn btn-sm btn-soft-light mx-3 d-flex align-items-center gap-2">
                            <div className="dropdown me-3">
                              <span
                                className="dropdown-toggle align-items-center d-flex"
                                id="dropdownMenuButton05"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <svg
                                  class="icon-25 me-1"
                                  width="25"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.0122 14.8299C10.4077 14.8299 9.10986 13.5799 9.10986 12.0099C9.10986 10.4399 10.4077 9.17993 12.0122 9.17993C13.6167 9.17993 14.8839 10.4399 14.8839 12.0099C14.8839 13.5799 13.6167 14.8299 12.0122 14.8299Z"
                                    fill="currentColor"
                                  ></path>
                                  <path
                                    opacity="0.4"
                                    d="M21.2301 14.37C21.036 14.07 20.76 13.77 20.4023 13.58C20.1162 13.44 19.9322 13.21 19.7687 12.94C19.2475 12.08 19.5541 10.95 20.4228 10.44C21.4447 9.87 21.7718 8.6 21.179 7.61L20.4943 6.43C19.9118 5.44 18.6344 5.09 17.6226 5.67C16.7233 6.15 15.5685 5.83 15.0473 4.98C14.8838 4.7 14.7918 4.4 14.8122 4.1C14.8429 3.71 14.7203 3.34 14.5363 3.04C14.1582 2.42 13.4735 2 12.7172 2H11.2763C10.5302 2.02 9.84553 2.42 9.4674 3.04C9.27323 3.34 9.16081 3.71 9.18125 4.1C9.20169 4.4 9.10972 4.7 8.9462 4.98C8.425 5.83 7.27019 6.15 6.38109 5.67C5.35913 5.09 4.09191 5.44 3.49917 6.43L2.81446 7.61C2.23194 8.6 2.55897 9.87 3.57071 10.44C4.43937 10.95 4.74596 12.08 4.23498 12.94C4.06125 13.21 3.87729 13.44 3.59115 13.58C3.24368 13.77 2.93709 14.07 2.77358 14.37C2.39546 14.99 2.4159 15.77 2.79402 16.42L3.49917 17.62C3.87729 18.26 4.58245 18.66 5.31825 18.66C5.66572 18.66 6.0745 18.56 6.40153 18.36C6.65702 18.19 6.96361 18.13 7.30085 18.13C8.31259 18.13 9.16081 18.96 9.18125 19.95C9.18125 21.1 10.1215 22 11.3069 22H12.6968C13.872 22 14.8122 21.1 14.8122 19.95C14.8429 18.96 15.6911 18.13 16.7029 18.13C17.0299 18.13 17.3365 18.19 17.6022 18.36C17.9292 18.56 18.3278 18.66 18.6855 18.66C19.411 18.66 20.1162 18.26 20.4943 17.62L21.2097 16.42C21.5776 15.75 21.6083 14.99 21.2301 14.37Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                                ຈັດການ
                              </span>
                              <div
                                className="dropdown-menu dropdown-menu-end"
                                aria-labelledby="dropdownMenuButton05"
                                style={{}}
                              >
                                <EditDocument
                                  data={item}
                                  newLoad={newLoadData}
                                  setNewLoad={(res) => setNewLoadData(res)}
                                />
                                <a
                                  className="dropdown-item d-flex align-items-center gap-2"
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    _handleDeleteDocument(item?._id)
                                  }
                                >
                                  <svg
                                    className="icon-20"
                                    width={20}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.4"
                                      d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                  ລຶບ
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">ສະມາຊິກກຸ່ມ</h4>
                </div>
              </div>
              <div className="card-body">
                <ul className="list-inline p-0 m-0">
                  
                  <DeleteAdmin
                    groupID={_id}
                    newLoad={newLoadMember}
                    setNewLoad={(res) => setNewLoadMember(res)}
                  />
                  <InviteAdminGroup
                    groupID={_id}
                    newLoad={newLoadMember}
                    setNewLoad={(res) => setNewLoadMember(res)}
                  />
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">ກ່ຽວກັບ</h4>
                </div>
              </div>
              <div className="card-body">
                <ul className="list-inline p-0 m-0">
                  <li className="mb-3">
                    <p className="mb-0">
                      {listGroup?.title ? listGroup?.title : "No Title"}
                    </p>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex gap-3">
                      <div className="flex-shrink-0">
                        <img
                          src={
                            listGroup?.createdBy?.profileImage
                              ? aws_url_emp + listGroup?.createdBy?.profileImage
                              : placeholderImage(100)
                          }
                          alt=""
                          className="avatar-40 rounded-circle"
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h6>
                          ຜູ້ສ້າງ:
                          <i>
                            {" "}
                            {listGroup?.createdBy?.firstName
                              ? listGroup?.createdBy?.firstName +
                                " " +
                                listGroup?.createdBy?.lastName
                              : "No Name"}
                          </i>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex gap-3">
                      <div className="flex-shrink-0">
                        <svg
                          class="icon-32"
                          width="32"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M22 12C22 17.524 17.523 22 12 22C6.477 22 2 17.524 2 12C2 6.478 6.477 2 12 2C17.523 2 22 6.478 22 12Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M15.5739 15.8145C15.4429 15.8145 15.3109 15.7805 15.1899 15.7095L11.2639 13.3675C11.0379 13.2315 10.8989 12.9865 10.8989 12.7225V7.67554C10.8989 7.26154 11.2349 6.92554 11.6489 6.92554C12.0629 6.92554 12.3989 7.26154 12.3989 7.67554V12.2965L15.9589 14.4195C16.3139 14.6325 16.4309 15.0925 16.2189 15.4485C16.0779 15.6835 15.8289 15.8145 15.5739 15.8145Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                      <div className="flex-grow-1 ">
                        <h6>
                          ວັນທີສ້າງ:{" "}
                          {listGroup?.createdAt
                            ? formatDateTime(listGroup?.createdAt)
                            : "No Date"}
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li className="mt-5">
                    <div className="d-flex gap-3">
                      <div className="flex-grow-1 ">
                        {_user?._id === listGroup?.createdBy?._id ? (
                          <div className="d-flex gap-3">
                            <EditGroup
                              data={listGroup}
                              newLoad={newLoadData}
                              setNewLoad={(res) => setNewLoadData(res)}
                            />
                            <button
                              className="btn btn-light "
                              onClick={_handleDelete}
                            >
                              <i className="icon-trash" /> ລຶບ
                            </button>
                          </div>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-danger float-end"
                            onClick={() =>
                              _handleCheckout("EXIT", checkout?._id)
                            }
                          >
                            <i class="fa-solid fa-right-to-bracket" />{" "}
                            ອອກຈາກກຸ່ມ
                          </button>
                        )}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
