import { gql } from "apollo-boost";
export const QUERY_DOCUMENTS = gql`
query Documents($where: DocumentsWhereInput, $orderBy: OrderByInput, $skip: Int, $limit: Int) {
  documents(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
    total
    data {
      _id
      documentID
      typeDocument {
        _id
        title
        typeDocumentID
      }
      title
      description
      documentDate
      departmentID {
        _id
        title_lao
      }
      folderID {
        _id
        folderNumber
        title
      }
      files
      isDeleted
      isPublic
      isPrivate
      sendBy {
        _id
        cvID
        profileImage
        firstName
        lastName
      }
      isRedPad
      sendDate
      sendTo {
        _id
        cvID
        firstName
        profileImage
        lastName
      }
      sendToDate
      groupID {
        _id
        title
        coverImage
        profileImage
      }
      status
      createdAt
      createdBy {
        _id
        profileImage
        firstName
        lastName
      }
      updatedAt
      updatedBy {
        _id
        cvID
        profileImage
        firstName
        lastName
      }
    }
  }
}
`;

export const QUERY_FOLDERS = gql`
query DocumentFolders($where: DocumentFolderWhereInput, $orderBy: OrderByInput, $skip: Int, $limit: Int) {
  documentFolders(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
    total
    data {
      _id
      folderNumber
      title
      departmentID {
        _id
        title_lao
      }
      createdAt
      createdBy {
        _id
        firstName
        lastName
      }
      loggerID {
        _id
        title
      }
    }
  }
}
`;
export const CREATE_DOCUMENT = gql`
mutation CreateDocuments($data: DocumentsInput!) {
  createDocuments(data: $data) {
    _id
  }
}
`;

export const UPDATE_DOCUMENT = gql`
mutation UpdateDocuments($data: DocumentsInput!, $where: DocumentsWhereInputOne!) {
  updateDocuments(data: $data, where: $where) {
    _id
  }
}
`;

export const DELETE_DOCUMENT= gql`
mutation DeleteDocuments($where: DocumentsWhereInputOne!) {
  deleteDocuments(where: $where) {
    _id
  }
}
`;
