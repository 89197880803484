// import { useLazyQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import {
  formatDateDash,
  getStaffLogin,
  messageError,
  messageSuccess,
  valiDate,
} from "../../helper";
import { UPDATE_FOR_WAR_DOCUMENT } from "./apollo";
import SelectUser from "../../helper/componentsList/SelectUser";
import SelectDocument from "../../helper/componentsList/SelectDocument";
import SelectDepartment from "../../helper/componentsList/SelectDepartment";
import { Tune } from "@material-ui/icons";
export default function SenDocumentEditForwar({ data, newLoad, setNewLoad }) {
  const _user = getStaffLogin().data;
  const [show, setShow] = useState(false);
  const [listUser, setListUser] = useState({});
  const [listDoc, setListDoc] = useState();
  const [department, setListDepartment] = useState();
  const [_private, setPrivate] = useState(null);
  const [updateForwar] = useMutation(UPDATE_FOR_WAR_DOCUMENT);

  useEffect(() => {
    if (!show) return;
    setListDoc(data.document);
    setListUser(data?.receiptBy);
    setListDepartment(data?.department);
    setPrivate(data?.isPrivate);
  }, [data, show]);

  return (
    <Formik
      initialValues={{
        description: data?.description,
        isPrivate: data?.isPrivate,
      }}
      enableReinitialize={false}
      validate={(values) => {
        const errors = {};
        if (!values.description) {
          errors.description = "error";
        }
        if (!listDoc?._id) errors.document = "error";
        if (!listUser?._id) errors.receiptBy = "error";

        return errors;
      }}
      onSubmit={async (values) => {
        try {
          let __updateForwar = await updateForwar({
            variables: {
              data: {
                description: String(values?.description),
                document: listDoc?._id,
                department: listUser?.department?._id,
                receiptBy: listUser?._id,
                sendBy: _user?._id,
                receiptDate: formatDateDash(new Date()),
                isPrivate: parseInt(_private),
              },
              where: {
                _id: data?._id,
              }
            },
          });

          if (__updateForwar) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setShow(false);
            setNewLoad(!newLoad);
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການເຂົ້າລະບົບມີຄວາມຂັດຂ້ອງ");
        }
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <React.Fragment>
          <button
            className="btn hvr-shutter-out-horizontal text-white"
            data-bs-toggle="modal"
            type="submit"
            onClick={() => setShow(true)}
          >
            <i className="fas fa-edit text-danger fs-4" />
          </button>
          <Modal show={show} onHide={() => setShow(false)} animation={false} centered backdrop="static" size="lg">
            <Modal.Header closeButton>
              <Modal.Title className="fs-5">ຟອມສົ່ງເອກະສານ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>ເອກະສານ {valiDate()}</label><br />
                <input type="radio"
                  value={0}
                  name="isPrivate"
                  checked={parseInt(_private) === 0 ? true : false}
                  onClick={(e) =>
                    setPrivate(e.target.value)
                  }
                  className=" mr-2 mb-2"
                /><span> ເອກະສານທົ່ວໄປ</span><br />
                <input type="radio"
                  value={1}
                  checked={parseInt(_private)  === 1 ? true : false}
                  name="isPrivate"
                  onClick={(e) =>
                    setPrivate(e.target.value)
                  }
                  className=" mr-2 mb-2"
                /><span> ເອກະສານສ່ວນຕົວ</span><br />
                <i className="text-danger">{errors?.isPrivate}</i>
              </div>
              {Number(_private) === 0 ? (
                <div className="form-group">
                  <label>ສົ່ງເຖິງພະແນກ {valiDate()}</label>
                  <SelectDepartment
                    size={"lg"}
                    onChange={(data) => {
                      setListDepartment(data);
                    }}
                    value={department?._id}
                  />
                  <i className="text-danger">{errors?.department}</i>
                </div>
              ) : (
                <div className="form-group">
                  <label>ສົ່ງເຖີງ {valiDate()}</label>
                  <SelectUser
                    size={"lg"}
                    onChange={(data) => {
                      setListUser(data);
                    }}
                    value={listUser?._id}
                  />
                  <i className="text-danger">{errors?.receiptBy}</i>

                </div>
              )}
              <div className="form-group">
                <label>ຫົວເລື່ອງ {valiDate()}</label>
                <textarea
                  type="text"
                  rows={5}
                  className={
                    errors.description
                      ? "form-control form-control-lg mb-3 mt-2 is-invalid"
                      : "form-control form-control-lg mb-3 mt-2 invalid"
                  }
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  placeholder="ປ້ອນຂໍ້ມູນ..."
                />
              </div>
              <div className="form-group">
                <label>ເອກະສານ {valiDate()}</label>
                <SelectDocument
                  size={"lg"}
                  onChange={(data) => {
                    setListDoc(data);
                  }}
                  value={listDoc?._id}
                />
              </div>
              {/* <div className="form-group">
                <label>ເອກະສານ </label><br />
                <button className={_private === 0 ? "active btn btn-danger mt-2" : "btn btn-light mt-2"}
                  onClick={() => {
                    setPrivate(0)
                  }}
                >ສົ່ງທົ່ວໄປ</button>
                <button className={_private === 1 ? "active btn btn-danger mt-2" : "btn btn-light mt-2"}
                  onClick={() => {
                    setPrivate(1)
                  }}
                >ສົ່ງສ່ວນຕົວ</button>
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary btn-lg hvr-shutter-out-horizontal"
                onClick={() => handleSubmit()}
              >
                <i className="icon-check-circle me-1" />
                ຢືນຢັນສົ່ງ
              </button>
              <button
                type="button"
                className="btn btn-light btn-lg hvr-shutter-out-horizontal"
                onClick={() => setShow(false)}
              >
                <i className="icon-x me-2" />
                ຍົກເລີກ
              </button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      )}
    </Formik>
  );
}
