import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  DELETE_SIGNATURE_SUB_PROVIDER,
  QUERY_SIGNATURE_SUB_PROVIDERS,
} from "./apollo";
import QueryDepartment from "./selectDepartment";
import image from "../../img/Nodata.png";
import LimitData from "../../controllers/LimitData";
import Pagination from "../../helper/components/pagination/Pagination";
import {
  formatDateDash,
  ITEM_PER_PAGE,
  messageError,
  messageSuccess,
} from "../../helper";
import Notiflix from "notiflix";

export default function QueryLicent() {
  const [result, setResult] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [newLaod, setNewLoad] = useState(false);
  const [numberPage, setNumberPage] = useState(1);
  const [numberRow, setNumberRow] = useState(ITEM_PER_PAGE);
  const [deleteSignatureSubProvider] = useMutation(
    DELETE_SIGNATURE_SUB_PROVIDER
  );

  const [querySignatureSubProvider, { data: DataSubProvider }] = useLazyQuery(
    QUERY_SIGNATURE_SUB_PROVIDERS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    querySignatureSubProvider({
      variables: {
        where: {
         
        },
        orderBy: "createdAt_DESC",
      },
    });
  }, [ newLaod]);

  useEffect(() => {
    const results = DataSubProvider?.SignatureSubProviders?.data;
    if (searchValue) {
      const filter = results?.filter((obj) => {
        if (
          obj?.documentCreatorID?.documentCode?.includes(searchValue) ||
          obj?.documentCreatorID?.title?.includes(searchValue) ||
          obj?.signatureID?.title?.includes(searchValue)
        ) {
          return obj;
        }
      });
      setResult(filter);
    } else {
      setResult(results);
    }
  }, [DataSubProvider, searchValue]);

  const countData = DataSubProvider?.SignatureSubProviders?.total;
  const countPage = [];
  for (var i = 1; i <= Math.ceil(countData / numberRow); i++) {
    countPage.push(i);
  }

  const _deleteSignature_Sub_Provider = (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການລຶບຂໍ້ມູນ ນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _deleteSignatureSubProvider = await deleteSignatureSubProvider({
            variables: {
              where: {
                _id: parseInt(id),
              },
            },
          });
          if (_deleteSignatureSubProvider) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            setNewLoad(!newLaod);
          }
        } catch (error) {
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <h3>ລາຍງານຂໍ້ມູນລາຍເຊັນ</h3>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <p className="mb-md-0 mb-2 d-flex align-items-center gap-2">
                    <input
                      type="text"
                      className="form-control w-100"
                      placeholder="ຊື່ເອກະສານ ຫຼື ລະຫັດເອກະສານ"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </p>
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="dropdown me-3">
                      <LimitData
                        onChange={(val) => {
                          setNumberRow(val);
                        }}
                      />
                    </div>
                    <div className="dropdown me-3 pt-2">
                      <Pagination
                        className="mt-2"
                        pageTotal={countPage}
                        currentPage={numberPage}
                        onPageChange={(page) => {}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">
                    ຈັດການລາຍເຊັນ {countData || 0} ລາຍການ
                  </h4>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive mt-4">
                  <table
                    id="basic-table"
                    className="table table-striped mb-0 table-sm"
                    role="grid"
                  >
                    <thead>
                      <tr>
                        <th>ລຳດັບ</th>
                        <th>ເອກະສານ</th>
                        <th>ເຊັນໂດຍ</th>
                        <th>ລາຍລະອຽດ</th>
                        <th>ພະແນກ</th>
                        <th>ວັນທີ່ເຊັນ</th>
                        <th className="text-center">ລາຍເຊັນ</th>
                        <th className="text-center">ຈັດການ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="text-nowrap" align="top">
                            {item?.documentCreatorID?.documentCode
                              ? item?.documentCreatorID?.documentCode
                              : "-"}
                            <hr className="m-1" />
                            {item?.documentCreatorID?.title
                              ? item?.documentCreatorID?.title
                              : "-"}
                          </td>
                          <td className="text-nowrap">
                            {item?.signatureID?.title
                              ? item?.signatureID?.title
                              : "-"}
                          </td>
                          <td className="text-nowrap">
                            {item?.signatureID?.description
                              ? item?.signatureID?.description
                              : "-"}
                          </td>
                          <td className="text-nowrap">
                            <QueryDepartment signID={item?.signatureID?._id} />
                          </td>
                          <td className="text-nowrap">
                            {formatDateDash(item?.createdAt || "")}
                          </td>
                          <td className="text-end">
                            <img
                              className="img-xl rounded-circle"
                              src={item?.licence ? item?.licence : image}
                              style={{
                                width: 100,
                                height: 100,
                                border: "2px solid #eee",
                              }}
                            />
                          </td>
                          <td className="text-center">
                            <a href="javascript:void();">
                              <i
                                className="icon-trash text-danger fs-3"
                                onClick={() =>
                                  _deleteSignature_Sub_Provider(item?._id)
                                }
                              />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
