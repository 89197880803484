// import { useLazyQuery } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import { getStaffLogin, messageError, messageSuccess, valiDate } from "../../helper";
import { UPDATE_FOLDER } from "./apollo";
import { QUERY_LOGGERS } from "../logger/apollo";

export default function EditFolder({ data, newLoad, setNewLoad }) {
  const _user = getStaffLogin().data;
  const [show, setShow] = useState(false);
  const [listLogger, setListLogger] = useState([]);
  const [updateFolder] = useMutation(UPDATE_FOLDER);
  const handleClose = () => setShow(false);
  const [queryLogger, { data: resultData, loading }] = useLazyQuery(
    QUERY_LOGGERS,
    { fetchPolicy: "cache-and-network" }
  );
  useEffect(() => {
    queryLogger({
      variables: {
        where:{
          departmentID: parseInt(_user?.department?._id),
        },
        limit: parseInt(1000),
        orderBy: "createdAt_DESC",
      },
    });
  }, []);

  useEffect(() => {
    if (resultData) {
      setListLogger(resultData?.documentLoggers?.data);
    }
  }, [resultData]);
  
  return (
    <Formik
      initialValues={{
        loggerID: data?.loggerID?._id,
        title: data?.title,
      }}
      enableReinitialize={true}
      validate={(values) => {
        const errors = {};
        if (!values.loggerID) {
          errors.loggerID = "error";
        }
        if (!values.title) {
          errors.title = "error";
        }
        return errors;
      }}
      onSubmit={async (values) => {
        try {
          const a = {
            variables: {
              data: {
                loggerID: parseInt(values?.loggerID),
                title: String(values?.title),
              },
              where: {
                _id: parseInt(data?._id),
              },
            },
          };

          let __updateFolder = await updateFolder({
            variables: {
              data: {
                loggerID: parseInt(values?.loggerID),
                title: String(values?.title),
              },
              where: {
                _id: parseInt(data?._id),
              },
            },
          });

          if (__updateFolder) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setShow(false);
            setNewLoad(!newLoad);
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການເຂົ້າລະບົບມີຄວາມຂັດຂ້ອງ");
        }
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <React.Fragment>
          <button
            className="btn btn-info"
            onClick={() => setShow(true)}
          >
            <i className="icon-edit-3" />
          </button>
          <Modal
            centered
            show={show}
            onHide={() => setShow(false)}
            animation={false}
            backdrop="static"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title className="fs-5">
                <i className="icon-edit1" /> ແກ້ໄຂແຟ້ມເອກະສານ
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>ຕູ້ເອກະສານ {valiDate()}</label>
                <select
                  className={
                    errors.loggerID
                      ? "form-control form-control-lg mb-3 mt-2 is-invalid"
                      : "form-control form-control-lg mb-3 mt-2 invalid"
                  }
                  name="loggerID"
                  onChange={handleChange}
                  value={values?.loggerID}
                >
                  {listLogger?.map((item, index) => (
                    <option
                      selected={
                        data?.loggerID?._id === item?._id ? true : false
                      }
                      value={item?._id}
                    >
                      {item?.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>ຊື່ແຟ້ມ ຫຼື ເບີແຟ້ມ {valiDate()}</label>
                <input
                  type="text"
                  className={
                    errors.title
                      ? "form-control form-control-lg mb-3 mt-2 is-invalid"
                      : "form-control form-control-lg mb-3 mt-2 invalid"
                  }
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  placeholder="ປ້ອນຂໍ້ມູນ..."
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary btn-lg "
                onClick={() => handleSubmit()}
              >
                <i className="icon-check-circle me-1" />
                ບັນທຶກ
              </button>
              <button
                type="button"
                className="btn btn-light btn-lg "
                onClick={() => handleClose()}
              >
                <i className="icon-x me-2" />
                ຍົກເລີກ
              </button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      )}
    </Formik>
  );
}
