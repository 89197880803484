// import { useLazyQuery } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  gcp_docs,
  DisPlayFiles,
  formatDateDash,
  getStaffLogin
} from "../../helper";
import useRouter from "use-react-router";
import { QUERY_DOCUMENTS } from "../documents/apollo";

export default function DetailDocs() {
  const { match } = useRouter();
  const _id = match.params.id;
  const _user = getStaffLogin().data
  const [result, setListDocs] = useState([]);

  const [queryFolder, { data: resultData }] = useLazyQuery(
    QUERY_DOCUMENTS,
    { fetchPolicy: "cache-and-network" }
  );
  useEffect(() => {
    queryFolder({
      variables: {
        where: {
          departmentID: parseInt(_user?.department?._id),
          folderID: parseInt(_id),
        },
        orderBy: "createdAt_DESC",
      },
    });
  }, []);

  useEffect(() => {
    if (resultData) {
      setListDocs(resultData?.documents?.data);
    }
  }, [resultData]);
  const total = resultData?.documents?.total || 0;

  return (

    <React.Fragment>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <div className="col-lg-12">
          <div className="tab-content mt-2" id="pills-tabContent">
            <h3 className="my-3">ເອກະສານທັງໝົດ {total} ໄຟລ໌</h3>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
              {result && result?.map((item) => (
                item?.files.split(".").pop() === "pdf" ||
                  item?.files.split(".").pop() === "presentation" ||
                  item?.files.split(".").pop() === "pptx" ||
                  item?.files.split(".").pop() === "ppt" ||
                  item?.files.split(".").pop() === "pptm" ||
                  item?.files.split(".").pop() === "document" ||
                  item?.files.split(".").pop() === "docx" ||
                  item?.files.split(".").pop() === "doc" ||
                  item?.files.split(".").pop() === "docm" ||
                  item?.files.split(".").pop() === "dotx" ||
                  item?.files.split(".").pop() === "xlsx" ||
                  item?.files.split(".").pop() === "xls" ||
                  item?.files.split(".").pop() === "xlsm" ||
                  item?.files.split(".").pop() === "sheet" ?
                  (
                    <div className="col">
                      <div className="card iq-file-manager">
                        <div className="card-body card-thumbnail">
                          <div className="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
                            <DisPlayFiles file={item?.files} className="file" width={100} height={100} item={item} />
                          </div>
                          <div className="mt-1">
                            <div className="d-flex justify-content-between">
                              <p className="small">
                                ວັນທີ່ສ້າງ: {formatDateDash(item?.createdAt)}
                              </p>
                            </div>
                            <div className="d-flex align-items-center text-primary gap-2 mb-2">
                              <i className="icon-unfold_more" />
                              <p className="mb-0 text-primary fw-bolder me-3">
                                {item?.documentID
                                  ? item?.documentID
                                  : "No Document ID"}
                              </p>
                            </div>
                            <div className="d-flex align-items-center text-primary gap-2 mb-2">
                              <i className="icon-text_fields" />
                              <p className=" mb-0 text-dark me-3">
                                {item?.title?.substring(25)
                                  ? item?.title?.substring(0, 25) + "..."
                                  : item?.title}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <a className="btn btn-light btn-sm w-100"
                                href={gcp_docs + item?.files} target="_blank"
                              >
                                <i class="fa-solid fa-circle-down me-1 fs-4" />
                                ດາວໂຫຼດ
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  ) : null
              ))}
            </div>
            <h4 className="py-3 mb-0">ຮູບພາບ</h4>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
              {result && result?.map((item) => (
                item?.files.split(".").pop() === "jpeg" ||
                  item?.files.split(".").pop() === "jpg" ||
                  item?.files.split(".").pop() === "png" ||
                  item?.files.split(".").pop() === "gif" ||
                  item?.files.split(".").pop() === "svg" ? (
                  <div className="col">
                    <div className="card iq-file-manager">
                      <div className="card-body card-thumbnail">
                        <div className="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
                          {/* {disPlayFiles(item?.files)} */}
                          <DisPlayFiles file={item?.files} item={item} />
                        </div>
                        <div className="mt-2">
                          <div className="d-flex justify-content-between">
                            <p className="small">
                              ວັນທີ່ສ້າງ: {formatDateDash(item?.createdAt)}
                            </p>
                          </div>
                          <div className="d-flex align-items-center text-primary gap-2 mb-2">
                            <i className="icon-unfold_more" />
                            <p className="mb-0 text-primary fw-bolder me-3">
                              {item?.documentID
                                ? item?.documentID
                                : "No Document ID"}
                            </p>
                          </div>
                          <div className="d-flex align-items-center text-primary gap-2 mb-2">
                            <i className="icon-text_fields" />
                            <p className=" mb-0 text-dark me-3">
                              {item?.title?.substring(30)
                                ? item?.title?.substring(0, 30) + "..."
                                : item?.title}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                ) : null
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}
