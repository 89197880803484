import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import {USER_KEY} from '../helper'
function PrivateRoute({ component: Component, headerTitle, ...rest }) {
  const _local = localStorage.getItem(USER_KEY);

  useEffect(() => {
    if (_local) {
      const _json = JSON.parse(_local);
    }
  }, [_local]);

  return (
    <Route
      {...rest}
      render={(props) =>
        _local ? (
          <div>
            <Component {...props} />
          </div>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default PrivateRoute;
