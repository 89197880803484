import React, { useState } from "react";
import normalLogo from "../img/logo_next_day.png";
import miniLogo from "../img/ans-app-v3.0.png";
export default function Sidenav({ location, history }) {
  const [activeStatus, setActiveStatus] = useState(
    location.pathname.split("/")[1]?.split("/")[0]
  );

  const onRouting = (route, path) => {
    history.push(route ? route : location.pathname);
    setActiveStatus(route.split("/")[1]?.split("/")[0]);
  };

  return (
    <aside
      className="sidebar sidebar-base"
      id="first-tour"
      data-toggle="main-sidebar"
      data-sidebar="responsive"
    >
      <div className="sidebar-header d-flex align-items-center justify-content-start">
        <a
          href="javascript:;"
          onClick={() => history.push("/home")}
          className="navbar-brand"
        >
          <div className="logo-main">
            <div className="logo-normal">
              <img src={normalLogo} className="img-fluid" />
            </div>
            <div className="logo-mini">
              <img src={miniLogo} className="img-fluid" />
            </div>
          </div>
        </a>
      </div>
      <div className="sidebar-body pt-0 data-scrollbar pb-4">
        <div className="sidebar-list pb-4">
          <a
            className={
              activeStatus && activeStatus === "add_new_documents"
                ? "nav-item active btn btn-primary w-100 btn-lg my-2"
                : "nav-item btn btn-light w-100 btn-lg my-2"
            }
            aria-current="page"
            onClick={() => onRouting("/add_new_documents", "add_new_documents")}
          >
            <i className="icon-plus-circle" /> ສ້າງເອກະສານໃໝ່
          </a>

          <ul className="navbar-nav iq-main-menu" id="sidebar-menu">
            <li
              className={
                activeStatus && activeStatus === "home"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "home"
                    ? "nav-link active"
                    : "nav-link"
                }
                aria-current="page"
                onClick={() => onRouting("/home", "home")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ໜ້າຫຼັກ"
                  data-bs-placement="right"
                >
                  <svg
                    width={20}
                    className="icon-30"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z"
                      fill="currentColor"
                    />
                  </svg>
                </i>
                <span className="item-name">ໜ້າຫຼັກ</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "documents"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "documents"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/documents", "documents")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ຈັດການເອກະສານທົ່ວໄປ"
                  data-bs-placement="right"
                >
                  <svg
                    className="icon-30"
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
                <span className="item-name">ຈັດການເອກະສານທົ່ວໄປ</span>
              </a>
            </li>

            <li
              className={
                activeStatus && activeStatus === "private_documents"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "private_documents"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() =>
                  onRouting("/private_documents", "private_documents")
                }
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ຈັດການເອກະສານສ່ວນຕົວ"
                  data-bs-placement="right"
                >
                  <svg
                    className="icon-32"
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M12.0865 22C11.9627 22 11.8388 21.9716 11.7271 21.9137L8.12599 20.0496C7.10415 19.5201 6.30481 18.9259 5.68063 18.2336C4.31449 16.7195 3.5544 14.776 3.54232 12.7599L3.50004 6.12426C3.495 5.35842 3.98931 4.67103 4.72826 4.41215L11.3405 2.10679C11.7331 1.96656 12.1711 1.9646 12.5707 2.09992L19.2081 4.32684C19.9511 4.57493 20.4535 5.25742 20.4575 6.02228L20.4998 12.6628C20.5129 14.676 19.779 16.6274 18.434 18.1581C17.8168 18.8602 17.0245 19.4632 16.0128 20.0025L12.4439 21.9088C12.3331 21.9686 12.2103 21.999 12.0865 22Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M11.3194 14.3209C11.1261 14.3219 10.9328 14.2523 10.7838 14.1091L8.86695 12.2656C8.57097 11.9793 8.56795 11.5145 8.86091 11.2262C9.15387 10.9369 9.63207 10.934 9.92906 11.2193L11.3083 12.5451L14.6758 9.22479C14.9698 8.93552 15.448 8.93258 15.744 9.21793C16.041 9.50426 16.044 9.97004 15.751 10.2574L11.8519 14.1022C11.7049 14.2474 11.5127 14.3199 11.3194 14.3209Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
                <span className="item-name">ຈັດການເອກະສານສ່ວນຕົວ</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "all_file"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "all_file"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/all_file", "all_file")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ໄຟລ໌ເອກະສານທັງໝົດ"
                  data-bs-placement="right"
                >
                  <svg
                    class="icon-32"
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M18.8088 9.021C18.3573 9.021 17.7592 9.011 17.0146 9.011C15.1987 9.011 13.7055 7.508 13.7055 5.675V2.459C13.7055 2.206 13.5036 2 13.253 2H7.96363C5.49517 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59022 22 8.16958 22H16.0463C18.5058 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.299 9.012 20.0475 9.013C19.6247 9.016 19.1177 9.021 18.8088 9.021Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M16.0842 2.56737C15.7852 2.25637 15.2632 2.47037 15.2632 2.90137V5.53837C15.2632 6.64437 16.1742 7.55437 17.2802 7.55437C17.9772 7.56237 18.9452 7.56437 19.7672 7.56237C20.1882 7.56137 20.4022 7.05837 20.1102 6.75437C19.0552 5.65737 17.1662 3.69137 16.0842 2.56737Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.97398 11.3877H12.359C12.77 11.3877 13.104 11.0547 13.104 10.6437C13.104 10.2327 12.77 9.89868 12.359 9.89868H8.97398C8.56298 9.89868 8.22998 10.2327 8.22998 10.6437C8.22998 11.0547 8.56298 11.3877 8.97398 11.3877ZM8.97408 16.3819H14.4181C14.8291 16.3819 15.1631 16.0489 15.1631 15.6379C15.1631 15.2269 14.8291 14.8929 14.4181 14.8929H8.97408C8.56308 14.8929 8.23008 15.2269 8.23008 15.6379C8.23008 16.0489 8.56308 16.3819 8.97408 16.3819Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
                <span className="item-name">ໄຟລ໌ເອກະສານທັງໝົດ</span>
              </a>
            </li>
            <li>
              <hr className="hr-horizontal" />
            </li>
            <li className="nav-item static-item">
              <a
                className="nav-link static-item disabled text-start"
                href="#"
                tabIndex={-1}
              >
                <strong className="default-icon">ການສົ່ງ ແລະ ຮັບເອກະສານ</strong>
                <span className="mini-icon">
                  <svg
                    class="icon-32"
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.2428 4.73756C15.2428 6.95855 17.0459 8.75902 19.2702 8.75902C19.5151 8.75782 19.7594 8.73431 20 8.68878V16.6615C20 20.0156 18.0215 22 14.6624 22H7.34636C3.97851 22 2 20.0156 2 16.6615V9.3561C2 6.00195 3.97851 4 7.34636 4H15.3131C15.2659 4.243 15.2423 4.49001 15.2428 4.73756ZM13.15 14.8966L16.0078 11.2088V11.1912C16.2525 10.8625 16.1901 10.3989 15.8671 10.1463C15.7108 10.0257 15.5122 9.97345 15.3167 10.0016C15.1211 10.0297 14.9453 10.1358 14.8295 10.2956L12.4201 13.3951L9.6766 11.2351C9.51997 11.1131 9.32071 11.0592 9.12381 11.0856C8.92691 11.1121 8.74898 11.2166 8.63019 11.3756L5.67562 15.1863C5.57177 15.3158 5.51586 15.4771 5.51734 15.6429C5.5002 15.9781 5.71187 16.2826 6.03238 16.3838C6.35288 16.485 6.70138 16.3573 6.88031 16.0732L9.35125 12.8771L12.0948 15.0283C12.2508 15.1541 12.4514 15.2111 12.6504 15.1863C12.8494 15.1615 13.0297 15.0569 13.15 14.8966Z"
                      fill="currentColor"
                    ></path>
                    <circle
                      opacity="0.4"
                      cx="19.5"
                      cy="4.5"
                      r="2.5"
                      fill="currentColor"
                    ></circle>
                  </svg>
                </span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "send"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "send"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/send", "send")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ສົ່ງເອກະສານ"
                  data-bs-placement="right"
                >
                  <svg
                    class="icon-32"
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.4274 2.5783C20.9274 2.0673 20.1874 1.8783 19.4974 2.0783L3.40742 6.7273C2.67942 6.9293 2.16342 7.5063 2.02442 8.2383C1.88242 8.9843 2.37842 9.9323 3.02642 10.3283L8.05742 13.4003C8.57342 13.7163 9.23942 13.6373 9.66642 13.2093L15.4274 7.4483C15.7174 7.1473 16.1974 7.1473 16.4874 7.4483C16.7774 7.7373 16.7774 8.2083 16.4874 8.5083L10.7164 14.2693C10.2884 14.6973 10.2084 15.3613 10.5234 15.8783L13.5974 20.9283C13.9574 21.5273 14.5774 21.8683 15.2574 21.8683C15.3374 21.8683 15.4274 21.8683 15.5074 21.8573C16.2874 21.7583 16.9074 21.2273 17.1374 20.4773L21.9074 4.5083C22.1174 3.8283 21.9274 3.0883 21.4274 2.5783Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.01049 16.8079C2.81849 16.8079 2.62649 16.7349 2.48049 16.5879C2.18749 16.2949 2.18749 15.8209 2.48049 15.5279L3.84549 14.1619C4.13849 13.8699 4.61349 13.8699 4.90649 14.1619C5.19849 14.4549 5.19849 14.9299 4.90649 15.2229L3.54049 16.5879C3.39449 16.7349 3.20249 16.8079 3.01049 16.8079ZM6.77169 18.0003C6.57969 18.0003 6.38769 17.9273 6.24169 17.7803C5.94869 17.4873 5.94869 17.0133 6.24169 16.7203L7.60669 15.3543C7.89969 15.0623 8.37469 15.0623 8.66769 15.3543C8.95969 15.6473 8.95969 16.1223 8.66769 16.4153L7.30169 17.7803C7.15569 17.9273 6.96369 18.0003 6.77169 18.0003ZM7.02539 21.5683C7.17139 21.7153 7.36339 21.7883 7.55539 21.7883C7.74739 21.7883 7.93939 21.7153 8.08539 21.5683L9.45139 20.2033C9.74339 19.9103 9.74339 19.4353 9.45139 19.1423C9.15839 18.8503 8.68339 18.8503 8.39039 19.1423L7.02539 20.5083C6.73239 20.8013 6.73239 21.2753 7.02539 21.5683Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
                <span className="item-name">ສົ່ງເອກະສານ</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "inbox"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "inbox"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/inbox", "inbox")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ກ່ອງຮັບເອກະສານ"
                  data-bs-placement="right"
                >
                  <svg
                    class="icon-32"
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M22 15.94C22 18.73 19.76 20.99 16.97 21H16.96H7.05C4.27 21 2 18.75 2 15.96V15.95C2 15.95 2.006 11.524 2.014 9.298C2.015 8.88 2.495 8.646 2.822 8.906C5.198 10.791 9.447 14.228 9.5 14.273C10.21 14.842 11.11 15.163 12.03 15.163C12.95 15.163 13.85 14.842 14.56 14.262C14.613 14.227 18.767 10.893 21.179 8.977C21.507 8.716 21.989 8.95 21.99 9.367C22 11.576 22 15.94 22 15.94Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M21.4759 5.67351C20.6099 4.04151 18.9059 2.99951 17.0299 2.99951H7.04988C5.17388 2.99951 3.46988 4.04151 2.60388 5.67351C2.40988 6.03851 2.50188 6.49351 2.82488 6.75151L10.2499 12.6905C10.7699 13.1105 11.3999 13.3195 12.0299 13.3195C12.0339 13.3195 12.0369 13.3195 12.0399 13.3195C12.0429 13.3195 12.0469 13.3195 12.0499 13.3195C12.6799 13.3195 13.3099 13.1105 13.8299 12.6905L21.2549 6.75151C21.5779 6.49351 21.6699 6.03851 21.4759 5.67351Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
                <span className="item-name">ກ່ອງຮັບເອກະສານທົ່ວໄປ</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "inbox_isPrivate"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "inbox_isPrivate"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/inbox_isPrivate", "inbox_isPrivate")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ກ່ອງຮັບເອກະສານ"
                  data-bs-placement="right"
                >
                  <svg
                    class="icon-32"
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M22 15.94C22 18.73 19.76 20.99 16.97 21H16.96H7.05C4.27 21 2 18.75 2 15.96V15.95C2 15.95 2.006 11.524 2.014 9.298C2.015 8.88 2.495 8.646 2.822 8.906C5.198 10.791 9.447 14.228 9.5 14.273C10.21 14.842 11.11 15.163 12.03 15.163C12.95 15.163 13.85 14.842 14.56 14.262C14.613 14.227 18.767 10.893 21.179 8.977C21.507 8.716 21.989 8.95 21.99 9.367C22 11.576 22 15.94 22 15.94Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M21.4759 5.67351C20.6099 4.04151 18.9059 2.99951 17.0299 2.99951H7.04988C5.17388 2.99951 3.46988 4.04151 2.60388 5.67351C2.40988 6.03851 2.50188 6.49351 2.82488 6.75151L10.2499 12.6905C10.7699 13.1105 11.3999 13.3195 12.0299 13.3195C12.0339 13.3195 12.0369 13.3195 12.0399 13.3195C12.0429 13.3195 12.0469 13.3195 12.0499 13.3195C12.6799 13.3195 13.3099 13.1105 13.8299 12.6905L21.2549 6.75151C21.5779 6.49351 21.6699 6.03851 21.4759 5.67351Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
                <span className="item-name">ກ່ອງຮັບເອກະສານສ່ວນຕົວ</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "document_group"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "document_group"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/document_group", "document_group")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ກຸ່ມເອກະສານ"
                  data-bs-placement="right"
                >
                  <svg
                    width={32}
                    className="icon-32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.4"
                      d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.4"
                      d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z"
                      fill="currentColor"
                    />
                    <path
                      d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.4"
                      d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z"
                      fill="currentColor"
                    />
                    <path
                      d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z"
                      fill="currentColor"
                    />
                  </svg>
                </i>
                <span className="item-name">ກຸ່ມເອກະສານ</span>
              </a>
            </li>

            <li>
              <hr className="hr-horizontal" />
            </li>
            <li className="nav-item static-item">
              <a
                className="nav-link static-item disabled text-start"
                href="#"
                tabIndex={-1}
              >
                <strong className="default-icon">ຈັດການຂໍ້ມູນທົ່ວໄປ</strong>
                <span
                  className="mini-icon"
                  data-bs-toggle="tooltip"
                  title="Elements"
                  data-bs-placement="right"
                >
                  <svg
                    class="icon-32"
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0122 14.8299C10.4077 14.8299 9.10986 13.5799 9.10986 12.0099C9.10986 10.4399 10.4077 9.17993 12.0122 9.17993C13.6167 9.17993 14.8839 10.4399 14.8839 12.0099C14.8839 13.5799 13.6167 14.8299 12.0122 14.8299Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M21.2301 14.37C21.036 14.07 20.76 13.77 20.4023 13.58C20.1162 13.44 19.9322 13.21 19.7687 12.94C19.2475 12.08 19.5541 10.95 20.4228 10.44C21.4447 9.87 21.7718 8.6 21.179 7.61L20.4943 6.43C19.9118 5.44 18.6344 5.09 17.6226 5.67C16.7233 6.15 15.5685 5.83 15.0473 4.98C14.8838 4.7 14.7918 4.4 14.8122 4.1C14.8429 3.71 14.7203 3.34 14.5363 3.04C14.1582 2.42 13.4735 2 12.7172 2H11.2763C10.5302 2.02 9.84553 2.42 9.4674 3.04C9.27323 3.34 9.16081 3.71 9.18125 4.1C9.20169 4.4 9.10972 4.7 8.9462 4.98C8.425 5.83 7.27019 6.15 6.38109 5.67C5.35913 5.09 4.09191 5.44 3.49917 6.43L2.81446 7.61C2.23194 8.6 2.55897 9.87 3.57071 10.44C4.43937 10.95 4.74596 12.08 4.23498 12.94C4.06125 13.21 3.87729 13.44 3.59115 13.58C3.24368 13.77 2.93709 14.07 2.77358 14.37C2.39546 14.99 2.4159 15.77 2.79402 16.42L3.49917 17.62C3.87729 18.26 4.58245 18.66 5.31825 18.66C5.66572 18.66 6.0745 18.56 6.40153 18.36C6.65702 18.19 6.96361 18.13 7.30085 18.13C8.31259 18.13 9.16081 18.96 9.18125 19.95C9.18125 21.1 10.1215 22 11.3069 22H12.6968C13.872 22 14.8122 21.1 14.8122 19.95C14.8429 18.96 15.6911 18.13 16.7029 18.13C17.0299 18.13 17.3365 18.19 17.6022 18.36C17.9292 18.56 18.3278 18.66 18.6855 18.66C19.411 18.66 20.1162 18.26 20.4943 17.62L21.2097 16.42C21.5776 15.75 21.6083 14.99 21.2301 14.37Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "query_licent"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "query_licent"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/query_licent", "query_licent")}
              >
                <i className="fa-solid fa-signature fs-3" />
                <span className="item-name">ຈັດການລາຍເຊັນ</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "logger"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "logger"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/logger", "logger")}
              >
                <i className="fa-solid fa-door-closed fs-3" />
                <span className="item-name">ຈັດການຕູ້ເອກະສານ</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "folder"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "folder"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/folder", "folder")}
              >
                <i className="fa-solid fa-folder-open fs-3" />
                <span className="item-name">ຈັດການແຟ້ມເອກະສານ</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "type_documents"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "type_documents"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/type_documents", "type_documents")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ຈັດການໝວດເອກະສານ"
                  data-bs-placement="right"
                >
                  <svg
                    width={32}
                    className="icon-32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M21.25 13.4764C20.429 13.4764 19.761 12.8145 19.761 12.001C19.761 11.1865 20.429 10.5246 21.25 10.5246C21.449 10.5246 21.64 10.4463 21.78 10.3076C21.921 10.1679 22 9.97864 22 9.78146L21.999 7.10415C21.999 4.84102 20.14 3 17.856 3H6.144C3.86 3 2.001 4.84102 2.001 7.10415L2 9.86766C2 10.0648 2.079 10.2541 2.22 10.3938C2.36 10.5325 2.551 10.6108 2.75 10.6108C3.599 10.6108 4.239 11.2083 4.239 12.001C4.239 12.8145 3.571 13.4764 2.75 13.4764C2.336 13.4764 2 13.8093 2 14.2195V16.8949C2 19.158 3.858 21 6.143 21H17.857C20.142 21 22 19.158 22 16.8949V14.2195C22 13.8093 21.664 13.4764 21.25 13.4764Z"
                      fill="currentColor"
                    />
                    <path
                      d="M15.4303 11.5887L14.2513 12.7367L14.5303 14.3597C14.5783 14.6407 14.4653 14.9177 14.2343 15.0837C14.0053 15.2517 13.7063 15.2727 13.4543 15.1387L11.9993 14.3737L10.5413 15.1397C10.4333 15.1967 10.3153 15.2267 10.1983 15.2267C10.0453 15.2267 9.89434 15.1787 9.76434 15.0847C9.53434 14.9177 9.42134 14.6407 9.46934 14.3597L9.74734 12.7367L8.56834 11.5887C8.36434 11.3907 8.29334 11.0997 8.38134 10.8287C8.47034 10.5587 8.70034 10.3667 8.98134 10.3267L10.6073 10.0897L11.3363 8.61268C11.4633 8.35868 11.7173 8.20068 11.9993 8.20068H12.0013C12.2843 8.20168 12.5383 8.35968 12.6633 8.61368L13.3923 10.0897L15.0213 10.3277C15.2993 10.3667 15.5293 10.5587 15.6173 10.8287C15.7063 11.0997 15.6353 11.3907 15.4303 11.5887Z"
                      fill="currentColor"
                    />
                  </svg>
                </i>
                <span className="item-name">ຈັດການໝວດເອກະສານ</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="sidebar-footer" />
    </aside>
  );
}
