import { gql } from "apollo-boost";

export const QUERY_LOGGERS = gql`
query DocumentLoggers($where: DocumentLoggerWhereInput, $orderBy: OrderByInput, $skip: Int, $limit: Int) {
  documentLoggers(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
    total
    data {
      _id
      loggerID
      title
      departmentID {
        _id
        title_lao
        title_eng
      }
      createdAt
      createdBy {
        _id
        firstName
        lastName
      }
    }
  }
}
`;
export const CREATE_LOGGER = gql`
mutation CreateDocumentLogger($data: DocumentLoggerInput!) {
  createDocumentLogger(data: $data) {
    _id
  }
}
`;

export const UPDATE_LOGGER = gql`
 mutation UpdateDocumentLogger($data: DocumentLoggerInput!, $where: DocumentLoggerWhereInputOne!) {
  updateDocumentLogger(data: $data, where: $where) {
    _id
  }
}
`;

export const DELETE_LOGGER = gql`
mutation DeleteDocumentLogger($where: DocumentLoggerWhereInputOne!) {
  deleteDocumentLogger(where: $where) {
    _id
  }
}
`;
