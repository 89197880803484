import { gql } from "apollo-boost";
export const QUERY_NEW_DOCUMENTS = gql`
  query DocumentCreators(
    $where: DocumentCreatorWhereInput
    $orderBy: OrderByInput
    $skip: Int
    $limit: Int
  ) {
    documentCreators(
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      total
      data {
        _id
        documentCode
        documentDate
        department {
          _id
          title_lao
        }
        typeDocument {
          _id
          title
        }
        title
        subTitle
        referenceText
        details
        subDetail
        summaryText
        createdAt
        createdBy {
          _id
          firstName
          lastName
        }
      }
    }
  }
`;



export const QUERY_DEPARTMENTS = gql`
  query Query(
    $where: DepartmentWhereInput
    $orderBy: OrderByInput
    $skip: Int
    $limit: Int
    $noLimit: Boolean
  ) {
    departments(
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
      noLimit: $noLimit
    ) {
      data {
        _id
        title_lao
      }
    }
  }
`;
export const QUERY_SIGNATURE_PROVIDERS = gql`
  query Query(
    $where: SignatureProviderWhereInput
    $orderBy: OrderByInput
    $skip: Int
    $limit: Int
  ) {
    SignatureProviders(
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      total
      data {
        _id
        title
        description
        department {
          _id
          title_lao
        }
      }
    }
  }
`;
export const QUERY_SIGNATURE_SUB_PROVIDERS = gql`
query Query($where: SignatureSubProviderWhereInput, $orderBy: OrderByInput, $skip: Int, $limit: Int) {
  SignatureSubProviders(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
    total
    data {
      _id
      signatureID {
        _id
        title
        description
      }
      documentCreatorID {
        _id
        documentCode
        title
      }
      createdAt
      licence
      createdBy {
        _id
        firstName
        lastName
      }
      sinatureDate
      sinatureBy {
        _id
        firstName
        lastName
      }
    }
  }
}
`;
export const CREATE_NEW_DOCUMENT = gql`
  mutation Mutation($data: DocumentCreatorInput!) {
    createDocumentCreator(data: $data) {
      _id
    }
  }
`;
export const CREATE_SIGNATURE_PROVIDER = gql`
  mutation CreateSignatureProvider($data: SignatureProviderInput!) {
    createSignatureProvider(data: $data) {
      _id
    }
  }
`;
export const DELETE_NEW_DOCUMENT = gql`
  mutation Mutation($where: DocumentCreatorWhereInputOne!) {
    deleteDocumentCreator(where: $where) {
      _id
    }
  }
`;

export const UPDATE_NEW_DOCUMENT = gql`
  mutation Mutation(
    $data: DocumentCreatorInput!
    $where: DocumentCreatorWhereInputOne!
  ) {
    updateDocumentCreator(data: $data, where: $where) {
      _id
    }
  }
`;
export const CREATE_SIGNATURE_SUB_PROVIDER =  gql`
  mutation Mutation($data: SignatureSubProviderInput!) {
    createSignatureSubProvider(data: $data) {
      _id
    }
  }
`;
export const DELETE_SIGNATURE_SUB_PROVIDER = gql`
mutation Mutation($where: SignatureSubProviderWhereInputOne!) {
  deleteSignatureSubProvider(where: $where) {
    _id
  }
}
`;
export const UPDATE_SIGNATURE_SUB_PROVIDER = gql`
mutation Mutation($data: SignatureSubProviderInput!, $where: SignatureSubProviderWhereInputOne!) {
  updateSignatureSubProvider(data: $data, where: $where) {
    _id
  }
}
`;


