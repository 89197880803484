// import { useLazyQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import {
  getStaffLogin,
  messageError,
  messageSuccess,
} from "../../helper";
import { UPDATE_SIGNATURE_SUB_PROVIDER } from "./apollo";
import SignatureCanvas from 'react-signature-canvas';
import moment from "moment";

export default function SignatureNewDocument({ onLoadData, send_ID }) {
  const [show, setShow] = useState(false);
  const userInfo = getStaffLogin().data;
  const [sigCanvas, setSigCanvas] = useState(null);
  const today = moment().format("YYYY-MM-DD");

  const [updateSignatureSubProvider] = useMutation(
    UPDATE_SIGNATURE_SUB_PROVIDER
  );
  return (
    <Formik
      initialValues={{
      }}
      enableReinitialize={false}
      validate={(values) => {
        const errors = {};
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        try {
          let __updateSignatureSubProvider = await updateSignatureSubProvider({
            variables: {
              data: {
                licence: sigCanvas?.toDataURL(),
                sinatureBy: parseInt(userInfo?._id),
                sinatureDate: today,
              },
              where: {
                _id: send_ID
              }
            },
          });

          if (__updateSignatureSubProvider) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            sigCanvas.clear();
            onLoadData();
            setShow(false);
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການເຂົ້າລະບົບມີຄວາມຂັດຂ້ອງ");
        }
      }}
    >
      {({ errors, handleChange, handleSubmit }) => (
        <React.Fragment>
          <button
            type="button"
            className="btn btn-default btn-sm"
            id="printPageButton"
            onClick={() => setShow(true)}
          >
            ກົດເພື່ອເຊັນ
          </button>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            animation={false}
            centered
            backdrop="static"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title className="fs-5">
                <svg
                  className="icon-22"
                  width={22}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                    fill="currentColor"
                  />
                </svg>
                ເຊັນເອກະສານ
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ul className="row flex-wrap align-items-center list-inline m-0 p-0">
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label className="align-top">ລາຍເຊັນ </label>
                    <SignatureCanvas
                      canvasProps={{
                        width: 730,
                        height: 300,
                        className: "licence border w-100",
                      }}
                      ref={(ref) => setSigCanvas(ref)}
                      penColor="blue"
                    />
                    <i className="text-danger">{errors?.licence}</i>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary btn-lg me-2"
                    onClick={() => { handleSubmit(); }}
                  >
                    <i className="icon-check-circle me-1" />
                    ບັນທຶກ
                  </button>
                  <button
                    type="button"
                    className="btn btn-light btn-lg "
                    onClick={() => {
                      sigCanvas.clear();
                    }}
                  >
                    <i className="icon-x me-2" />
                    ຍົກເລີກ
                  </button>{" "}
                </li>
              </ul>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      )}
    </Formik>
  );
}
