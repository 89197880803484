import { gql } from "apollo-boost";

export const QUERY_USER_INFO = gql `
query Employees($where: EmployeeWhereInput, $orderBy: OrderByInput, $skip: Int, $limit: Int) {
    employees(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
      total
      data {
        _id
        cvID
        profileImage
        firstName
        lastName
        gender
        phoneNumber
        position {
          _id
          title_lao
        }
        department {
          _id
          title_lao
        }
        role
      }
    }
  }
`;