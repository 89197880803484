// import { useLazyQuery } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";

import {
  getStaffLogin,
  messageError,
  messageSuccess,
  toDayDash,
  valiDate,
} from "../../helper";

import { CREATE_NEW_DOCUMENT } from "./apollo";
import SelectDepartment from "../../helper/componentsList/SelectDepartment";
import SelectTypeDocument from "../../helper/componentsList/SelectTypeDocument";

export default function AddNewDocument({ onSuccess }) {
  const _user = getStaffLogin().data;
  const [show, setShow] = useState(false);
  const [createDocument] = useMutation(CREATE_NEW_DOCUMENT);
  const handleClose = () => setShow(false);
  const toDayDate = toDayDash();
  const [doCumentDate, setDocumentDate] = useState(toDayDate);
  const [GetDepartment, setGetDepartment] = useState();
  const [GetDocument, setGetDocument] = useState();
  return (
    <Formik
      initialValues={{
        documentCode: "",
        title: "",
        subTitle: "",
        referenceText: "",
        details: "",
        subDetail: "",
        summaryText: "",
        // documentDate: "",
      }}
      enableReinitialize={false}
      validate={(values) => {
        const errors = {};

        if (!values.documentCode) {
          errors.documentCode = "ກະລຸນາປ້ອນເລກທີເອກະສານກ່ອນ!";
        }
        if (!values.title) {
          errors.title = "ກະລຸນາປ້ອນ(ຮຽນ)ກ່ອນ!";
        }
        if (!values.subTitle) {
          errors.subTitle = "ກະລຸນາປ້ອນ(ເລື່ອງ)ກ່ອນ!";
        }
        if (!values.referenceText) {
          errors.referenceText = "ກະລຸນາປ້ອນ(ອີງຕາມ)ກ່ອນ!";
        }
        if (!values.details) {
          errors.details = "ກະລຸນາປ້ອນ(ເນື້ອໃນເອກະສານ)ກ່ອນ!";
        }
        if (!values.summaryText) {
          errors.summaryText = "ກະລຸນາປ້ອນ(ຂໍ້ຄວາມສະຫລຸບ)ກ່ອນ!";
        }

        if (!doCumentDate) {
          errors.documentDate = "ກະລຸນາເລືອກວັນທີເອກະສານກ່ອນ!";
        }
        if (!GetDepartment) {
          errors.GetDepartment = "ກະລຸນາເລືອກພະແນກກ່ອນ!";
        }
        if (!GetDocument) {
          errors.GetDocument = "ກະລຸນາເລືອກໝວດເອກະສານກ່ອນ!";
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        try {
          let __createDocument = await createDocument({
            variables: {
              data: {
                documentCode: String(values.documentCode),
                department: parseInt(GetDepartment?._id),
                typeDocument: parseInt(GetDocument?._id),
                title: String(values.title),
                subTitle: String(values.subTitle),
                referenceText: String(values.referenceText),
                details: String(values.details),
                subDetail: String(values.subDetail),
                summaryText: String(values.summaryText),
                documentDate: String(doCumentDate),
              },
            },
          });
          if (__createDocument?.data?.createDocumentCreator?._id) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            resetForm();
            setGetDepartment();
            setGetDocument();
            onSuccess();
            setDocumentDate(toDayDate);
            setShow(false);
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການເຂົ້າລະບົບມີຄວາມຂັດຂ້ອງ");
        }
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <React.Fragment>
          <button
            className="btn btn-primary hvr-shutter-out-horizontal"
            data-bs-toggle="modal"
            type="submit"
            onClick={() => setShow(true)}
          >
            <i className="fas fa-plus me-1" />
            ສ້າງເອກະສານໃໝ່
          </button>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            animation={false}
            centered
            backdrop="static"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title className="fs-5">
                <svg
                  className="icon-22"
                  width={22}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                    fill="currentColor"
                  />
                </svg>
                ສ້າງເອກະສານໃໝ່
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ul className="row flex-wrap align-items-center list-inline m-0 p-0">
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ເລກທີເອກະສານ{valiDate()}
                    </label>
                    <input
                      type="text"
                      className={
                        errors.documentCode
                          ? "form-control is-invalid"
                          : "form-control  invalid"
                      }
                      placeholder="ເລກທີເອກະສານ"
                      name="documentCode"
                      value={values.documentCode}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{errors.documentCode}</span>
                  </div>
                </li>
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ພະແນກ {valiDate()}
                    </label>
                    <SelectDepartment
                      className={errors.GetDepartment ? "is-invalid" : ""}
                      onChange={(data) => {
                        setGetDepartment(data);
                      }}
                    />
                    <span className="text-danger">{errors.GetDepartment}</span>
                  </div>
                </li>
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ໝວດເອກະສານ{valiDate()}
                    </label>
                    <SelectTypeDocument
                      className={errors.GetDocument ? "is-invalid" : ""}
                      onChange={(data) => {
                        setGetDocument(data);
                      }}
                    />
                    <span className="text-danger">{errors.GetDocument}</span>
                  </div>
                </li>

                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ຮຽນ:{valiDate()}
                    </label>
                    <input
                      type="text"
                      className={
                        errors.title
                          ? "form-control is-invalid"
                          : "form-control invalid"
                      }
                      placeholder="ຮຽນ:"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{errors.title}</span>
                  </div>
                </li>
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ເລື່ອງ:{valiDate()}
                    </label>
                    <input
                      type="text"
                      className={
                        errors.subTitle
                          ? "form-control is-invalid"
                          : "form-control  invalid"
                      }
                      placeholder="ເລື່ອງ:"
                      name="subTitle"
                      value={values.subTitle}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{errors.subTitle}</span>
                  </div>
                </li>
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ອິງຕາມ:{valiDate()}
                    </label>
                    {/* textarea */}
                    <textarea
                      className={
                        errors.referenceText
                          ? "form-control is-invalid"
                          : "form-control  invalid"
                      }
                      // className="form-control shadow-none p-3 W-100"
                      rows="2"
                      placeholder="ອິງຕາມ:"
                      name="referenceText"
                      value={values.referenceText}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{errors.referenceText}</span>
                  </div>
                </li>
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">ຈຸດປະສົງ:</label>
                    <textarea
                      className="form-control shadow-none p-3 W-100"
                      rows="2"
                      placeholder="ຈຸດປະສົງ"
                      name="subDetail"
                      value={values.subDetail}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{errors.subDetail}</span>
                  </div>
                </li>
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ເນື້ອໃນເອກະສານ {valiDate()}
                    </label>
                    <textarea
                      className={
                        errors.details
                          ? "form-control shadow-none p-3 W-100 is-invalid"
                          : "form-control  invalid"
                      }
                      rows="5"
                      placeholder="ເນື້ອໃນເອກະສານ"
                      name="details"
                      value={values.details}
                      onChange={handleChange}
                    ></textarea>
                    <span className="text-danger">{errors.details}</span>
                  </div>
                </li>

                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ຂໍ້ຄວາມສະຫລຸບ: {valiDate()}
                    </label>
                    <textarea
                      className={
                        errors.summaryText
                          ? "form-control shadow-none p-3 W-100 is-invalid"
                          : "form-control  invalid"
                      }
                      rows="2"
                      placeholder="ຂໍ້ຄວາມສະຫລຸບ:"
                      name="summaryText"
                      value={values.summaryText}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{errors.summaryText}</span>
                  </div>
                </li>
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ວັນທີ່ເອກະສານ {valiDate()}
                    </label>
                    <input
                      type="date"
                      className={
                        errors.documentDate
                          ? "form-control is-invalid"
                          : "form-control  invalid"
                      }
                      placeholder="ວັນທີເອກະສານ"
                      name="documentDate"
                      value={doCumentDate}
                      onChange={(e) => setDocumentDate(e.target.value)}
                    />
                    <span className="text-danger">{errors.documentDate}</span>
                  </div>
                </li>
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary btn-lg "
                onClick={() => handleSubmit()}
              >
                <i className="icon-check-circle me-1" />
                ບັນທຶກ
              </button>
              <button
                type="button"
                className="btn btn-light btn-lg "
                onClick={() => handleClose()}
              >
                <i className="icon-x me-2" />
                ຍົກເລີກ
              </button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      )}
    </Formik>
  );
}
