import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { aws_url_emp, getStaffLogin, suserStatus } from "../../helper";
import { QUERY_USER_INFO } from "./apollo";
import male from "../../img/male.png";
import female from "../../img/female.png";


export default function ProFileInfo() {
	const [show, setShow] = useState(false);
	const userId = getStaffLogin()?.data?._id;
	const [userInfo, setUserInfo] = useState();
	const [fetchData, { data, loading }] = useLazyQuery(QUERY_USER_INFO, {
		fetchPolicy: "network-only",
	});

	useEffect(() => {
		fetchData({
			variables: {
				where: {
					_id: userId
				},
				limit: 1,
			},
		});
		if (data) {
			setUserInfo(data?.employees?.data[0]);
		}
	}, [show]);

	return (
		<>
			<button className="btn border-0 w-100 text-start" onClick={() => setShow(true)}>
				<i className="icon-user me-1 fs-3" />
				ໂປຣໄຟລ
			</button>
			<Modal size="md" show={show}>
				<Modal.Header closeButton onHide={() => setShow(false)}>
					<Modal.Title className="fs-6">
						<i className="icon-user fs-3 me-1" />
						ຂໍ້ມູນຜູ້ໃຊ້
					</Modal.Title>
				</Modal.Header>
				<Modal.Body >
					<form style={{
						fontSize:"18px"
					}}>
						<div className="mb-2"
						>
							{userInfo?.gender === "MALE" ? (
								<img
									className="mainnav__avatar img-md rounded-circle border"
									src={
										userInfo?.profileImage
											? aws_url_emp + userInfo?.profileImage
											: male
									}
									style={{ width: "150px", height: "150px" }}
								/>
							) : (
								<img
									style={{
										width: '150px',
										height: '150px'
									}}
									src={
										userInfo?.profileImage
											? aws_url_emp + userInfo?.profileImage
											: female
									}
									className="mainnav__avatar img-md rounded-circle border"
								/>
							)}
						</div>
						<div className="mb-2">
							cvID: {userInfo?.cvID || ""} {" "}
						</div>
						<div className="mb-2">
							ຊື່ ແລະ ນາມສະກຸນ: {userInfo?.firstName || ""} {" "}
							{userInfo?.lastName || ""}
						</div>
						<div className="mb-2">
							ພະແນກ: {userInfo?.department?.title_lao || ""}
						</div>
						<div className="mb-2">
							ຕຳແໜ່ງ: {userInfo?.position?.title_lao || ""}
						</div>
						<div className="mb-2">
							ເບີໂທ: {userInfo?.phoneNumber || ""}
						</div>
						<div className="mb-2">
							ສິດການໃຊ້ງານ: {suserStatus(userInfo?.role || "")}
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
}
