// = "/company/pump/detail"
export const HOME_PAGE = "/home";
export const LOGGER_SCREEN = "/logger";
export const FOLDER_SCREEN = "/folder";
export const TYPE_DOCUMENTS_SCREEN = "/type_documents";
export const DOCUMENTS_SCREEN = "/documents";
export const PRIVATE_DOCUMENTS_SCREEN = "/private_documents";
export const DOCUMENTS_GROUP_SCREEN = "/document_group";
export const DOCUMENTS_GROUP_DETAIL_SCREEN = "/document_group/detail";
export const ALL_FILE_SCREEN = "/all_file";
export const INBOX_SCREEN = "/inbox";
export const SEND_SCREEN = "/send";
export const REPORT_SCREEN = "/reports";
export const DETAIL_FOLDER = "/logger/detail";
export const DETAIL_DOCS = "/folder/detail";
export const INBOX_PRIVATE_LIST = "/inbox_isPrivate"
export const ADD_NEW_DOCUMENTS = "/add_new_documents";
export const QUERYLICENT = "/query_licent";
