import { gql } from "apollo-boost";

export const QUERY_GROUPS = gql`
query DocumentGroups($where: DocumentGroupWhereInput, $orderBy: OrderByInput, $skip: Int, $limit: Int) {
  documentGroups(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
    total
    data {
      _id
      title
      coverImage
      profileImage
      createdAt
      createdBy {
        _id
        firstName
        lastName
        profileImage
      }
    }
  }
}
`;
export const CREATE_GROUP = gql`
  mutation CreateDocumentGroup($data: DocumentGroupInput!) {
    createDocumentGroup(data: $data) {
      _id
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateDocumentGroup($data: DocumentGroupInput!, $where: DocumentGroupWhereInputOne!) {
    updateDocumentGroup(data: $data, where: $where) {
      _id
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation DeleteDocumentGroup($where: DocumentGroupWhereInputOne!) {
    deleteDocumentGroup(where: $where) {
      _id
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation CreateDocuments($data: DocumentsInput!) {
    createDocuments(data: $data) {
      _id
    }
  }
`;

export const QUERY_GROUP_INVITE = gql`
  query DocumentGroupInvites($where: DocumentGroupInviteWhereInput, $orderBy: OrderByInput, $skip: Int, $limit: Int) {
    documentGroupInvites(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
      total
      data {
        _id
        userID {
          _id
          profileImage
          firstName
          lastName
        }
        groupID {
          _id
          title
          coverImage
          profileImage
        }
        isAdmin
        createdAt
        createdBy {
          _id
          profileImage
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          _id
          cvID
          profileImage
          firstName
          lastName
        }
      }
    }
  }
`;

export const CREATE_GROUP_INVITE = gql`
  mutation CreateDocumentGroupInvite($data: DocumentGroupInviteInput!) {
    createDocumentGroupInvite(data: $data) {
      _id
    }
  }
`;
export const DELETE_GROUP_INVITE = gql`
mutation DeleteDocumentGroupInvite($where: DocumentGroupInviteWhereInputOne!, $data: DeleteInput) {
  deleteDocumentGroupInvite(where: $where, data: $data) {
    _id
  }
}
`;
export const UPDATE_GROUP_INVITE = gql`
  mutation UpdateDocumentGroupInvite($data: DocumentGroupInviteInput!, $where: DocumentGroupInviteWhereInputOne!) {
    updateDocumentGroupInvite(data: $data, where: $where) {
      _id
    }
  }
`;

export const QUERY_EMPLOYEE = gql`
 query Employees(
  $where: EmployeeWhereInput
  $orderBy: OrderByInput
  $skip: Int
  $limit: Int
) {
  employees(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
    total
    data {
      _id
      cvID
      profileImage
      firstName
      lastName
      phoneNumber
      department {
        _id
        title_lao
      }
      position {
        _id
        title_lao
      }
      team {
        _id
        title_lao
      }
      status
      role
    }
  }
}
`;
