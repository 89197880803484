import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from "react-router-dom";
import { USER_KEY } from "../helper";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../Screens/login/components/Login";
import Navbar from "../Layout/Navbar";
import CustomSideNav from "../Layout/SideNav";
import * as ROUTES from "./app";
import HomePage from "../Screens/home";
import bg from "../img/top-header3.png";
import LoggerList from "../Screens/logger";
import FolderList from "../Screens/folder";
import AllFilesList from "../Screens/allFiles";
import DocumentsList from "../Screens/documents";
import PrivateDocumentsList from "../Screens/privateDocuments";
import DocumentsGroup from "../Screens/groups";
import InboxDocumentsList from "../Screens/inbox";
import SendDocumentsList from "../Screens/send";
import TypeDocumentList from "../Screens/typeDocuments";
import ReportList from "../Screens/reports";
import DocumentsGroupDetail from "../Screens/groups/groupDetail";
import DetailFolders from "../Screens/logger/DetailFolder";
import DetailDocs from "../Screens/folder/DetailDocs";
import InboxPrivateList from "../Screens/inbox/inboxPrivate";
import NewDocumentsList from "../Screens/addNewdocuments";
import QueryLicent from "../Screens/addNewdocuments/selectLicent";
import User_Manual from "../Screens/userManual";

function Routes() {

  const _local = localStorage.getItem(USER_KEY);
  return (
    <Router>
      <Switch>
        {!_local ? (
          <React.Fragment>
            <Route path="/">
              <Redirect to="/login" />
            </Route>
            <PublicRoute exact path="/login" component={Login} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Route
              render={({ location, history }) => (
                <React.Fragment>
                  <Route exact path="/login">
                    <Redirect to="/home" />
                  </Route>
                  <Route exact path="/">
                    <Redirect to="/home" />
                  </Route>
                  <CustomSideNav location={location} history={history} />
                  <main className="main-content pinned pt-5">
                    <div className="position-relative iq-banner mt-5">
                      <Navbar />
                      {location.pathname === "/home" && (
                        <div
                          className="iq-navbar-header"
                          style={{
                            height: 215,
                            // background: "hsl(0, 65%, 62%)"
                            backgroundImage: `url(${bg})`,
                          }}
                        >
                          <div className="container-fluid iq-container">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="flex-wrap d-flex justify-content-between align-items-center">
                                  <div>
                                    <h1 className="text-white">
                                      ຍິນດີຕ້ອນຮັບເຂົ້າສູ່ລະບົບຈັດການເອກະສານພາຍໃນອົງກອນ
                                    </h1>
                                    <p>
                                      ເພື່ອຈັດການເອກະສານຂອງພາຍໃນອົງກອນ
                                      ໃຫ້ມີຄວາມເປັນສູນກາງ, ເປັນລະບຽບ ແລະ
                                      ປອດໄພຂື້ນກວ່າເກົ່າ
                                    </p>
                                  </div>
                                  <User_Manual />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <PrivateRoute
                      path={ROUTES.HOME_PAGE}
                      exact
                      component={(props) => <HomePage />}
                    />
                    <PrivateRoute
                      path={ROUTES.LOGGER_SCREEN}
                      exact
                      component={(props) => <LoggerList />}
                    />
                    <PrivateRoute
                      path={ROUTES.FOLDER_SCREEN}
                      exact
                      component={(props) => <FolderList />}
                    />

                    <PrivateRoute
                      path={ROUTES.DETAIL_FOLDER + "/:id"}
                      exact
                      component={(props) => <DetailFolders history={history} />}
                    />

                    <PrivateRoute
                      path={ROUTES.DETAIL_DOCS + "/:id"}
                      exact
                      component={(props) => <DetailDocs history={history} />}
                    />

                    <PrivateRoute
                      path={ROUTES.TYPE_DOCUMENTS_SCREEN}
                      exact
                      component={(props) => <TypeDocumentList />}
                    />
                    <PrivateRoute
                      path={ROUTES.DOCUMENTS_SCREEN}
                      exact
                      component={(props) => <DocumentsList />}
                    />
                    <PrivateRoute
                      path={ROUTES.INBOX_PRIVATE_LIST}
                      exact
                      component={(props) => <InboxPrivateList />}
                    />

                    <PrivateRoute
                      path={ROUTES.PRIVATE_DOCUMENTS_SCREEN}
                      exact
                      component={(props) => <PrivateDocumentsList />}
                    />
                    <PrivateRoute
                      path={ROUTES.DOCUMENTS_GROUP_SCREEN}
                      exact
                      component={(props) => <DocumentsGroup />}
                    />
                    <PrivateRoute
                      path={ROUTES.DOCUMENTS_GROUP_DETAIL_SCREEN + "/:id"}
                      exact
                      component={(props) => (
                        <DocumentsGroupDetail history={history} />
                      )}
                    />
                    <PrivateRoute
                      path={ROUTES.ALL_FILE_SCREEN}
                      exact
                      component={(props) => <AllFilesList />}
                    />
                    <PrivateRoute
                      path={ROUTES.INBOX_SCREEN}
                      exact
                      component={(props) => <InboxDocumentsList />}
                    />
                    <PrivateRoute
                      path={ROUTES.SEND_SCREEN}
                      exact
                      component={(props) => <SendDocumentsList />}
                    />
                    <PrivateRoute
                      path={ROUTES.REPORT_SCREEN}
                      exact
                      component={(props) => <ReportList />}
                    />
                    <PrivateRoute
                      path={ROUTES.ADD_NEW_DOCUMENTS}
                      exact
                      component={(props) => <NewDocumentsList />}
                    />
                    <PrivateRoute
                      path={ROUTES.QUERYLICENT}
                      exact
                      component={(props) => <QueryLicent />}
                    />

                  </main>
                </React.Fragment>
              )}
            />
          </React.Fragment>
        )}
      </Switch>
    </Router>
  );
}

export default Routes;
