// import { useLazyQuery } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import { v4 as uuidv4 } from "uuid";
import {
  disPlayFiles,
  gcpUpload,
  getStaffLogin,
  messageError,
  messageSuccess,
  toDayDash,
  valiDate,
} from "../../helper";
import { QUERY_DOCUMENT_FOLDERS, QUERY_TYPE_DOCUMENT } from "../typeDocuments/apollo";
import { CREATE_DOCUMENT } from "../documents/apollo";

export default function AddDocPrivate({ newLoad, setNewLoad }) {
  const _user = getStaffLogin().data;
  const [show, setShow] = useState(false);
  const [createDocument] = useMutation(CREATE_DOCUMENT);
  const handleClose = () => setShow(false);
  const [fileNames, setFileNames] = useState("");
  const [file, setFile] = useState(null);
  const [listTypeDocument, setListTypeDocument] = useState([]);
  const [listDocumentFolder, setListDocumentFolder] = useState([]);
  const toDayDate = toDayDash();
  const [doCumentDate, setDocumentDate] = useState(toDayDate);

  const [queryTypeDocument, { data: resultData }] = useLazyQuery(
    QUERY_TYPE_DOCUMENT,
    { fetchPolicy: "cache-and-network" }
  );
  const [queryDocumentFolder, { data: documentFolder }] = useLazyQuery(
    QUERY_DOCUMENT_FOLDERS,
    { fetchPolicy: "cache-and-network" }
  );


  useEffect(() => {
    queryTypeDocument({
      variables: {
        where: {
          departmentID: parseInt(_user.department._id),
        },
        limit: 1000,
        orderBy: "createdAt_DESC",
      },
    });
    queryDocumentFolder({
      variables: {
        where: {
          departmentID: parseInt(_user.department._id),
          _id: undefined,
        },
        limit: 1000,
        orderBy: "createdAt_DESC",
      }
    })
  }, []);

  useEffect(() => {
    if (resultData) {
      setListTypeDocument(resultData.documentTypes?.data);
    }
  }, [resultData]);
  useEffect(() => {
    if (documentFolder) {
      setListDocumentFolder(documentFolder.documentFolders?.data);
    }
  }, [documentFolder]);

  const handleUpload = async (event) => {
    try {
      const _fileName =
        uuidv4() + "." + event.target.files[0].type.split("/")[1];
      const _file = event.target.files[0];
      setFile(_file);
      const res = await gcpUpload({
        file: _file,
        bucketName: "ansx",
        pathname: "docs",
        filename: _fileName,
      });
      if (res?.publicUrl) {
        setFile(_file);
        setFileNames(_fileName);
        messageSuccess("ການດຳເນິນງານສຳເລັດແລ້ວ");
      } else {
        messageError("ການດຳເນິນງານບໍ່ສຳເລັດ");
        setFile(null);
        setFileNames("");
      }
    } catch (error) {
      console.log(error);
      messageError("ການດຳເນິນງານບໍ່ສຳເລັດ");
    }
  };

  return (
    <Formik
      initialValues={{
        documentID: "",
        title: "",
        typeDocument: "",
        folderID: "",
        description: "",
      }}
      enableReinitialize={false}
      validate={(values) => {
        const errors = {};

        if (!values.documentID) {
          errors.documentID = "ກະລຸນາປ້ອນເລກທີເອກະສານກ່ອນ!";
        }
        if (!values.typeDocument) {
          errors.typeDocument = "ກະລຸນາເລືອກປະເພດເອກະສານກ່ອນ!";
        }
        if (!values.title) {
          errors.title = "ກະລຸນາປ້ອນຫົວຂໍ້ເອກະສານກ່ອນ!";
        }

        if (!doCumentDate) {
          errors.documentDate = "ກະລຸນາເລືອກວັນທີເອກະສານກ່ອນ!";
        }
        if (!fileNames) {
          errors.files = "ກະລຸນາອັບໂຫຼດເອກະສານເພື່ອຢັ້ງຢືນ!";
        }
        if (!values.folderID) {
          errors.folderID = "ກະລຸນາເລືອກແຟ້ມເອກະສານກ່ອນ!";
        }      
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {

        try {
          let __createDocument = await createDocument({
            variables: {
              data: {
                documentID: String(values.documentID),
                typeDocument: String(values.typeDocument),
                title: String(values?.title),
                description: String(values?.description),
                documentDate: String(doCumentDate),
                folderID: String(values.folderID),
                files: String(fileNames),
                isPrivate: 1,
              },
            },
          });

          if (__createDocument) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            resetForm();
            setNewLoad(!newLoad);
            setDocumentDate(toDayDate);
            setFileNames("");
            setShow(false);
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການເຂົ້າລະບົບມີຄວາມຂັດຂ້ອງ");
        }
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <React.Fragment>
           <button
            className="btn btn-primary hvr-shutter-out-horizontal"
            data-bs-toggle="modal"
            type="submit"
            onClick={() => setShow(true)}
          >
            <i className="fas fa-plus me-1" />ອັບໂຫຼດໄຟລ໌ເອກະສານສ່ວນຕົວ
          </button>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            animation={false}
            centered
            backdrop="static"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title className="fs-5">
                <svg
                  className="icon-22"
                  width={22}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                    fill="currentColor"
                  />
                </svg>
                ເພີ່ມເອກະສານສ່ວນຕົວ
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ul className="row flex-wrap align-items-center list-inline m-0 p-0">
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ເລກທີເອກະສານ{valiDate()}
                    </label>
                    <input
                      type="text"
                      className={
                        errors.documentID
                          ? "form-control is-invalid"
                          : "form-control  invalid"
                      }
                      placeholder="ເລກທີເອກະສານ"
                      name="documentID"
                      value={values.documentID}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{errors.documentID}</span>
                  </div>
                </li>
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ປະເພດເອກະສານ{valiDate()}
                    </label>
                    <select
                      className={
                        errors.typeDocument ? "form-control is-invalid shadow-none p-3 W-100" : "form-control invalid shadow-none p-3 W-100"
                      }
                      id="exampleFormControlSelect1"
                      name="typeDocument"
                      onChange={handleChange}
                    >
                      <option>ເລືອກປະເພດເອກະສານ</option>
                      {listTypeDocument.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                    <span className="text-danger">{errors.typeDocument}</span>
                  </div>
                </li>
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ຫົວຂໍ້ເອກະສານ{valiDate()}
                    </label>
                    <input
                      type="text"
                      className={
                        errors.title
                          ? "form-control is-invalid"
                          : "form-control  invalid"
                      }
                      placeholder="ຫົວຂໍ້ເອກະສານ"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{errors.title}</span>
                  </div>
                </li>
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ເນື້ອໃນເອກະສານ
                    </label>
                    <textarea
                      className="form-control shadow-none p-3 W-100"
                      rows="4"
                      placeholder="ເນື້ອໃນເອກະສານ"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </li>
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ວັນທີເອກະສານ{valiDate()}
                    </label>
                    <input
                      type="date"
                      className={
                        errors.documentDate
                          ? "form-control is-invalid"
                          : "form-control  invalid"
                      }
                      placeholder="ວັນທີເອກະສານ"
                      name="documentDate"
                      value={doCumentDate}
                      onChange={(e) => setDocumentDate(e.target.value)}
                    />
                    <span className="text-danger">{errors.documentDate}</span>
                  </div>
                </li>
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ເລືອກແຟ້ມເອກະສານ{valiDate()}
                    </label>
                    <select
                      className={
                        errors.folderID ? "form-control is-invalid shadow-none p-3 W-100" : "form-control invalid shadow-none p-3 W-100"
                      }
                      id="exampleFormControlSelect1"
                      name="folderID"
                      onChange={handleChange}
                    >
                      <option>ເລືອກແຟ້ມເອກະສານ</option>
                      {listDocumentFolder.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                    <span className="text-danger">{errors.folderID}</span>
                  </div>
                </li>            

                <li className="col-md-12 mb-3">
                  <div style={{ textAlign: "center" }}>
                    <input
                      type="file"
                      id="file-upload"
                      onChange={handleUpload}
                      accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf, image/*"
                      hidden
                    />
                    <label for="file-upload">
                      <div>
                        {file ? (
                          <div className="card bg-transparent shadow-none">
                            <div className="iq-dashed-border align-items-center justify-content-center">
                              {disPlayFiles(fileNames, "", 150, 150)}
                            </div>
                          </div>
                        ) : (
                          <div className="card bg-transparent shadow-none">
                            <div className="iq-dashed-border">
                              <div className="align-items-center justify-content-center p-5">
                                <svg
                                  className="icon-42 text-primary"
                                  width="42"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    opacity="0.4"
                                    d="M2 7.916V16.084C2 19.623 4.276 22 7.665 22H16.335C19.724 22 22 19.623 22 16.084V7.916C22 4.378 19.723 2 16.334 2H7.665C4.276 2 2 4.378 2 7.916Z"
                                    fill="currentColor"
                                  ></path>
                                  <path
                                    d="M7.72033 12.8555L11.4683 16.6205C11.7503 16.9035 12.2493 16.9035 12.5323 16.6205L16.2803 12.8555C16.5723 12.5615 16.5713 12.0865 16.2773 11.7945C15.9833 11.5025 15.5093 11.5025 15.2163 11.7965L12.7493 14.2735V7.91846C12.7493 7.50346 12.4133 7.16846 11.9993 7.16846C11.5853 7.16846 11.2493 7.50346 11.2493 7.91846V14.2735L8.78333 11.7965C8.63633 11.6495 8.44433 11.5765 8.25133 11.5765C8.06033 11.5765 7.86833 11.6495 7.72233 11.7945C7.42933 12.0865 7.42833 12.5615 7.72033 12.8555Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                                <h4 className="text-body mb-0">ອັບໂຫຼດໄຟລ໌</h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <span className="text-danger">{errors.files}</span>
                    </label>
                  </div>
                </li>
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary btn-lg "
                onClick={() => handleSubmit()}
              >
                <i className="icon-check-circle me-1" />
                ບັນທຶກ
              </button>
              <button
                type="button"
                className="btn btn-light btn-lg "
                onClick={() => handleClose()}
              >
                <i className="icon-x me-2" />
                ຍົກເລີກ
              </button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      )}
    </Formik>
  );
}
