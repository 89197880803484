// import { useLazyQuery } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import { getStaffLogin, messageError, messageSuccess, valiDate } from "../../helper";
import { CREATE_FOLDER } from "./apollo";
import { QUERY_LOGGERS } from "../logger/apollo";

export default function AddFolder({newLoad, setNewLoad }) {
  const _user = getStaffLogin().data;
  const [show, setShow] = useState(false);
  const [listLogger, setListLogger] = useState([]);
  const [createFolder] = useMutation(CREATE_FOLDER);
  const handleClose = () => setShow(false);
  const [queryLogger, { data: resultData, loading }] = useLazyQuery(
    QUERY_LOGGERS,
    { fetchPolicy: "cache-and-network" }
  );
  useEffect(() => {
    queryLogger({
      variables: {
        where:{
          departmentID: parseInt(_user?.department?._id),
        },
        limit: parseInt(1000),
        orderBy: "createdAt_DESC",
      },
    });
  }, []);


  useEffect(() => {
    if (resultData) {
      setListLogger(resultData?.documentLoggers?.data);
    }
  }, [resultData]);
  return (
    <Formik
      initialValues={{
        loggerID: 0,
        title: "",
      }}
      enableReinitialize={true}
      validate={(values) => {
        const errors = {};
        if (!values.loggerID) {
          errors.loggerID = "error";
        }
        if (!values.title) {
          errors.title = "error";
        }
        return errors;
      }}
      onSubmit={async (values) => {
        try {
          let __createFolder = await createFolder({
            variables: {
              data: {
                loggerID: parseInt(values?.loggerID),
                title: String(values?.title),
              },
            },
          });

          if (__createFolder) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setShow(false);
            setNewLoad(!newLoad);
            values.title = "";
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການເຂົ້າລະບົບມີຄວາມຂັດຂ້ອງ");
        }
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <React.Fragment>
          <button
            className="btn btn-primary me-2 "
            onClick={() => setShow(true)}
          >
            <i className="icon-plus-circle" /> ເພີ່ມແຟ້ມເອກະສານ
          </button>
          <Modal
            centered
            show={show}
            onHide={() => setShow(false)}
            animation={false}
            backdrop="static"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title className="fs-5">
                <i className="icon-plus" /> ເພີ່ມແຟ້ມເອກະສານ
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>ຕູ້ເອກະສານ {valiDate()}</label>
               <select className={
                    errors.loggerID
                      ? "form-control form-control-lg mb-3 mt-2 is-invalid"
                      : "form-control form-control-lg mb-3 mt-2 invalid"
                  } name="loggerID" onChange={handleChange} value={values?.loggerID}>
                    <option value="">ເລືອກຕູ້ເອກະສານ</option>
                    {listLogger?.map((item, index) => (
                  <option value={item?._id}>{item?.title}</option>
                ))}
                  </select>
              </div>
              <div className="form-group">
                <label>ຊື່ແຟ້ມ ຫຼື ເບີແຟ້ມ {valiDate()}</label>
                <input
                  type="text"
                  className={
                    errors.title
                      ? "form-control form-control-lg mb-3 mt-2 is-invalid"
                      : "form-control form-control-lg mb-3 mt-2 invalid"
                  }
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  placeholder="ປ້ອນຂໍ້ມູນ..."
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary btn-lg "
                onClick={() => handleSubmit()}
              >
                <i className="icon-check-circle me-1" />
                ບັນທຶກ
              </button>
              <button
                type="button"
                className="btn btn-light btn-lg "
                onClick={() => handleClose()}
              >
                <i className="icon-x me-2" />
                ຍົກເລີກ
              </button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      )}
    </Formik>
  );
}
