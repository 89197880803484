// import { useLazyQuery } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import {
  getStaffLogin,
  messageError,
  messageSuccess,
  messageWarning,
  toDayDash,
  valiDate,
} from "../../helper";

import {
  CREATE_SIGNATURE_PROVIDER,
  QUERY_SIGNATURE_PROVIDERS,
} from "./apollo";
import SelectDepartment from "../../helper/componentsList/SelectDepartment";

export default function QueryDepartment({ signID }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [getData, setGetDepartment] = useState();
  const [createSignatureProvider] = useMutation(CREATE_SIGNATURE_PROVIDER);

  const [querySignatureProvider, { data: resultAll }] = useLazyQuery(
    QUERY_SIGNATURE_PROVIDERS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    querySignatureProvider({
      variables: {
        where: {
          _id: signID ? parseInt(signID) : null,
        },
        orderBy: "createdAt_DESC",
      },
    });
  }, [signID]);

  useEffect(() => {
    if (resultAll) {
      setGetDepartment(resultAll.SignatureProviders.data);
    }
  }, [resultAll]);

  return (
    <>
      {getData?.map((item) => (
        <div className="row">
          {item?.department?.title_lao}
        </div>
      ))}
    </>
  );
}
