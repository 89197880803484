import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ITEM_PER_PAGE, setParams, useSearchParams } from "../helper/index";

export default function LimitData({ className, onChange }) {
  const history = useHistory();
  const params = useSearchParams();
  const [rows, setRows] = useState();
  useEffect(() => {
    const row = params.get("rows");
    setRows(row ? row : String(ITEM_PER_PAGE));
    onChange(row ? parseInt(row) : ITEM_PER_PAGE);
  }, [params]);
  return (
    <div className={className}>
      <div className="flex">
        <div className="mb-2 form-inline justify-content-end">
          <div style={{ padding: "4px" }} />
          <select
            id="sel-rows"
            className="form-select ml-2"
            onChange={(e) => {
              const value = e.target.value;
              history.push({
                search: setParams("rows", value),
              });
            }}
            value={rows}
          >
            <option value="10">10 ລາຍການ</option>
            <option value="50">50 ລາຍການ</option>
            <option value="100">100 ລາຍການ</option>
            <option value="500">500 ລາຍການ</option>
            <option value="1000">1,000 ລາຍການ</option>
          </select>
        </div>
      </div>
    </div>
  );
}
