/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useLazyQuery } from "@apollo/client";
import { QUERY_DOCUMENTS } from "../documents/apollo";
import {
  gcp_docs,
  endOfMonth,
  formatDateDash,
  formatDateTime,
  getStaffLogin,
  placeholderImage,
  startOfMonth,
} from "../../helper";
import { QUERY_TYPE_DOCUMENT } from "../typeDocuments/apollo";
import { QUERY_LOGGERS } from "../logger/apollo";
import { QUERY_GROUPS } from "../groups/apollo";
import { QUERY_FOR_WAR_DOCUMENTS } from "../send/apollo";

export default function HomePage() {
  const _user = getStaffLogin().data;
  const [startDate, setStartDate] = useState(startOfMonth());
  const [endDate, setEndDate] = useState(endOfMonth());
  const [numberRow, setNumberRow] = useState(100);
  const today = formatDateTime();

  const [queryGroup, { data: resultGroup }] = useLazyQuery(QUERY_GROUPS, {
    fetchPolicy: "cache-and-network",
  });

  const [querySend, { data: resultSend }] = useLazyQuery(
    QUERY_FOR_WAR_DOCUMENTS,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [querySendPrivate, { data: resultSendPrivate }] = useLazyQuery(
    QUERY_FOR_WAR_DOCUMENTS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [queryInbox, { data: resultInbox }] = useLazyQuery(
    QUERY_FOR_WAR_DOCUMENTS,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [queryInboxPrivate, { data: resultInboxPrivate }] = useLazyQuery(
    QUERY_FOR_WAR_DOCUMENTS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [queryLogger, { data: resultLogger }] = useLazyQuery(QUERY_LOGGERS, {
    fetchPolicy: "cache-and-network",
  });

  const [queryLoggerDepart, { data: resultLoggerDepart }] = useLazyQuery(
    QUERY_LOGGERS,
    { fetchPolicy: "cache-and-network" }
  );

  const [queryTypeDocument, { data: resultDocumentType }] = useLazyQuery(
    QUERY_TYPE_DOCUMENT,
    { fetchPolicy: "cache-and-network" }
  );

  const [queryTypeDepartment, { data: resultDepartment }] = useLazyQuery(
    QUERY_TYPE_DOCUMENT,
    { fetchPolicy: "cache-and-network" }
  );

  const [factAll, { data: resultDocument }] = useLazyQuery(QUERY_DOCUMENTS, {
    fetchPolicy: "cache-and-network",
  });

  const [factDocument, { data: document }] = useLazyQuery(QUERY_DOCUMENTS, {
    fetchPolicy: "cache-and-network",
  });

  const [factDocDepartment, { data: resultDocsDepartment }] = useLazyQuery(
    QUERY_DOCUMENTS,
    { fetchPolicy: "cache-and-network" }
  );
  const [factDocPrivate, { data: resultDocsPrivate }] = useLazyQuery(
    QUERY_DOCUMENTS,
    { fetchPolicy: "cache-and-network" }
  );

  const [factDocPrivateTotal, { data: resultDocsPrivateTotal }] = useLazyQuery(
    QUERY_DOCUMENTS,
    { fetchPolicy: "cache-and-network" }
  );

  useEffect(() => {
    queryInbox({
      variables: {
        where: {
          department: parseInt(_user?.department?._id),
          status: 1,
          isPrivate: 0,
          receiptDateBetween: [startDate, endDate],
        },
        limit: 100,
        orderBy: "createdAt_DESC",
      },
    });
    queryInboxPrivate({
      variables: {
        where: {
          receiptBy: parseInt(_user?._id),
          status: 1,
          isPrivate: 1,
          receiptDateBetween: [startDate, endDate],
        },
        limit: 100,
        orderBy: "createdAt_DESC",
      },
    });

    querySend({
      variables: {
        where: {
          original_department: parseInt(_user?.department?._id),
          sendDateBetween: [startDate, endDate],
          isPrivate: 0,
        },
        limit: numberRow,
        orderBy: "createdAt_DESC",
      },
    });
    querySendPrivate({
      variables: {
        where: {
          sendBy: parseInt(_user?._id),
          isPrivate: 1,
          sendDateBetween: [startDate, endDate],
        },
        limit: numberRow,
        orderBy: "createdAt_DESC",
      },
    });

    factDocument({
      variables: {
        where: {
          departmentID: parseInt(_user?.department?._id),
          isPrivate: 0,
          createdAt_gte: startDate,
          createdAt_lte: endDate,
          isDeleted: 0,
          isPublic: 1,
        },
        limit: numberRow,
        orderBy: "createdAt_DESC",
      },
    });

    factAll({
      variables: {
        where: {
          // createdAt_gte: startDate,
          // createdAt_lte: endDate,
          isDeleted: 0,
          isPublic: 1,
        },
      },
    });

    factDocDepartment({
      variables: {
        where: {
          departmentID: parseInt(_user?.department?._id),
          isPrivate: 0,
          // createdAt_gte: startDate,
          // createdAt_lte: endDate,
          isDeleted: 0,
          isPublic: 1,
        },
      },
    });

    factDocPrivate({
      variables: {
        where: {
          createdBy: parseInt(_user?._id),
          isPrivate: 1,
          createdAt_gte: startDate,
          createdAt_lte: endDate,
          isDeleted: 0,
          isPublic: 1,
        },
      },
    });

    factDocPrivateTotal({
      variables: {
        where: {
          createdBy: parseInt(_user?._id),
          isPrivate: 1,
          // createdAt_gte: startDate,
          // createdAt_lte: endDate,
          isDeleted: 0,
          isPublic: 1,
        },
      },
    });

    queryTypeDocument({
      variables: {
        where: {},
      },
    });

    queryTypeDepartment({
      variables: {
        where: {
          departmentID: parseInt(_user?.department?._id),
        },
      },
    });

    queryLogger({
      variables: {
        where: {},
      },
    });

    queryLoggerDepart({
      variables: {
        where: {
          departmentID: parseInt(_user?.department?._id),
        },
      },
    });

    queryGroup({
      variables: {
        where: {},
      },
    });
  }, [
    numberRow,
    startDate,
    endDate,
    factAll,
    queryTypeDocument,
    queryLogger,
    queryGroup,
    querySend,
    queryInbox,
    factDocDepartment,
    factDocPrivate,
    queryTypeDepartment,
    queryLoggerDepart,
    factDocument,
    factDocPrivateTotal,
  ]);

  const totalDocument = resultDocument?.documents?.total;
  const totalTypeDocument = resultDocumentType?.documentTypes?.total;
  const TotalTypeDepartment = resultDepartment?.documentTypes?.total;

  const totalLogger = resultLogger?.documentLoggers?.total;
  const totalLoggerDepart = resultLoggerDepart?.documentLoggers?.total;
  const totalGroup = resultGroup?.documentGroups?.total;

  const totalSend = resultSend?.forwardDocuments?.total;
  const totalSendPrivate = resultSendPrivate?.forwardDocuments?.total;

  const totalInbox = resultInbox?.forwardDocuments?.total;
  const totalInboxPrivate = resultInboxPrivate?.forwardDocuments?.total;

  const TotalDocsD = resultDocsDepartment?.documents?.total;

  const TotalPrivate = resultDocsPrivateTotal?.documents?.total;
  const TotalPrivateD = resultDocsPrivate?.documents?.total;
  const totalDocs = document?.documents?.total;

  return (
    <div className="content-inner container-fluid pb-0" id="page_layout">
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="card" style={{ marginTop: -100, background: "#eee" }}>
          <div className="card-body">
            <div className="d-flex flex-column">
              <h3>ເວລາປັດຈຸບັນ: {today}</h3>
              <p className="text-primary mb-0">Financial Dashboard</p>
            </div>
            <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
              <div className="form-check form-switch mb-0 iq-status-switch">
                <input
                  className="form-check-input iq-status"
                  type="checkbox"
                  id="iq-switch"
                  defaultChecked=""
                />
                <label
                  className="form-check-label iq-reset-status"
                  htmlFor="iq-switch"
                >
                  Online
                </label>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-3 col-md-6">
              <div className="row">
                <div className="col-12">
                  <div className="card card-block card-stretch card-height">
                    <div className="card-body" style={{ height: "150px" }}>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <h5 className="mb-0 text-dark">ຕູ້ເອກະສານທັງໝົດ</h5>
                        <a
                          className="badge rounded-pill bg-soft-primary"
                          href="javascript:void(0);"
                        >
                          {totalLogger || 0}
                        </a>
                      </div>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <small className="mb-0 text-dark">
                          ຕູ້ເອກະສານພາຍໃນພະແນກ
                        </small>
                        <a
                          className="badge rounded-pill bg-soft-primary"
                          href="javascript:void(0);"
                        >
                          {totalLoggerDepart || 0}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="row">
                <div className="col-12">
                  <div className="card card-block card-stretch card-height">
                    <div className="card-body" style={{ height: "150px" }}>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <h5 className="text-dark">ໝວດເອກະສານທັງໝົດ</h5>
                        <a
                          className="badge rounded-pill bg-soft-primary"
                          href="javascript:void(0);"
                        >
                          {totalTypeDocument || 0}
                        </a>
                      </div>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <small className="text-dark">
                          ໝວດເອກະສານພາຍໃນພະແນກ
                        </small>
                        <a
                          className="badge rounded-pill bg-soft-primary"
                          href="javascript:void(0);"
                        >
                          {TotalTypeDepartment || 0}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="row">
                <div className="col-12">
                  <div className="card card-block card-stretch card-height">
                    <div className="card-body" style={{ height: "150px" }}>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <h5 className="text-dark">ເອກະສານທັງໝົດ</h5>
                        <a
                          className="badge rounded-pill bg-soft-primary"
                          href="javascript:void(0);"
                        >
                          {totalDocument || 0}
                        </a>
                      </div>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <small>ເອກະສານພາຍໃນພະແນກ: </small>
                        <a
                          className="badge rounded-pill bg-soft-primary"
                          href="javascript:void(0);"
                        >
                          {TotalDocsD || 0}
                        </a>
                      </div>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <small>ເອກະສານສ່ວນຕົວ: </small>
                        <a
                          className="badge rounded-pill bg-soft-primary"
                          href="javascript:void(0);"
                        >
                          {TotalPrivate || 0}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="card">
                <div className="card-body" style={{ height: "150px" }}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="iq-scroller-effect">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h5 className="text-dark">ຈັດກຸ່ມເອກະສານທັງໝົດ</h5>
                          <a
                            href="table/border-table.html"
                            className="badge rounded-pill bg-soft-primary"
                          >
                            {totalGroup || 0}
                          </a>
                        </div>
                        <div className="d-flex align-items-center iq-slider mb-4 gap-2">
                          {resultGroup?.documentGroups?.data?.map((item) => (
                            <div>
                              <img
                                className="img-fluid avatar-40 rounded-circle mb-4"
                                src={
                                  item?.profileImage
                                    ? gcp_docs + item?.profileImage
                                    : placeholderImage(100)
                                }
                                alt=""
                                loading="lazy"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="card card-block card-stretch card-height">
                <nav className="tab-bottom-bordered">
                  <div
                    className="mb-0 nav nav-tabs justify-content-around"
                    id="nav-tab1"
                    role="tablist"
                  >
                    <button
                      className="nav-link py-3 active"
                      id="nav-home-11-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home-11"
                      type="button"
                      role="tab"
                      aria-controls="nav-home-11"
                      aria-selected="true"
                    >
                      ເອກະສານທັງໝົດ {totalDocs + TotalPrivateD || 0}
                    </button>
                    <button
                      className="nav-link py-3"
                      id="nav-profile-11-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile-11"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile-11"
                      aria-selected="false"
                    >
                      ເອກະສານເຂົ້າທັງໝົດ {totalInbox + totalInboxPrivate || 0}
                    </button>
                    <button
                      className="nav-link py-3"
                      id="nav-contact-11-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-contact-11"
                      type="button"
                      role="tab"
                      aria-controls="nav-contact-11"
                      aria-selected="false"
                    >
                      ເອກະສານອອກທັງໝົດ {totalSend + totalSendPrivate || 0}
                    </button>
                  </div>
                </nav>
                <div className="tab-content iq-tab-fade-up" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-home-11"
                    role="tabpanel"
                    aria-labelledby="nav-home-11-tab"
                  >
                    <div className="table-responsive">
                      <table
                        id="transaction-table"
                        className="table mb-0 table-striped"
                        role="grid"
                      >
                        <tbody>
                          {document?.documents?.data?.map((item, index) => (
                            <tr key={index}>
                              <td className="text-primary">
                                {item?.title || ""}
                              </td>
                              <td className="text-dark">
                                {item?.typeDocument?.title || ""}
                              </td>
                              <td className="text-end">
                                {item?.documentDate
                                  ? formatDateDash(item?.documentDate)
                                  : "--"}
                              </td>
                            </tr>
                          ))}

                          {resultDocsPrivate?.documents?.data?.map(
                            (item, index) => (
                              <tr key={index}>
                                <td className="text-primary">
                                  {item?.title || ""}
                                </td>
                                <td className="text-dark">
                                  {item?.typeDocument?.title || ""}
                                </td>
                                <td className="text-end">
                                  {item?.documentDate
                                    ? formatDateDash(item?.documentDate)
                                    : "--"}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-profile-11"
                    role="tabpanel"
                    aria-labelledby="nav-profile-11-tab"
                  >
                    <div className="table-responsive">
                      <table
                        id="transaction-table-1"
                        className="table mb-0 table-striped"
                        role="grid"
                      >
                        <tbody>
                          {resultInbox?.forwardDocuments?.data?.map(
                            (item, index) => (
                              <tr key={index}>
                                <td className="text-primary">
                                  {item?.description || ""}
                                </td>
                                <td className="text-dark">
                                  {item?.receiptDate
                                    ? formatDateDash(item?.receiptDate)
                                    : "--"}
                                </td>
                                <td className="text-end">
                                  {item?.status === 1 ? (
                                    <span className="text-success">
                                      ຮັບເອກະສານແລ້ວ
                                    </span>
                                  ) : null}
                                </td>
                              </tr>
                            )
                          )}
                          {resultInboxPrivate?.forwardDocuments?.data?.map(
                            (item, index) => (
                              <tr key={index}>
                                <td className="text-primary">
                                  {item?.description || ""}
                                </td>
                                <td className="text-dark">
                                  {item?.receiptDate
                                    ? formatDateDash(item?.receiptDate)
                                    : "--"}
                                </td>
                                <td className="text-end">
                                  {item?.status === 1 ? (
                                    <span className="text-success">
                                      ຮັບເອກະສານແລ້ວ
                                    </span>
                                  ) : null}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-contact-11"
                    role="tabpanel"
                    aria-labelledby="nav-contact-11-tab"
                  >
                    <div className="table-responsive">
                      <table
                        id="transaction-table-2"
                        className="table mb-0 table-striped"
                        role="grid"
                      >
                        <tbody>
                          {resultSend?.forwardDocuments?.data?.map(
                            (item, index) => (
                              <tr key={index}>
                                <td className="text-primary">
                                  {item?.description || ""}
                                </td>
                                <td className="text-dark">
                                  {item?.sendDate
                                    ? formatDateDash(item?.sendDate)
                                    : "--"}
                                </td>
                                <td className="text-end">
                                  {item?.status === 0 ? (
                                    <span className="text-danger">
                                      ກຳລັງສົ່ງ
                                    </span>
                                  ) : (
                                    <span className="text-success">
                                      ຮອດປາຍທາງແລ້ວ
                                    </span>
                                  )}
                                </td>
                              </tr>
                            )
                          )}

                          {resultSendPrivate?.forwardDocuments?.data?.map(
                            (item, index) => (
                              <tr key={index}>
                                <td className="text-primary">
                                  {item?.description || ""}
                                </td>
                                <td className="text-dark">
                                  {item?.sendDate
                                    ? formatDateDash(item?.sendDate)
                                    : "--"}
                                </td>
                                <td className="text-end">
                                  {item?.status === 0 ? (
                                    <span className="text-danger">
                                      ກຳລັງສົ່ງ
                                    </span>
                                  ) : (
                                    <span className="text-success">
                                      ຮອດປາຍທາງແລ້ວ
                                    </span>
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
