/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { getStaffLogin, messageError, messageSuccess } from "../../helper";
import { DELETE_TYPE_DOCUMENT, QUERY_TYPE_DOCUMENT } from "./apollo";
import Notiflix from "notiflix";
import EditType from "./editType";
import AddType from "./addType";

export default function TypeDocumentList() {
  const _user = getStaffLogin().data;
  const [searchValue, setSearchValue] = useState("");
  const [newLoadData, setNewLoadData] = useState(false);
  const [deleteFolder] = useMutation(DELETE_TYPE_DOCUMENT);
  const [listTypeDocument, setListTypeDocument] = useState([]);

  const [queryTypeDocument, { data: resultData }] = useLazyQuery(
    QUERY_TYPE_DOCUMENT,
    { fetchPolicy: "cache-and-network" }
  );
  useEffect(() => {
    queryTypeDocument({
      variables: {
        where: {
          departmentID: parseInt(_user.department._id),
          title: searchValue ? String(searchValue) : undefined,
        },
        limit: 1000,
        orderBy: "createdAt_DESC",
      },
    });
  }, [searchValue, newLoadData]);

  useEffect(() => {
    if (resultData) {
      setListTypeDocument(resultData.documentTypes?.data);
    }
  }, [resultData]);
  const total = resultData?.documentTypes?.total;

  const _handleDelete = (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການລຶບຂໍ້ມູນ ນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _deleteFolder = await deleteFolder({
            variables: {
              where: {
                _id: parseInt(id),
              },
            },
          });
          if (_deleteFolder) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            setNewLoadData(!newLoadData);
          }
        } catch (error) {
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <h3>ຈັດການຂໍ້ມູນໝວດເອກະສານ</h3>
        <hr />
        <div className="row">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="mb-0 text-primary">
              ໝວດເອກະສານທັງໝົດ {total || 0} ໝວດ
            </h4>
            <ul
              className="nav nav-pills mb-0 iq-product-filter d-flex bg-transparent align-items-center"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item dropdown d-none d-xl-block">
                <AddType
                  newLoad={newLoadData}
                  setNewLoad={(res) => setNewLoadData(res)}
                />
              </li>
              
              <li className="nav-item dropdown d-none d-xl-block">
                <div className="form-group input-group mb-0 search-input w-100">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="ຄົ້ນຫາ..."
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <span className="input-group-text">
                    <svg
                      className="icon-20"
                      width={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="11.7669"
                        cy="11.7666"
                        r="8.98856"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.0186 18.4851L21.5426 22"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive mt-0">
                  <table
                    id="basic-table"
                    className="table table-striped mb-0"
                    role="grid"
                  >
                    <thead>
                      <tr>
                        <th>ລຳດັບ</th>
                        <th>ໝວດເອກະສານ</th>
                        <th>ພະແນກ</th>
                        <th>ຈັດການ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listTypeDocument?.map((item, index) => (
                        <tr>
                          <td width={100}>{index + 1}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6>{item?.title ? item?.title : "No Title"}</h6>
                            </div>
                          </td>
                          <td>
                            <h6>
                              {item?.departmentID?.title_lao
                                ? item?.departmentID?.title_lao
                                : "No Department"}
                            </h6>
                          </td>
                          <td width={250}>
                            <div className="d-flex align-items-center">
                              <EditType
                                data={item}
                                newLoad={newLoadData}
                                setNewLoad={(res) => setNewLoadData(res)}
                              />
                              <button
                                className="btn btn-primary ms-2 "
                                onClick={() => _handleDelete(item?._id)}
                              >
                                <svg
                                  class="icon-18 me-2"
                                  width="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    opacity="0.4"
                                    d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                                    fill="currentColor"
                                  ></path>
                                  <path
                                    d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                                ລຶບ
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
