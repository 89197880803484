// import { useLazyQuery } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { formatDateTime, getStaffLogin } from "../../helper";
import { QUERY_FOLDERS } from "../folder/apollo";
import folderImage from "../../img/folder.jpeg";
import useRouter from "use-react-router";

export default function DetailFolders() {
	const { match, history } = useRouter();
	const _id = match.params.id;
	const _user = getStaffLogin().data
	const [listFolder, setListFolder] = useState([]);

	const [queryFolder, { data: resultData }] = useLazyQuery(
		QUERY_FOLDERS,
		{ fetchPolicy: "cache-and-network" }
	);
	useEffect(() => {
		queryFolder({
			variables: {
				where: {
					departmentID: parseInt(_user?.department?._id),
					loggerID: parseInt(_id),
				},
				orderBy: "createdAt_DESC",
			},
		});
	}, []);

	useEffect(() => {
		if (resultData) {
			setListFolder(resultData?.documentFolders?.data);
		}
	}, [resultData]);

	const total = resultData?.documentFolders?.total || 0;


	return (

		<React.Fragment>
			<div className="content-inner pb-0 container-fluid" id="page_layout">
				<div className="col-lg-12">
					<div className="tab-content mt-2" id="pills-tabContent">
						<h4 className="mb-0 text-primary">ລາຍງານ {total} ລາຍການ</h4>

						{listFolder?.map((item, index) => (
							<div className="card iq-product-list-view mb-2">
								<div className="card-body d-flex flex-wrap justify-content-between">
									<div className="iq-product-list-left-side d-flex gap-3 flex-wrap align-items-center justify-content-center justify-content-sm-center">
										<img
											src={folderImage}
											alt="item Image"
											className="img-fluid trending-img iq-product-img"
											loading="lazy"
											width={120}
										/>
										<div className="iq-list-view-left text-center text-sm-start">
											<strong className="text-danger">
												{item?.title ? item?.title : "No title"}
											</strong>
											<h4 className="mb-3">
												{" "}
												{item?.folderNumber
													? item?.folderNumber
													: "No ID"}
											</h4>
											<div>
												<div className="d-flex align-items-center">
													<svg
														className="icon-18 me-2"
														width="18"
														viewBox="0 0 24 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															opacity="0.4"
															d="M22 12C22 17.524 17.523 22 12 22C6.477 22 2 17.524 2 12C2 6.478 6.477 2 12 2C17.523 2 22 6.478 22 12Z"
															fill="currentColor"
														></path>
														<path
															d="M15.5739 15.8145C15.4429 15.8145 15.3109 15.7805 15.1899 15.7095L11.2639 13.3675C11.0379 13.2315 10.8989 12.9865 10.8989 12.7225V7.67554C10.8989 7.26154 11.2349 6.92554 11.6489 6.92554C12.0629 6.92554 12.3989 7.26154 12.3989 7.67554V12.2965L15.9589 14.4195C16.3139 14.6325 16.4309 15.0925 16.2189 15.4485C16.0779 15.6835 15.8289 15.8145 15.5739 15.8145Z"
															fill="currentColor"
														></path>
													</svg>

													<h6 className="mb-0">ຕູ້ເອກະສານ</h6>
													<small className="ms-2">
														{" "}
														{item?.loggerID?.title
															? item?.loggerID?.title
															: "No Logger"}
													</small>
												</div>
												<div className="d-flex align-items-center">
													<svg
														class="icon-18 me-2"
														width="18"
														viewBox="0 0 24 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z"
															fill="currentColor"
														></path>
														<path
															opacity="0.4"
															d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z"
															fill="currentColor"
														></path>
														<path
															opacity="0.4"
															d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z"
															fill="currentColor"
														></path>
														<path
															d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z"
															fill="currentColor"
														></path>
														<path
															opacity="0.4"
															d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z"
															fill="currentColor"
														></path>
														<path
															d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z"
															fill="currentColor"
														></path>
													</svg>

													<h6 className="mb-0">ພະແນກ</h6>
													<small className="ms-2">
														{" "}
														{item?.departmentID.title_lao
															? item?.departmentID?.title_lao
															: "No Department"}
													</small>
												</div>
											</div>
										</div>
									</div>
									<div className="iq-list-view-right d-flex flex-column justify-content-center align-items-end d-block align-items-center align-items-sm-end mx-sm-0 mx-auto">
										<div>
											<span className="badge rounded-pill p-2 px-3 bg-soft-success">
												{item?.createdBy?.firstName
													? item?.createdBy?.firstName +
													" " +
													item?.createdBy?.lastName
													: "No Author"}
											</span>
										</div>
										<div className="d-flex flex-column text-end">
											<span>
												{item?.createdAt
													? formatDateTime(item?.createdAt)
													: "No Timestamp"}
											</span>
										</div>
										<div className="d-flex flex-column text-end">
											<button type="button"
												className="btn btn-light btn-sm"
												onClick={() => history.push(`/folder/detail/${item?._id}`)}
											>
												<i className="icon-list" /> ເບິ່ງຂໍ້ມູນເອກະສານ
											</button>
										</div>
										<div className="iq-list-options d-flex align-items-end gap-2">

										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</React.Fragment >
	);
}
