import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import useReactRouter from "use-react-router";
import {
  gcp_docs,
  DisPlayFiles,
  endOfMonth,
  formatDateDash,
  getStaffLogin,
  setParams,
  startOfMonth,
} from "../../helper";
import { QUERY_DOCUMENTS } from "../documents/apollo";
import Pagination from "../../helper/components/pagination/Pagination";
import ListDocumentType from "../../helper/components/pagination/DocumentType";
import LimitData from "../../controllers/LimitData";

export default function AllFilesList() {
  const _user = getStaffLogin().data;
  const { history, location } = useReactRouter();
  const queryParams = new URLSearchParams(location?.search);
  const [searchValue, setSearchValue] = useState("");
  const [newLoadData, setNewLoadData] = useState(false);
  const [documentType, setDocumentType] = useState([]);
  const [listDocument, setListDocument] = useState([]);
  const [listDocumentPrivate, setListDocumentPrivate] = useState([]);
  const [startDate, setStartDate] = useState(startOfMonth());
  const [endDate, setEndDate] = useState(endOfMonth());
  const [numberPage, setNumberPage] = useState(1);
  const [numberRow, setNumberRow] = useState(20);

  //Query
  const [queryDocument, { data: resultData }] = useLazyQuery(QUERY_DOCUMENTS, {
    fetchPolicy: "cache-and-network",
  });

  const [queryDocumentPrivate, { data: resultDataPrivate }] = useLazyQuery(
    QUERY_DOCUMENTS,
    { fetchPolicy: "cache-and-network" }
  );

  useEffect(() => {
    queryDocument({
      variables: {
        where: {
          departmentID: parseInt(_user?.department?._id),
          createdAt_gte: startDate ? startDate : undefined,
          createdAt_lte: endDate ? endDate : undefined,
          isDeleted: 0,
          isPublic: 1,
          isPrivate: 0,
          typeDocument: documentType?._id ? documentType?._id : undefined,
        },
        skip: numberRow * (numberPage - 1),
        limit: parseInt(numberRow),
        orderBy: "createdAt_DESC",
      },
    });

    queryDocumentPrivate({
      variables: {
        where: {
          createdBy: parseInt(_user?._id),
          createdAt_gte: startDate ? startDate : undefined,
          createdAt_lte: endDate ? endDate : undefined,
          isDeleted: 0,
          isPublic: 1,
          isPrivate: 1,
          typeDocument: documentType?._id ? documentType?._id : undefined,
        },
        skip: numberRow * (numberPage - 1),
        limit: parseInt(numberRow),
        orderBy: "createdAt_DESC",
      },
    });
  }, [
    newLoadData,
    startDate,
    endDate,
    numberPage,
    numberRow,
    documentType,
  ]);

  useEffect(() => {
    const results = resultData?.documents?.data;
    if (searchValue) {
      const filter = results?.filter((obj) => {
        if (
          obj?.documentID?.includes(searchValue) ||
          obj?.title?.includes(searchValue)
        ) {
          return obj;
        }
      });
      setListDocument(filter);
    } else {
      setListDocument(results);
    }
  }, [resultData, searchValue]);

  useEffect(() => {
    const results = resultDataPrivate?.documents?.data;
    if (searchValue) {
      const filter = results?.filter((obj) => {
        if (
          obj?.documentID?.includes(searchValue) ||
          obj?.title?.includes(searchValue)
        ) {
          return obj;
        }
      });
      setListDocumentPrivate(filter);
    } else {
      setListDocumentPrivate(results);
    }
  }, [resultDataPrivate, searchValue]);

  // page
  useEffect(() => {
    let _startDate = queryParams.get("startDate");
    let _endDate = queryParams.get("endDate");
    let _page = queryParams.get("page");
    setNumberPage(_page ? parseInt(_page) : 1);
    if (_startDate) {
      setStartDate(_startDate);
    } else {
      setStartDate(startOfMonth());
    }
    if (_endDate) {
      setEndDate(_endDate);
    } else {
      setEndDate(endOfMonth());
    }
  }, [queryParams]);

  const countData = resultData?.documents?.total;
  const countDataPrivate = resultDataPrivate?.documents?.total;
  const _countData = countData + countDataPrivate;

  const countPage = [];
  for (var i = 1; i <= Math.ceil(_countData / numberRow); i++) {
    countPage.push(i);
  }

  return (
    <>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <h3>ຂໍ້ມູນເອກະສານທັງໝົດ</h3><hr />
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <p className="mb-md-0 mb-2 d-flex align-items-center gap-2">
                  <div className="dropdown me-3">
                    <input
                      type="text"
                      className="form-control w-100"
                      placeholder="ຄົ້ນຫາດ້ວຍຊື່ເອກະສານ"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </div>

                </p>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="dropdown me-3">
                    <ListDocumentType
                      getData={(data) => {
                        setDocumentType(data);
                      }}
                      defaultValue={documentType?.title}
                    />
                  </div>
                  <div className="dropdown me-3">
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control"
                        value={startDate}
                        onChange={(e) =>
                          history.push({
                            search: setParams(`startDate`, e.target.value),
                          })
                        }
                      />
                      <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) =>
                          history.push({
                            search: setParams(`endDate`, e.target.value),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="dropdown me-3">
                    <LimitData
                      onChange={(val) => {
                        setNumberRow(val);
                      }}
                    />
                  </div>
                  <div className="dropdown me-3 pt-2">
                    <Pagination
                      className="mt-2"
                      pageTotal={countPage}
                      currentPage={numberPage}
                      onPageChange={(page) => {
                        history.push({
                          search: setParams(`page`, page),
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h4 className="py-3 mb-0">
          ໄຟລ໌ເອກະສານຕ່າງໆ {countData + countDataPrivate || 0} ໄຟລ໌
        </h4>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
          {listDocument?.map((item) =>
            item?.files.split(".").pop() === "pdf" ||
              item?.files.split(".").pop() === "presentation" ||
              item?.files.split(".").pop() === "pptx" ||
              item?.files.split(".").pop() === "ppt" ||
              item?.files.split(".").pop() === "pptm" ||
              item?.files.split(".").pop() === "document" ||
              item?.files.split(".").pop() === "docx" ||
              item?.files.split(".").pop() === "doc" ||
              item?.files.split(".").pop() === "docm" ||
              item?.files.split(".").pop() === "dotx" ||
              item?.files.split(".").pop() === "xlsx" ||
              item?.files.split(".").pop() === "xls" ||
              item?.files.split(".").pop() === "xlsm" ||
              item?.files.split(".").pop() === "sheet" ? (
              <div className="col">
                <div className="card iq-file-manager">
                  <div className="card-body card-thumbnail">
                    <div className="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
                      <DisPlayFiles file={item?.files} className="file" width={100} height={100} item={item} />
                    </div>
                    <div className="mt-2">
                      <p className="small">
                        <i className="icon-clock me-1" /> ວັນທີ່ສ້າງ:{" "}
                        {formatDateDash(item?.createdAt)}
                      </p>
                      <div className="d-flex align-items-center text-primary gap-2 mb-2">
                        <i className="icon-unfold_more" />
                        <p className="mb-0 text-primary fw-bolder me-3">
                          {item?.documentID
                            ? item?.documentID
                            : "No Document ID"}
                        </p>
                      </div>
                      <div className="d-flex align-items-center text-primary gap-2 mb-2">
                        <i className="icon-text_fields" />
                        <p className=" mb-0 text-dark me-3">
                          {item?.title?.substring(30)
                            ? item?.title?.substring(0, 30) + "..."
                            : item?.title}
                        </p>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between align-items-center">
                        <a
                          className="btn btn-secondary btn-sm w-100 rounded-pill"
                          href={gcp_docs + item?.files}
                          target="_blank"
                        >
                          <i class="fa-solid fa-circle-down me-1" />
                          ດາວໂຫຼດ
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : null
          )}

          {listDocumentPrivate?.map((item) =>
            item?.files.split(".").pop() === "pdf" ||
              item?.files.split(".").pop() === "presentation" ||
              item?.files.split(".").pop() === "pptx" ||
              item?.files.split(".").pop() === "ppt" ||
              item?.files.split(".").pop() === "pptm" ||
              item?.files.split(".").pop() === "document" ||
              item?.files.split(".").pop() === "docx" ||
              item?.files.split(".").pop() === "doc" ||
              item?.files.split(".").pop() === "docm" ||
              item?.files.split(".").pop() === "dotx" ||
              item?.files.split(".").pop() === "xlsx" ||
              item?.files.split(".").pop() === "xls" ||
              item?.files.split(".").pop() === "xlsm" ||
              item?.files.split(".").pop() === "sheet" ? (
              <div className="col">
                <div className="card iq-file-manager">
                  <div className="card-body card-thumbnail">
                    <div className="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
                      <DisPlayFiles file={item?.files} className="file" width={100} height={100} item={item} />
                    </div>
                    <div className="mt-2">
                      <p className="small">
                        <i className="icon-clock me-1" /> ວັນທີ່ສ້າງ:{" "}
                        {formatDateDash(item?.createdAt)}
                      </p>
                      <div className="d-flex align-items-center text-primary gap-2 mb-2">
                        <i className="icon-unfold_more" />
                        <p className="mb-0 text-primary fw-bolder me-3">
                          {item?.documentID
                            ? item?.documentID
                            : "No Document ID"}
                        </p>
                      </div>
                      <div className="d-flex align-items-center text-primary gap-2 mb-2">
                        <i className="icon-text_fields" />
                        <p className=" mb-0 text-dark me-3">
                          {item?.title?.substring(30)
                            ? item?.title?.substring(0, 30) + "..."
                            : item?.title}
                        </p>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between align-items-center">
                        <a
                          className="btn btn-secondary btn-sm w-100 rounded-pill"
                          href={gcp_docs + item?.files}
                          target="_blank"
                        >
                          <i class="fa-solid fa-circle-down me-1" />
                          ດາວໂຫຼດ
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : null
          )}
        </div>

        <h4 className="py-3 mb-0">ຮູບພາບ</h4>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
          {listDocument?.map((item) =>
            item?.files.split(".").pop() === "jpeg" ||
              item?.files.split(".").pop() === "jpg" ||
              item?.files.split(".").pop() === "png" ||
              item?.files.split(".").pop() === "gif" ||
              item?.files.split(".").pop() === "svg" ? (
              <div className="col">
                <div className="card iq-file-manager">
                  <div className="card-body card-thumbnail">
                    <div className="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
                      <DisPlayFiles file={item?.files} className="file" width={100} height={100} item={item} />
                    </div>
                    <div className="mt-2">
                      <p className="small">
                        <i className="icon-clock me-1" /> ວັນທີ່ສ້າງ:{" "}
                        {formatDateDash(item?.createdAt)}
                      </p>
                      <div className="d-flex align-items-center text-primary gap-2 mb-2">
                        <i className="icon-unfold_more" />
                        <p className="mb-0 text-primary fw-bolder me-3">
                          {item?.documentID
                            ? item?.documentID
                            : "No Document ID"}
                        </p>
                      </div>
                      <div className="d-flex align-items-center text-primary gap-2 mb-2">
                        <i className="icon-text_fields" />
                        <p className=" mb-0 text-dark me-3">
                          {item?.title?.substring(30)
                            ? item?.title?.substring(0, 30) + "..."
                            : item?.title}
                        </p>
                      </div>
                      <hr />

                      <div className="d-flex justify-content-between align-items-center">
                        <a
                          className="btn btn-secondary btn-sm w-100 rounded-pill"
                          href={gcp_docs + item?.files}
                          target="_blank"
                        >
                          <i class="fa-solid fa-circle-down me-1" />
                          ດາວໂຫຼດ
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : null
          )}

          {listDocumentPrivate?.map((item) =>
            item?.files.split(".").pop() === "jpeg" ||
              item?.files.split(".").pop() === "jpg" ||
              item?.files.split(".").pop() === "png" ||
              item?.files.split(".").pop() === "gif" ||
              item?.files.split(".").pop() === "svg" ? (
              <div className="col">
                <div className="card iq-file-manager">
                  <div className="card-body card-thumbnail">
                    <div className="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
                      <DisPlayFiles file={item?.files} className="file" width={100} height={100} item={item} />
                    </div>
                    <div className="mt-2">
                      <p className="small mb-2">
                        ວັນທີ່ສ້າງ: {formatDateDash(item?.createdAt)}
                      </p>
                      <div className="d-flex align-items-center text-primary gap-2 mb-2">
                        <i className="icon-unfold_more" />
                        <p className="mb-0 text-primary fw-bolder me-3">
                          {item?.documentID
                            ? item?.documentID
                            : "No Document ID"}
                        </p>
                      </div>
                      <div className="d-flex align-items-center text-primary gap-2 mb-2">
                        <i className="icon-text_fields" />
                        <p className=" mb-0 text-dark me-3">
                          {item?.title?.substring(30)
                            ? item?.title?.substring(0, 30) + "..."
                            : item?.title}
                        </p>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between align-items-center">
                        <a
                          className="btn btn-secondary btn-sm w-100 rounded-pill"
                          href={gcp_docs + item?.files}
                          target="_blank"
                        >
                          <i class="fa-solid fa-circle-down me-1" />
                          ດາວໂຫຼດ
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : null
          )}
        </div>
      </div>
    </>
  );
}
