/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import folderImage from "../../img/folder.jpeg";
import useReactRouter from "use-react-router";
import {
  formatDateTime,
  getStaffLogin,
  loadingData,
  messageError,
  messageSuccess,
} from "../../helper";
import { DELETE_FOLDER, QUERY_FOLDERS } from "./apollo";
import Notiflix from "notiflix";
import AddLogger from "./addFolder";
import EditFolder from "./editFolder";

export default function FolderList() {
  const { history } = useReactRouter();
  const _user = getStaffLogin().data;
  const [searchValue, setSearchValue] = useState("");
  const [newLoadData, setNewLoadData] = useState(false);
  const [deleteFolder] = useMutation(DELETE_FOLDER);
  const [listFolder, setListFolder] = useState([]);
  const [limit, setLimit] = useState(50);

  const [queryFolder, { data: resultData, loading }] = useLazyQuery(
    QUERY_FOLDERS,
    { fetchPolicy: "cache-and-network" }
  );
  useEffect(() => {
    queryFolder({
      variables: {
        where: {
          departmentID: parseInt(_user.department._id),
          title: searchValue ? String(searchValue) : undefined,
        },
        limit: parseInt(limit),
        orderBy: "createdAt_DESC",
      },
    });
  }, [searchValue, newLoadData, limit]);

  useEffect(() => {
    if (resultData) {
      setListFolder(resultData.documentFolders?.data);
    }
  }, [resultData]);
  const total = resultData?.documentFolders?.total;

  const _handleDelete = (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການລຶບຂໍ້ມູນ ນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _deleteFolder = await deleteFolder({
            variables: {
              where: {
                _id: parseInt(id),
              },
            },
          });
          if (_deleteFolder) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            setNewLoadData(!newLoadData);
          }
        } catch (error) {
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <h3>ຈັດການຂໍ້ມູນແຟ້ມເອກະສານ</h3><hr/>
        <div className="mb-4">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-0 text-primary">ແຟ້ມດອກະສານທັງໝົດ {total || 0} ແຟ້ມ</h4>
            <ul
              className="nav nav-pills mb-0 iq-product-filter d-flex bg-transparent align-items-center"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item dropdown d-none d-xl-block">
                <AddLogger
                  newLoad={newLoadData}
                  setNewLoad={(res) => setNewLoadData(res)}
                />
              </li>
              <li className="nav-item dropdown d-none d-xl-block">
                <div className="form-group input-group mb-0 search-input w-100">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="ຄົ້ນຫາ..."
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <span className="input-group-text">
                    <svg
                      className="icon-20"
                      width={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="11.7669"
                        cy="11.7666"
                        r="8.98856"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.0186 18.4851L21.5426 22"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </li>
              <li className="nav-item ms-2" role="presentation">
                <button
                  className="nav-link btn btn-sm btn-icon rounded-pill"
                  id="grid-view-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-grid-view"
                  type="button"
                  role="tab"
                  aria-controls="pills-grid-view"
                  aria-selected="false"
                  tabIndex={-1}
                >
                  <span className="btn-inner">
                    <svg
                      className="icon-35"
                      width={35}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z"
                        fill="currentColor"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </button>
              </li>
              <li className="nav-item ms-2" role="presentation">
                <button
                  className="nav-link btn btn-sm btn-icon rounded-pill active"
                  id="list-view-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-list-view"
                  type="button"
                  role="tab"
                  aria-controls="pills-list-view"
                  aria-selected="true"
                >
                  <span className="btn-inner">
                    <svg
                      className="icon-32"
                      width={32}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M10.0833 15.958H3.50777C2.67555 15.958 2 16.6217 2 17.4393C2 18.2559 2.67555 18.9207 3.50777 18.9207H10.0833C10.9155 18.9207 11.5911 18.2559 11.5911 17.4393C11.5911 16.6217 10.9155 15.958 10.0833 15.958Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.4"
                        d="M22.0001 6.37867C22.0001 5.56214 21.3246 4.89844 20.4934 4.89844H13.9179C13.0857 4.89844 12.4102 5.56214 12.4102 6.37867C12.4102 7.1963 13.0857 7.86 13.9179 7.86H20.4934C21.3246 7.86 22.0001 7.1963 22.0001 6.37867Z"
                        fill="currentColor"
                      />
                      <path
                        d="M8.87774 6.37856C8.87774 8.24523 7.33886 9.75821 5.43887 9.75821C3.53999 9.75821 2 8.24523 2 6.37856C2 4.51298 3.53999 3 5.43887 3C7.33886 3 8.87774 4.51298 8.87774 6.37856Z"
                        fill="currentColor"
                      />
                      <path
                        d="M21.9998 17.3992C21.9998 19.2648 20.4609 20.7777 18.5609 20.7777C16.6621 20.7777 15.1221 19.2648 15.1221 17.3992C15.1221 15.5325 16.6621 14.0195 18.5609 14.0195C20.4609 14.0195 21.9998 15.5325 21.9998 17.3992Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </button>
              </li>
            </ul>
          </div>
          <div className="col-lg-12">
            <div className="tab-content mt-2" id="pills-tabContent">
              <div
                className="tab-pane fade active show"
                id="pills-list-view"
                role="tabpanel"
                aria-labelledby="list-view-tab"
              >
                <div className="row row-cols-1">
                  <div className="col">
                    {listFolder?.map((item, index) => (
                      <div className="card iq-product-list-view mb-2">
                        <div className="card-body d-flex flex-wrap justify-content-between">
                          <div className="iq-product-list-left-side d-flex gap-3 flex-wrap align-items-center justify-content-center justify-content-sm-center">
                            <img
                              src={folderImage}
                              alt="item Image"
                              className="img-fluid trending-img iq-product-img"
                              loading="lazy"
                              width={120}
                            />
                            <div className="iq-list-view-left text-center text-sm-start">
                              <strong className="text-danger">
                                {item?.title ? item?.title : "No title"}
                              </strong>
                              <h4 className="mb-3">
                                {" "}
                                {item?.folderNumber
                                  ? item?.folderNumber
                                  : "No ID"}
                              </h4>
                              <div>
                                <div className="d-flex align-items-center">
                                  <svg
                                    className="icon-18 me-2"
                                    width="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.4"
                                      d="M22 12C22 17.524 17.523 22 12 22C6.477 22 2 17.524 2 12C2 6.478 6.477 2 12 2C17.523 2 22 6.478 22 12Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M15.5739 15.8145C15.4429 15.8145 15.3109 15.7805 15.1899 15.7095L11.2639 13.3675C11.0379 13.2315 10.8989 12.9865 10.8989 12.7225V7.67554C10.8989 7.26154 11.2349 6.92554 11.6489 6.92554C12.0629 6.92554 12.3989 7.26154 12.3989 7.67554V12.2965L15.9589 14.4195C16.3139 14.6325 16.4309 15.0925 16.2189 15.4485C16.0779 15.6835 15.8289 15.8145 15.5739 15.8145Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>

                                  <h6 className="mb-0">ຕູ້ເອກະສານ</h6>
                                  <small className="ms-2">
                                    {" "}
                                    {item?.loggerID?.title
                                      ? item?.loggerID?.title
                                      : "No Logger"}
                                  </small>
                                </div>
                                <div className="d-flex align-items-center">
                                  <svg
                                    class="icon-18 me-2"
                                    width="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      opacity="0.4"
                                      d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      opacity="0.4"
                                      d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      opacity="0.4"
                                      d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>

                                  <h6 className="mb-0">ພະແນກ</h6>
                                  <small className="ms-2">
                                    {" "}
                                    {item?.departmentID.title_lao
                                      ? item?.departmentID?.title_lao
                                      : "No Department"}
                                  </small>
                                  
                                </div>
                                <button type="button"
                                    className="btn btn-light btn-sm"
                                    onClick={() => history.push(`/folder/detail/${item?._id}`)}
                                  >
                                    <i className="icon-list" /> ເບິ່ງຂໍ້ມູນເອກະສານ
                                  </button>
                              </div>
                            </div>
                          </div>
                          <div className="iq-list-view-right d-flex flex-column justify-content-center align-items-end d-block align-items-center align-items-sm-end mx-sm-0 mx-auto">
                            <div>
                              <span className="badge rounded-pill p-2 px-3 bg-soft-success">
                                {item?.createdBy?.firstName
                                  ? item?.createdBy?.firstName +
                                  " " +
                                  item?.createdBy?.lastName
                                  : "No Author"}
                              </span>
                            </div>
                            <div className="d-flex flex-column text-end">
                              <span>
                                {item?.createdAt
                                  ? formatDateTime(item?.createdAt)
                                  : "No Timestamp"}
                              </span>
                            </div>
                            <div className="iq-list-options d-flex align-items-end gap-2">

                              <EditFolder
                                data={item}
                                newLoad={newLoadData}
                                setNewLoad={(res) => setNewLoadData(res)}
                              />
                              <button
                                className="btn btn-primary"
                                onClick={() => _handleDelete(item?._id)}
                              >
                                <i className="icon-trash" /> 
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-grid-view"
                role="tabpanel"
                aria-labelledby="grid-view-tab"
              >
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
                  {listFolder?.map((item, index) => (
                    <div className="col">
                      <div className="card iq-product-custom-card animate:hover-media ">
                        <div className="iq-product-hover-img position-relative animate:hover-media-wrap">
                          <a href="../e-commerce/product-detail.html">
                            <img
                              src={folderImage}
                              alt="product-details"
                              className="img-fluid iq-product-img hover-media"
                              loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center mb-1">
                            <a className="h6 iq-product-detail mb-0">
                              {item?.title ? item?.title : "No title"}
                            </a>
                            <div className="d-flex align-items-center">
                              {item?.folderNumber
                                ? item?.folderNumber
                                : "No ID"}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-1">
                            <h5 className="h6 mb-0">
                              ຕູ້ເອກະສານ
                            </h5>
                            <div className="d-flex align-items-center">
                              {item?.loggerID?.title
                                ? item?.loggerID?.title
                                : "No ID"}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">ພະແນກ</h5>
                            <span>
                              {" "}
                              {item?.departmentID?.title_lao
                                ? item?.departmentID?.title_lao
                                : "No Department"}
                            </span>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-between align-items-center">
                            <EditFolder
                              data={item}
                              newLoad={newLoadData}
                              setNewLoad={(res) => setNewLoadData(res)}
                            />
                            <button
                              className="btn btn-primary"
                              onClick={() => _handleDelete(item?._id)}
                            >
                             <i className="icon-trash"/>
                            </button>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <center>
              {loading ? loadingData(25, "ກຳລັງໂຫຼດຂໍ້ມູນ...") : false}
            </center>
          ) : (
            listFolder.length > 50 && (
              <div className="text-center">
                <a
                  href="javascript:void(0);"
                  className="btn btn-primary "
                  onClick={() => setLimit(limit + 50)}
                >
                  <svg
                    class="icon-32"
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M18.1085 8.94037C18.1085 8.94037 14.9368 4.5 10.1792 4.5C8.07626 4.5 6.05943 5.3354 4.57242 6.82242C3.0854 8.30943 2.25 10.3263 2.25 12.4292C2.25 14.5322 3.0854 16.549 4.57242 18.036C6.05943 19.5231 8.07626 20.3585 10.1792 20.3585C12.9354 20.3585 15.363 18.851 16.7839 16.9429"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M19.9876 12.7139C19.9026 12.7198 19.8173 12.7029 19.7402 12.6639L14.7222 10.0921C14.5844 10.0211 14.494 9.88782 14.4836 9.73793C14.4733 9.58804 14.5444 9.44359 14.6711 9.35425L19.2866 6.11519C19.421 6.02124 19.5979 6.00373 19.7483 6.07095C19.8992 6.13755 19.9992 6.27715 20.0102 6.43576L20.4126 12.2466C20.4236 12.4053 20.3439 12.5573 20.2035 12.6441C20.1375 12.6854 20.0629 12.7087 19.9876 12.7139"
                      fill="currentColor"
                    ></path>
                  </svg>{" "}
                  ໂຫຼດເພີ່ມເຕີມ
                </a>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}
