import React from "react";
import { Loading } from "notiflix";
import "../utils/index.css";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import logo from "../../../img/app-icon.png";
import bg from "../../../img/cover.gif";

import { LOGIN_USER } from "../apollo";
import {USER_KEY} from '../../../helper'
import { loadingScreen, messageError, messageWarning } from "../../../helper/";

export default function Login() {
  const [loginUser] = useMutation(LOGIN_USER);

  return (
    <body>
      <Formik
        initialValues={{
          phoneNumber: "",
          password: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.phoneNumber) errors.phoneNumber = "ກະລຸນາປ້ອນເບີໂທ";
          if (!values.password) errors.password = "ກະລຸນາປ້ອນລະຫັດຜ່ານ";
          return errors;
        }}
        onSubmit={async (values) => {
          try {
            let user = await loginUser({
              variables: {
                where: {
                  phoneNumber: parseInt(values?.phoneNumber),
                  password: String(values?.password),
                },
              },
            });
            if (user) {
              localStorage.setItem(
                USER_KEY,
                JSON.stringify(user?.data?.employeeLogin)
              );
              loadingScreen();
              setTimeout(() => {
                Loading.remove();
                window.location.href = `/home`;
              }, 2000);
            } else {
              messageWarning("ເບີໂທ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ");
            }
          } catch (error) {
            console.log(error)
            messageError("ເບີໂທ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="wrapper">
              <section className="login-content">
                <div className="row m-0 align-items-center bg-white h-100">
                  <div className="col-md-6">
                    <div className="row justify-content-center">
                      <div className="col-md-10">
                        <div className="card card-transparent shadow-none d-flex justify-content-center mb-0 auth-card iq-auth-form">
                          <div className="card-body">
                            <center>
                              <img
                                src={logo}
                                alt="logo"
                                className="img-fluid"
                                width={200}
                              />
                            </center>
                            <h2 className="mb-2 text-center">
                              ລະບົບຈັດການເອກະສານ
                            </h2>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label htmlFor="email" className="form-label">
                                    ເບີໂທ
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control form-control-lg"
                                    name="phoneNumber"
                                    placeholder="ເບີໂທ"
                                    onChange={handleChange}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                  {errors.phoneNumber &&
                                    touched.phoneNumber && (
                                      <div className="text-danger">
                                        {errors.phoneNumber}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label
                                    htmlFor="password"
                                    className="form-label"
                                  >
                                    ລະຫັດຜ່ານ
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control form-control-lg"
                                    placeholder="ລະຫັດຜ່ານ"
                                    name="password"
                                    onChange={handleChange}
                                  />
                                  {errors.password && touched.password && (
                                    <div className="text-danger">
                                      {errors.password}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center">
                              <button
                                type="submit"
                                handleSubmit={handleSubmit}
                                className="btn btn-primary btn-lg fs-3 w-100 hvr-shutter-out-horizontal"
                              >
                                ເຂົ້າສູ່ລະບົບ{" "}
                                <i className="icon-login float-end mt-2" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-md-block d-none bg-primary p-0 vh-100 overflow-hidden">
                    <img
                      src={bg}
                      className="img-fluid gradient-main animated-scaleX"
                      alt="images"
                      loading="lazy"
                      style={{backgroundSize:'cover',backgroundPosition:'center'}}
                    />
                  </div>
                </div>
              </section>
            </div>
          </form>
        )}
      </Formik>
    </body>
  );
}
