/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import loggerImage from "../../img/logger.png";
import {
  formatDateTime,
  getStaffLogin,
  loadingData,
  messageError,
  messageSuccess,
} from "../../helper";
import { DELETE_LOGGER, QUERY_LOGGERS } from "./apollo";
import Notiflix from "notiflix";
import AddLogger from "./addLogger";
import EditLogger from "./editLogger";
import useRouter from "use-react-router";

export default function LoggerList() {
  const _user = getStaffLogin().data
  const { history } = useRouter();
  const [searchValue, setSearchValue] = useState("");
  const [newLoadData, setNewLoadData] = useState(false);
  const [deleteLogger] = useMutation(DELETE_LOGGER);
  const [listLogger, setListLogger] = useState([]);
  const [limit, setLimit] = useState(50);
  const [queryLogger, { data: resultData, loading }] = useLazyQuery(
    QUERY_LOGGERS,
    { fetchPolicy: "cache-and-network" }
  );

  useEffect(() => {
    queryLogger({
      variables: {
        where: {
          departmentID: parseInt(_user?.department?._id),
          title: searchValue ? String(searchValue) : undefined,
        },
        limit: parseInt(limit),
        orderBy: "createdAt_DESC",
      },
    });
  }, [searchValue, newLoadData]);

  useEffect(() => {
    if (resultData) {
      setListLogger(resultData.documentLoggers?.data);
    }
  }, [resultData]);
  const total = resultData?.documentLoggers?.total;

  const _handleDelete = (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການລຶບຂໍ້ມູນ ນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _deleteLogger = await deleteLogger({
            variables: {
              where: {
                _id: parseInt(id),
              },
            },
          });
          if (_deleteLogger) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            setNewLoadData(!newLoadData);
          }
        } catch (error) {
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <h4>ຈັດການຂໍ້ມູນຕູ້ເອກະສານ</h4><hr/>
        <div className="mb-4">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-0 text-primary">ຕູ້ເອກະສານທັງໝົດ {total || 0} ໜ່ວຍ</h4>
            <ul
              className="nav nav-pills mb-0 iq-product-filter d-flex bg-transparent align-items-center"
              id="pills-tab"
              role="tablist"
            >
              
              <li className="nav-item dropdown d-none d-xl-block">
                <div className="form-group input-group mb-0 search-input w-100">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="ຄົ້ນຫາ..."
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <span className="input-group-text">
                    <svg
                      className="icon-20"
                      width={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="11.7669"
                        cy="11.7666"
                        r="8.98856"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.0186 18.4851L21.5426 22"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </li>
              <li className="nav-item dropdown d-none d-xl-block">
                <AddLogger
                  newLoad={newLoadData}
                  setNewLoad={(res) => setNewLoadData(res)}
                />
              </li>
              <li className="nav-item ms-2" role="presentation">
                <button
                  className="nav-link btn btn-sm btn-icon rounded-pill"
                  id="grid-view-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-grid-view"
                  type="button"
                  role="tab"
                  aria-controls="pills-grid-view"
                  aria-selected="false"
                  tabIndex={-1}
                >
                  <span className="btn-inner">
                    <svg
                      className="icon-35"
                      width={35}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z"
                        fill="currentColor"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </button>
              </li>
              <li className="nav-item ms-2" role="presentation">
                <button
                  className="nav-link btn btn-sm btn-icon rounded-pill active"
                  id="list-view-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-list-view"
                  type="button"
                  role="tab"
                  aria-controls="pills-list-view"
                  aria-selected="true"
                >
                  <span className="btn-inner">
                    <svg
                      className="icon-32"
                      width={32}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M10.0833 15.958H3.50777C2.67555 15.958 2 16.6217 2 17.4393C2 18.2559 2.67555 18.9207 3.50777 18.9207H10.0833C10.9155 18.9207 11.5911 18.2559 11.5911 17.4393C11.5911 16.6217 10.9155 15.958 10.0833 15.958Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.4"
                        d="M22.0001 6.37867C22.0001 5.56214 21.3246 4.89844 20.4934 4.89844H13.9179C13.0857 4.89844 12.4102 5.56214 12.4102 6.37867C12.4102 7.1963 13.0857 7.86 13.9179 7.86H20.4934C21.3246 7.86 22.0001 7.1963 22.0001 6.37867Z"
                        fill="currentColor"
                      />
                      <path
                        d="M8.87774 6.37856C8.87774 8.24523 7.33886 9.75821 5.43887 9.75821C3.53999 9.75821 2 8.24523 2 6.37856C2 4.51298 3.53999 3 5.43887 3C7.33886 3 8.87774 4.51298 8.87774 6.37856Z"
                        fill="currentColor"
                      />
                      <path
                        d="M21.9998 17.3992C21.9998 19.2648 20.4609 20.7777 18.5609 20.7777C16.6621 20.7777 15.1221 19.2648 15.1221 17.3992C15.1221 15.5325 16.6621 14.0195 18.5609 14.0195C20.4609 14.0195 21.9998 15.5325 21.9998 17.3992Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </button>
              </li>
            </ul>
          </div>
          <div className="col-lg-12">
            <div className="tab-content mt-2" id="pills-tabContent">
              <div
                className="tab-pane fade active show"
                id="pills-list-view"
                role="tabpanel"
                aria-labelledby="list-view-tab"
              >
                <div className="row row-cols-1">
                  <div className="col">
                    {listLogger?.map((logger) => (
                      <div className="card iq-product-list-view mb-2">
                        <div className="card-body d-flex flex-wrap justify-content-between">
                          <div className="iq-product-list-left-side d-flex gap-3 flex-wrap align-items-center justify-content-center justify-content-sm-center">
                            <img
                              src={loggerImage}
                              alt="Logger Image"
                              className="img-fluid trending-img iq-product-img"
                              loading="lazy"
                              width={120}
                            />
                            <div className="iq-list-view-left text-center text-sm-start">
                              <strong className="text-danger">
                                {logger?.title ? logger?.title : "No title"}
                              </strong>
                              <h4 className="mb-3">
                                {" "}
                                {logger?.loggerID ? logger?.loggerID : "No ID"}
                              </h4>
                              <div>
                                <div className="d-flex align-items-center">
                                  <svg
                                    className="icon-24"
                                    width={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M13.1043 4.17701L14.9317 7.82776C15.1108 8.18616 15.4565 8.43467 15.8573 8.49218L19.9453 9.08062C20.9554 9.22644 21.3573 10.4505 20.6263 11.1519L17.6702 13.9924C17.3797 14.2718 17.2474 14.6733 17.3162 15.0676L18.0138 19.0778C18.1856 20.0698 17.1298 20.8267 16.227 20.3574L12.5732 18.4627C12.215 18.2768 11.786 18.2768 11.4268 18.4627L7.773 20.3574C6.87023 20.8267 5.81439 20.0698 5.98724 19.0778L6.68385 15.0676C6.75257 14.6733 6.62033 14.2718 6.32982 13.9924L3.37368 11.1519C2.64272 10.4505 3.04464 9.22644 4.05466 9.08062L8.14265 8.49218C8.54354 8.43467 8.89028 8.18616 9.06937 7.82776L10.8957 4.17701C11.3477 3.27433 12.6523 3.27433 13.1043 4.17701Z"
                                      fill="#FFD329"
                                    />
                                  </svg>
                                  <h6 className="mb-0">ພະແນກ</h6>
                                  <small className="ms-2">
                                    {" "}
                                    {logger?.departmentID
                                      ? logger?.departmentID?.title_lao
                                      : "No Department"}
                                  </small>
                                </div>
                              </div>
                              <button type="button"
                                className="btn btn-light btn-sm"
                                onClick={() => history.push(`/logger/detail/${logger?._id}`)}
                              >
                                <i className="icon-list" /> ເບິ່ງຂໍ້ມູນແຟ້ມເອກະສານ
                              </button>
                            </div>
                          </div>
                          <div className="iq-list-view-right d-flex flex-column justify-content-center align-items-end d-block align-items-center align-items-sm-end mx-sm-0 mx-auto">

                          </div>
                          <div className="iq-list-view-right d-flex flex-column justify-content-center align-items-end d-block align-items-center align-items-sm-end mx-sm-0 mx-auto">
                            <div>
                              <span className="badge rounded-pill p-2 px-3 bg-soft-success">
                                {logger?.createdBy?.firstName
                                  ? logger?.createdBy?.firstName +
                                  " " +
                                  logger?.createdBy?.lastName
                                  : "No Author"}
                              </span>
                            </div>
                            <div className="d-flex flex-column text-end">
                              <span>
                                {logger?.createdAt
                                  ? formatDateTime(logger?.createdAt)
                                  : "No Timestamp"}
                              </span>
                            </div>
                            <div className="iq-list-options d-flex align-items-end gap-2">
                              <EditLogger
                                data={logger}
                                newLoad={newLoadData}
                                setNewLoad={(res) => setNewLoadData(res)}
                              />
                              <button
                                className="btn btn-primary"
                                onClick={() => _handleDelete(logger?._id)}
                              >
                                <i className="icon-trash" /> 
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-grid-view"
                role="tabpanel"
                aria-labelledby="grid-view-tab"
              >
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
                  {listLogger?.map((logger, index) => (
                    <div className="col">
                      <div className="card iq-product-custom-card animate:hover-media ">
                        <div className="iq-product-hover-img position-relative animate:hover-media-wrap">
                          <a href="../e-commerce/product-detail.html">
                            <img
                              src={loggerImage}
                              alt="product-details"
                              className="img-fluid iq-product-img hover-media"
                              loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center mb-1">
                            <a className="h6 iq-product-detail mb-0">
                              {logger?.title ? logger?.title : "No title"}
                            </a>
                            <div className="d-flex align-items-center">
                              {logger?.loggerID ? logger?.loggerID : "No ID"}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">ພະແນກ</h5>
                            <span>
                              {" "}
                              {logger?.departmentID?.title_lao
                                ? logger?.departmentID?.title_lao
                                : "No Department"}
                            </span>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-between align-items-center" onClick={() => setLimit(limit + 50)}>
                            <EditLogger
                              data={logger}
                              newLoad={newLoadData}
                              setNewLoad={(res) => setNewLoadData(res)}
                            />
                            <button
                              className="btn btn-primary"
                              onClick={() => _handleDelete(logger?._id)}
                            >
                              <i className="icon-trash" /> 
                            </button>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <center>
              {loading ? loadingData(25, "ກຳລັງໂຫຼດຂໍ້ມູນ...") : false}
            </center>
          ) : (
            listLogger.length > 50 && (
              <div className="text-center">
                <a href="javascript:void(0);" className="btn btn-primary ">
                  <svg
                    class="icon-32"
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M18.1085 8.94037C18.1085 8.94037 14.9368 4.5 10.1792 4.5C8.07626 4.5 6.05943 5.3354 4.57242 6.82242C3.0854 8.30943 2.25 10.3263 2.25 12.4292C2.25 14.5322 3.0854 16.549 4.57242 18.036C6.05943 19.5231 8.07626 20.3585 10.1792 20.3585C12.9354 20.3585 15.363 18.851 16.7839 16.9429"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M19.9876 12.7139C19.9026 12.7198 19.8173 12.7029 19.7402 12.6639L14.7222 10.0921C14.5844 10.0211 14.494 9.88782 14.4836 9.73793C14.4733 9.58804 14.5444 9.44359 14.6711 9.35425L19.2866 6.11519C19.421 6.02124 19.5979 6.00373 19.7483 6.07095C19.8992 6.13755 19.9992 6.27715 20.0102 6.43576L20.4126 12.2466C20.4236 12.4053 20.3439 12.5573 20.2035 12.6441C20.1375 12.6854 20.0629 12.7087 19.9876 12.7139"
                      fill="currentColor"
                    ></path>
                  </svg>{" "}
                  ໂຫຼດເພີ່ມເຕີມ
                </a>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}
