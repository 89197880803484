// import { useLazyQuery } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import { messageError, messageSuccess, valiDate } from "../../helper";

import {
  CREATE_SIGNATURE_PROVIDER,
  CREATE_SIGNATURE_SUB_PROVIDER,
  QUERY_SIGNATURE_SUB_PROVIDERS,
  DELETE_SIGNATURE_SUB_PROVIDER,
} from "./apollo";

import SelectDepartment from "../../helper/componentsList/SelectDepartment";
import QueryDepartment from "./selectDepartment";
import Notiflix from "notiflix";

export default function AddDepartmentSignature({ onLoadData, sendID }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [GetDepartment, setGetDepartment] = useState();
  const [createSignatureProvider] = useMutation(CREATE_SIGNATURE_PROVIDER);
  const [getSubProvider, setGetSubProvider] = useState();
  const [sigCanvas, setSigCanvas] = useState(null);
  const [newLoad, setNewLoad] = useState(false);

  const [createSignatureSubProvider] = useMutation(
    CREATE_SIGNATURE_SUB_PROVIDER
  );
  const [deleteSignatureSubProvider] = useMutation(
    DELETE_SIGNATURE_SUB_PROVIDER
  );
  const [querySignatureSubProvider, { data: DataSubProvider }] = useLazyQuery(
    QUERY_SIGNATURE_SUB_PROVIDERS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    if (show) {
      querySignatureSubProvider({
        variables: {
          where: {
            documentCreatorID: sendID ? parseInt(sendID) : null,
          },
          orderBy: "createdAt_DESC",
        },
      });
    } else {
      return;
    }
  }, [getSubProvider, DataSubProvider, onLoadData, show, newLoad]);
  const _total = DataSubProvider?.SignatureSubProviders?.total;
  const _deleteSignature_Sub_Provider = (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການລຶບຂໍ້ມູນ ນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _deleteSignatureSubProvider = await deleteSignatureSubProvider({
            variables: {
              where: {
                _id: parseInt(id),
              },
            },
          });
          if (_deleteSignatureSubProvider) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            onLoadData();
            setNewLoad(!newLoad);
          }
        } catch (error) {
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <Formik
      initialValues={{
        title: "",
        description: "",
      }}
      enableReinitialize={false}
      validate={(values) => {
        const errors = {};
        if (!values.title) {
          errors.title = "ກະລຸນາປ້ອນຫົວຂໍ້ກ່ອນ!";
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        try {
          let __createSignatureProvider = await createSignatureProvider({
            variables: {
              data: {
                title: String(values?.title),
                description: String(values?.description),
                department: parseInt(
                  GetDepartment?._id ? GetDepartment?._id : 0
                ),
              },
            },
          });
          const resID =
            __createSignatureProvider?.data?.createSignatureProvider?._id;

          if (resID) {
            await createSignatureSubProvider({
              variables: {
                data: {
                  signatureID: parseInt(resID),
                  documentCreatorID: parseInt(sendID),
                  // licence: sigCanvas?.toDataURL(),
                },
              },
            });
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setGetDepartment(GetDepartment);
            resetForm();
            setNewLoad(!newLoad);
            onLoadData();
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການເຂົ້າລະບົບມີຄວາມຂັດຂ້ອງ");
        }
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <React.Fragment>
          <a
            href="javascript:void(0)"
            onClick={() => setShow(true)}
            className="btn btn-sm btn-icon rounded-circle btn-outline-danger"
          >
            <i className="fa-solid fa-file-signature" />
          </a>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            animation={false}
            centered
            backdrop="static"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title className="fs-5">
                <svg
                  className="icon-22"
                  width={22}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                    fill="currentColor"
                  />
                </svg>
                ເພີ່ມຜູ້ເຊັນເອກະສານ
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ul className="row flex-wrap align-items-center list-inline m-0 p-0">
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ເຊັນໂດຍ:{valiDate()}
                    </label>
                    <input
                      type="text"
                      className={
                        errors.title
                          ? "form-control is-invalid"
                          : "form-control  invalid"
                      }
                      placeholder="ປ້ອນ..."
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{errors.title}</span>
                  </div>
                </li>

                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">ລາຍລະອຽດ:</label>
                    {/* textarea */}
                    <textarea
                      className={
                        errors.description
                          ? "form-control shadow-none p-3 W-100 is-invalid"
                          : "form-control shadow-none p-3 W-100 invalid"
                      }
                      rows="2"
                      placeholder="ລາຍລະອຽດ:"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{errors.description}</span>
                  </div>
                </li>
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">ພະແນກ</label>
                    <SelectDepartment
                      onChange={(data) => {
                        setGetDepartment(data);
                      }}
                    />
                    <span className="text-danger">{errors.GetDepartment}</span>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary btn-lg me-2"
                    onClick={() => handleSubmit()}
                  >
                    <i className="icon-check-circle me-1" />
                    ບັນທຶກ
                  </button>
                  <button
                    type="button"
                    className="btn btn-light btn-lg "
                    onClick={() => {
                      sigCanvas.clear();
                    }}
                  >
                    <i className="icon-x me-2" />
                    ຍົກເລີກ
                  </button>
                  <hr />
                  {_total !== 0 ? (
                    <>
                      <div className="table-responsive mt-0">
                        <table
                          id="basic-table"
                          className="table table-striped mb-0 table-sm"
                          role="grid"
                        >
                          <thead>
                            <tr>
                              <th>ລຳດັບ</th>
                              <th>ຫົວຂໍ້</th>
                              <th>ລາຍລະອຽດ</th>
                              <th>ພະແນກ</th>
                              <th className="text-center">ຈັດການ</th>
                            </tr>
                          </thead>
                          <tbody>
                            {DataSubProvider?.SignatureSubProviders?.data.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td className="text-nowrap">
                                    {item?.signatureID?.title
                                      ? item?.signatureID?.title
                                      : "-"}
                                  </td>
                                  <td className="text-nowrap">
                                    {item?.signatureID?.description
                                      ? item?.signatureID?.description
                                      : "-"}
                                  </td>
                                  <td className="text-nowrap">
                                    <QueryDepartment
                                      signID={item?.signatureID?._id}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        _deleteSignature_Sub_Provider(item?._id)
                                      }
                                    >
                                      <i className="icon-trash" />
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : null}
                </li>
              </ul>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      )}
    </Formik>
  );
}
