import React, { useEffect, useState } from "react";
import Notiflix from "notiflix";
import normalLogo from "../img/logo_next_day.png";
import miniLogo from "../img/ans-app-v3.0.png";
import {
  getStaffLogin,
  loadingScreen,
} from "../helper";
import ConfirmDocument from "../Screens/inbox/confirmdocument";
import ProFileInfo from "../Screens/proFilesInfo";

export default function NavBar({ history }) {
  useEffect(() => {
    //sidebar min
    const localSideBarMini = localStorage.getItem("SIDEBAR_MINI");
    if (localSideBarMini === "true") {
      document.body.classList.add("sidebar-collapse");
    }
  }, []);
  const _onLogout = async () => {
    Notiflix.Confirm.show(
      "ຢຶນຢັນ",
      "ທ່ານຕ້ອງການອອກຈາກລະບົບແທ້ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async function () {
        localStorage.clear();
        sessionStorage.clear();
        loadingScreen();
        setTimeout(() => {
          Notiflix.Loading.remove();
          window.location.replace("/login");
        }, 1500);
      },
      function () { }
    );
  };

  return (
    <nav className="nav navbar navbar-expand-xl iq-navbar sticky-top" style={{ position: 'fixed', right: 0, left: 0 }}>
      <div className="container-fluid navbar-inner text-center">
        <a onClick={() => history.push('/home')} className="navbar-brand">
          {/*Logo start*/}
          <div className="logo-main text-center">
            <div className="logo-normal">
              <img src={normalLogo} className="img-fluid" alt="" style={{ maxHeight: 50 }} />
            </div>
            <div className="logo-mini">
              <img src={miniLogo} className="img-fluid" alt="" />
            </div>
          </div>
        </a>
        <div
          className="sidebar-toggle"
          data-toggle="sidebar"
          data-active="true"
        >
          <i className="icon d-flex">
            <svg className="icon-20" width={20} viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
              />
            </svg>
          </i>
        </div>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="mb-2 navbar-nav ms-auto align-items-center navbar-list mb-lg-0">
            <ConfirmDocument />
            <li className="nav-item dropdown" id="itemdropdown1">
              <a
                className="py-0 nav-link d-flex align-items-center"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="btn btn-primary btn-icon btn-sm rounded-pill hvr-pulse">
                  <span className="btn-inner">
                    <i className="icon-user" />
                  </span>
                </div>
              </a>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <ProFileInfo />
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="javascript:;"
                    onClick={_onLogout}
                  >
                    <i className="icon-log-out" /> ອອກຈາກລະບົບ
                  </a>
                </li>
              </ul>
            </li>
            <li
              className="nav-item iq-full-screen d-none d-xl-block"
              id="fullscreen-item"
            >
              <a
                href="#"
                className="nav-link"
                id="btnFullscreen"
                data-bs-toggle="dropdown"
              >
                <div className="btn btn-primary btn-icon btn-sm rounded-pill hvr-pulse">
                  <span className="btn-inner">
                    <svg
                      className="normal-screen icon-24"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.5528 5.99656L13.8595 10.8961"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.8016 5.97618L18.5524 5.99629L18.5176 9.96906"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.8574 18.896L10.5507 13.9964"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.60852 18.9164L5.85775 18.8963L5.89258 14.9235"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <svg
                      className="full-normal-screen d-none icon-24"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.7542 10.1932L18.1867 5.79319"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.2976 10.212L13.7547 10.1934L13.7871 6.62518"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.4224 13.5726L5.82149 18.1398"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.74391 13.5535L10.4209 13.5723L10.3867 17.2755"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
