/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import useReactRouter from "use-react-router";
import {
  gcp_docs,
  disPlayFiles,
  formatDateDash,
  getStaffLogin,
  ITEM_PER_PAGE,
  setParams,
  messageError,
  messageSuccess,
  startOfMonth,
  toDayDash,
  createdAt_gte,
  createdAt_lte,
} from "../../helper";
import { DELETE_NEW_DOCUMENT, QUERY_NEW_DOCUMENTS } from "./apollo";
import Pagination from "../../helper/components/pagination/Pagination";
import Notiflix from "notiflix";
import ListDocumentType from "../../helper/components/pagination/DocumentType";
import AddNewDocument from "./addNewDocument";
import EditNewDocument from "./editDocument";
import AddDepartmentSignature from "./addDepartmentSignature";
import PrintNewDocuments from "./printNewDocuments";
// import AddNewDocument from "./addNewDocument";

export default function NewDocumentsList() {
  const _user = getStaffLogin().data;
  const { history, location } = useReactRouter();
  const queryParams = new URLSearchParams(location?.search);
  const [searchValue, setSearchValue] = useState("");
  const [newLoadData, setNewLoadData] = useState(false);
  const [listAll, setListAll] = useState([]);
  const [documentType, setDocumentType] = useState([]);
  const [startDate, setStartDate] = useState(startOfMonth());
  const [endDate, setEndDate] = useState(toDayDash());
  const [numberPage, setNumberPage] = useState(1);
  const [numberRow, setNumberRow] = useState(ITEM_PER_PAGE);

  const [deleteNewDocument] = useMutation(DELETE_NEW_DOCUMENT);
  const [factAll, { data: resultAll }] = useLazyQuery(QUERY_NEW_DOCUMENTS, {
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    factAll({
      variables: {
        where: {
          typeDocument: documentType?._id
            ? parseInt(documentType?._id)
            : undefined,
          documentDate_gte: startDate ? createdAt_gte(startDate) : null,
          documentDate_lte: endDate ? createdAt_lte(endDate) : null,
          documentCode: searchValue ? String(searchValue) : undefined,
        },
        skip: searchValue ? 0 : numberRow * (numberPage - 1),
        limit: searchValue ? 1000 : numberRow,
        orderBy: "createdAt_DESC",
      },
    });
  }, [
    searchValue,
    startDate,
    endDate,
    numberPage,
    numberRow,
    newLoadData,
    documentType,
  ]);
  useEffect(() => {
    if (resultAll) {
      setListAll(resultAll.documentCreators?.data);
    }
  }, [resultAll]);
  // page
  useEffect(() => {
    let page = queryParams.get("page");
    let rows = queryParams.get("rows");
    let startDate = queryParams.get("startDate");
    let endDate = queryParams.get("endDate");
    if (page) {
      setNumberPage(parseInt(page));
    } else {
      setNumberPage(1);
    }
    if (rows) {
      setNumberRow(parseInt(rows));
    } else {
      setNumberRow(ITEM_PER_PAGE);
    }
    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate(startOfMonth());
    }
    if (endDate) {
      setEndDate(endDate);
    } else {
      setEndDate(toDayDash());
    }
  }, [queryParams]);

  const countData = resultAll?.documentCreators?.total;
  const countPage = [];
  for (var i = 1; i <= Math.ceil(countData / numberRow); i++) {
    countPage.push(i);
  }

  const _deleteNewDocument = (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການລຶບຂໍ້ມູນ ນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _deleteNewDocument = await deleteNewDocument({
            variables: {
              where: {
                _id: parseInt(id),
              },
            },
          });
          if (_deleteNewDocument) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            setNewLoadData(!newLoadData);
          }
        } catch (error) {
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <h3>ຈັດການສ້າງເອກະສານໃໝ່</h3>
        <hr />
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <p className="mb-md-0 mb-2 d-flex align-items-center ">
                  <AddNewDocument
                    newLoad={newLoadData}
                    onSuccess={() => setNewLoadData(!newLoadData)}
                  />
                </p>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="dropdown me-2">
                    <ListDocumentType
                      getData={(data) => {
                        setDocumentType(data);
                      }}
                      defaultValue={documentType?.title}
                    />
                  </div>
                  <div className="dropdown me-2">
                    <input
                      type="text"
                      className="form-control w-100"
                      placeholder="ຄົ້ນຫາ"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </div>
                  <div className="dropdown me-1">
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control"
                        value={startDate}
                        onChange={(e) =>
                          history.push({
                            search: setParams(`startDate`, e.target.value),
                          })
                        }
                      />
                      <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) =>
                          history.push({
                            search: setParams(`endDate`, e.target.value),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="dropdown me-1">
                    <select
                      className="form-select"
                      onChange={(e) =>
                        history.push({
                          search: setParams(`rows`, e.target.value),
                        })
                      }
                    >
                      <option
                        value={10}
                        selected={numberRow === 10 ? true : false}
                      >
                        10 ລາຍການ
                      </option>
                      <option
                        value={50}
                        selected={numberRow === 50 ? true : false}
                      >
                        50 ລາຍການ
                      </option>
                      <option
                        value={100}
                        selected={numberRow === 100 ? true : false}
                      >
                        100 ລາຍການ
                      </option>
                      <option
                        value={500}
                        selected={numberRow === 500 ? true : false}
                      >
                        500 ລາຍການ
                      </option>
                      <option
                        value={1000}
                        selected={numberRow === 1000 ? true : false}
                      >
                        1000 ລາຍການ
                      </option>
                    </select>
                  </div>
                  <div className="dropdown me-3 pt-2">
                    <Pagination
                      className="mt-2"
                      pageTotal={countPage}
                      currentPage={numberPage}
                      onPageChange={(page) => {
                        history.push({
                          search: setParams(`page`, page),
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 className="my-3">ເອກະສານທັງໝົດ {countData} ໄຟລ໌</h3>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
          {listAll?.map((item) => (
            <div className="col">
              <div className="card iq-file-manager">
                <div className="card-body card-thumbnail">
                  <a
                    data-title="PDF"
                    data-load-file="file"
                    data-load-target="#resolte-contaniner"
                    data-url="../file-manager/assets/images/doc-files/demo.pdf"
                  >
                    <div className="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
                      <div className="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
                        {disPlayFiles("a.txt", "file", 100, 110)}                  
                      </div>
                    </div>
                  </a>
                  <div className="mt-1">
                    <p className="mb-0">
                      <i class="fa fa-users" aria-hidden="true" />{" "}
                      {item?.department?.title_lao
                        ? item?.department?.title_lao
                        : "ບໍ່ມີພະແນກ"}
                    </p>
                    <div className="d-flex justify-content-between">
                      <p className="small">
                        <i className="icon-clock me-1" /> ວັນທີ່ສ້າງ:{" "}
                        {item?.documentDate
                          ? formatDateDash(item?.documentDate)
                          : ""}
                      </p>
                      <div className="btn-group">
                        <EditNewDocument
                          data={item}
                          onSuccess={() => setNewLoadData(!newLoadData)}
                        />
                        <a
                          href="javascript:void();"
                          className="ms-3 btn btn-sm btn-icon rounded-circle btn-outline-danger"
                          onClick={() => _deleteNewDocument(item?._id)}
                        >
                          <i className="icon-trash" />
                        </a>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="small text-primary gap-2 mb-2 d-flex">
                        <i className="icon-unfold_more" />{" "}
                        <b>
                          {item?.documentCode
                            ? item?.documentCode
                            : "ບໍ່ມີລະຫັດເອກະສານ"}
                        </b>
                      </p>
                      <div className="btn-group">
                        <AddDepartmentSignature
                          onLoadData={() => setNewLoadData(!newLoadData)}
                          sendID={item?._id}
                        />
                      </div>
                    </div>

                    <div className="d-flex align-items-center text-primary gap-2 mb-2">
                      <i className="icon-text_fields" />
                      <p className=" mb-0 text-dark me-3">
                        {item?.typeDocument?.title
                          ? item?.typeDocument?.title
                          : "ບໍ່ມີໝວດເອກະສານ"}
                      </p>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <PrintNewDocuments
                        _data={item}
                        onLoadData={newLoadData}
                      />
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
