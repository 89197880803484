import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Select from "react-select";
import { getLocalBranch } from "..";

const QUERY = gql`
  query Employees($where: EmployeeWhereInput) {
    employees(where: $where) {
      data {
        _id
        firstName
        lastName
        department {
          _id
          title_lao
        }
      }
    }
  }
`;

export default function SelectUser({ className, style, onChange, disabled, value }) {
  const [getData, setData] = useState([]);
  const branchID = getLocalBranch()?._id;
  const [selectedOption, setSelectedOption] = useState(null);
  const [fetchData, { data, loading }] = useLazyQuery(QUERY);

  useEffect(() => {
    fetchData({
      variables: {
        where: {},
      },
    });
  }, []);

  useEffect(() => {
    const results = data?.employees?.data || [];
    if (results?.length > 0) {
      const _results = results.map((_data) => {
        const object = {
          ..._data,
          value: _data?._id,
          label: _data?.firstName + " " + _data?.lastName ,
        };
        return object;
      });
      setData(_results);
    } else {
      setData([]);
    }
  }, [data]);

  //set value
  useEffect(() => {
    if (value) {
      const result = getData?.filter((_data) => _data?._id === value);
      setSelectedOption(result[0] || null);
    } else {
      setSelectedOption(null);
    }
  }, [getData, value]);

  return (
    <div style={{ minWidth: 200, color: "black", fontSize: 16, marginTop: 7 }}>
      <Select
        styles={style}
        className={className}
        isDisabled={disabled}
        value={selectedOption}
        placeholder={loading ? "ກຳລັງໂຫຼດ..." : "ເລືອກຜູ້ຮັບເອກະສານ"}
        onChange={(res) => {
          setSelectedOption(res);
          if (onChange) {
            onChange(res);
          }
        }}
        options={getData}
      />
    </div>
  );
}
