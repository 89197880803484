import React from "react";
import "moment/locale/lo";
import "./App.css";
import { clearLocalStorage, USER_KEY } from "./helper";
import _ from "lodash";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import Notiflix from "notiflix";
import Routes from "./Routes";
const api = "https://api.services.anousith.express/graphql";
// const api = "https://api.dev.services.anousith.express/graphql";
// const api = "http://localhost:7070/graphql";

const AppContext = React.createContext();
export default function App() {
  const authLink = setContext((_, { headers }) => {
    const _local = localStorage.getItem(USER_KEY);
    let token = JSON.parse(_local);
    token = token?.accessToken;
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors }) => {
    const isError1 = _.some(graphQLErrors, {
      message: "Error: TokenExpiredError: jwt expired",
    });
    const isError2 = _.some(graphQLErrors, {
      message: "Error: JsonWebTokenError: jwt must be provided",
    });
    const isError3 = _.some(graphQLErrors, {
      message: "Error: DO_NOT_HAVE_PERMISSION",
    });
    const isErrorToken = _.some(graphQLErrors, {
      message: "Error: JsonWebTokenError: invalid signature",
    });

    let message = "";
    if (isError1) {
      message =
        "ການເຂົ້າລະບົບຂອງທ່ານໄດ້ໝົດອາຍຸລົງແລ້ວ ກະລຸນາເຂົ້າລະບົບໃໝ່ອີກຄັ້ງ";
    }
    if (isError2) {
      message = "ຂໍອະໄພ ມີຂໍ້ຜິດພາດ ກະລຸນາເຂົ້າລະບົບໃໝ່ອີກຄັ້ງ";
    }
    if (isError3) {
      message = "ຂໍອະໄພ ບັນຊີຂອງທ່ານບໍ່ມີສິດເຂົ້າໃຊ້ງານ";
    }
    if (isErrorToken) {
      message = "ຂໍອະໄພ ລະບົບບໍສາມາດລະບຸ ຂໍ້ມູນຂອງທ່ານໄດ້";
    }
    if (isError1 || isErrorToken) {
      Notiflix.Report.warning("ແຈ້ງເຕືອນ", message, "ຕົກລົງ", async () => {
        Notiflix.Loading.standard("ກຳລັງດຳເນິນງານ...");
        clearLocalStorage();
        window.location.replace("/login");
      });
    }
  });

  const client = new ApolloClient({
    link: from([
      errorLink,
      authLink.concat(
        createHttpLink({
          uri: api,
        })
      ),
    ]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
  return (
    <AppContext.Provider>
      <ApolloProvider client={client}>
        <div className="app-container">
          <Routes />
        </div>
      </ApolloProvider>
    </AppContext.Provider>
  );
}
export { AppContext };
