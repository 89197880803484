import { gql } from "apollo-boost";

export const LOGIN_USER = gql`
 mutation EmployeeLogin($where: EmployeeLoginInput) {
  employeeLogin(where: $where) {
    accessToken
    data {
      _id
      firstName
      lastName
      role
      department {
        title_lao
        _id
        title_eng
      }
    }
  }
}
`;
