/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  gcp_docs,
  disPlayFiles,
  formatDateDash,
  getStaffLogin,
  ITEM_PER_PAGE,
  messageError,
  messageSuccess,
  setParams,
  startOfMonth,
  toDayDash,
} from "../../helper";
import Notiflix from "notiflix";
import Pagination from "../../helper/components/pagination/Pagination";
import LimitData from "../../controllers/LimitData";
import useReactRouter from "use-react-router";
import {
  DELETE_FOR_WAR_DOCUMENT,
  QUERY_FOR_WAR_DOCUMENTS,
} from "../send/apollo";

export default function InboxPrivateList() {
  const { history, location, match } = useReactRouter();
  const queryParams = new URLSearchParams(location?.search);
  const _user = getStaffLogin().data;
  const [searchValue, setSearchValue] = useState("");
  const [newLoadData, setNewLoadData] = useState(false);
  const [deleteForwar] = useMutation(DELETE_FOR_WAR_DOCUMENT);
  const [listFolder, setListFolder] = useState([]);
  const [startDate, setStartDate] = useState(startOfMonth());
  const [endDate, setEndDate] = useState(toDayDash());
  const [numberPage, setNumberPage] = useState(1);
  const [numberRow, setNumberRow] = useState(ITEM_PER_PAGE);

  const [queryFolder, { data: resultData }] = useLazyQuery(
    QUERY_FOR_WAR_DOCUMENTS,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  useEffect(() => {
    queryFolder({
      variables: {
        where: {
          receiptDateBetween: [startDate, endDate],
          isPrivate: 1,
          status: 1,
          receiptBy: _user?._id,
        },
        limit: numberRow,
        skip: numberRow * (numberPage - 1),
        orderBy: "createdAt_DESC",
      },
    });
  }, [newLoadData, startDate, endDate]);

  useEffect(() => {
    const results = resultData?.forwardDocuments?.data;
    if (searchValue) {
      const filter = results?.filter((obj) => {
        if (
          obj?.document?.title?.includes(searchValue) ||
          obj?.document?.documentID?.includes(searchValue)
        ) {
          return obj;
        }
      });
      setListFolder(filter);
    } else {
      setListFolder(results);
    }
  }, [resultData, searchValue]);

  // page
  useEffect(() => {
    let _startDate = queryParams.get("startDate");
    let _endDate = queryParams.get("endDate");
    let _page = queryParams.get("page");
    setNumberPage(_page ? parseInt(_page) : 1);
    if (_startDate) {
      setStartDate(_startDate);
    } else {
      setStartDate(startOfMonth());
    }
    if (_endDate) {
      setEndDate(_endDate);
    } else {
      setEndDate(toDayDash());
    }
  }, [queryParams]);

  const countData = resultData?.forwardDocuments?.total;
  const countPage = [];
  for (var i = 1; i <= Math.ceil(countData / numberRow); i++) {
    countPage.push(i);
  }

  return (
    <>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <h3>ລາຍງານຮັບຂໍ້ມູນເອກະສານສ່ວນຕົວ</h3>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <p className="mb-md-0 mb-2 d-flex align-items-center gap-2">
                    <input
                      type="text"
                      className="form-control w-100"
                      placeholder="ຄົ້ນຫາ"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </p>
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="dropdown me-3">
                      <div className="input-group">
                        <input
                          type="date"
                          className="form-control"
                          value={startDate}
                          onChange={(e) =>
                            history.push({
                              search: setParams(`startDate`, e.target.value),
                            })
                          }
                        />
                        <input
                          type="date"
                          className="form-control"
                          value={endDate}
                          onChange={(e) =>
                            history.push({
                              search: setParams(`endDate`, e.target.value),
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="dropdown me-3">
                      <LimitData
                        onChange={(val) => {
                          setNumberRow(val);
                        }}
                      />
                    </div>
                    <div className="dropdown me-3 pt-2">
                      <Pagination
                        className="mt-2"
                        pageTotal={countPage}
                        currentPage={numberPage}
                        onPageChange={(page) => {
                          history.push({
                            search: setParams(`page`, page),
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">
                    ເອກະສານທີ່ຮັບເຂົ້າ {countData || 0} ໄຟລ໌
                  </h4>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive mt-4">
                  <table
                    id="basic-table"
                    className="table table-striped mb-0 table-sm"
                    role="grid"
                  >
                    <thead>
                      <tr>
                        <th>ໄຟລ໌</th>
                        <th>ເອກະສານ</th>
                        <th>ຫົວເລື່ອງ</th>
                        <th>ວັນທີ່ຮັບ</th>
                        <th>ວັນທີ່ສົ່ງ</th>
                        <th>ຜູ້ສົ່ງ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listFolder?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center">
                              <a
                                data-fslightbox="gallery"
                                href={`${gcp_docs + item?.document?.files}`}
                                target="_blank"
                              >
                                <div className="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
                                  {disPlayFiles(item?.document?.files)}
                                </div>
                              </a>
                            </div>
                          </td>
                          <td>
                            {item?.document?.title || " "}
                            <br />
                            ເລກທີ່ເອກະສານ: {item?.document?.documentID || " "}
                          </td>
                          <td>
                            {item?.description || " "}
                            <br />
                            {item?.isPrivate === 0 ? (
                              <small className="text-success">
                                ເອກະສານທົ່ວໄປ
                              </small>
                            ) : (
                              <small className="text-danger">
                                ເອກະສານສ່ວນຕົວ
                              </small>
                            )}
                          </td>
                          <td>{formatDateDash(item?.receiptDate) || " "}</td>
                          <td>
                            <div className="text-info">
                              {formatDateDash(item?.sendDate)}
                            </div>
                          </td>
                          <td>
                            {item?.sendBy?.firstName || ""}{" "}
                            {item?.sendBy?.lastName || ""}
                            <hr className="m-1" />
                            {item?.department?.title_lao || ""}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
