import React, { useEffect, useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import logo from "../../img/logo_next_day.png";
// import "./print.css";
import _ from "lodash";
import { formatDateDash, formateDateSlashes } from "../../helper";
import { QUERY_SIGNATURE_SUB_PROVIDERS } from "./apollo";
import { useLazyQuery } from "@apollo/client";

import SignatureNewDocument from "./signatureNewDoument";
import image from "../../img/Nodata.png";

function PrintNewDocuments({ _data, onLoadData }) {
  const inputRef = useRef();
  const [reloadData, setReloadData] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => inputRef.current,
  });
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [factAll, { data: resultAll }] = useLazyQuery(
    QUERY_SIGNATURE_SUB_PROVIDERS,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  useEffect(() => {
    factAll({
      variables: {
        where: {
          documentCreatorID: _data?._id ? parseInt(_data?._id) : null,
        },
      },
    });
  }, [refresh, onLoadData]);

  return (
    <React.Fragment>
      <div className="col-md-12 mt-4">
        <button
          type="button"
          className="btn btn-secondary btn-sm w-100 rounded-pill"
          width="10px"
          style={{ position: "relative", top: "0px" }}
          onClick={() => {
            setShow(true);
            setReloadData(!reloadData);
          }}
        >
          <i className="fas fa-print" /> ພິມລາຍງານ ແລະ ເຊັນເອກະສານ
        </button>
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>ພິມລາຍງານ ແລະ ເຊັນເອກະສານ</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-md-12">
              <button
                onClick={handlePrint}
                type="button"
                className="btn btn-dark ml-5"
              >
                <i className="icon-print mr-1"></i> ພິມ
              </button>{" "}
            </div>
          </Row>
          <div style={{ paddingBottom: "10px" }} />
          <div className="row font-family">
            <div className="col-md-12" >
              <div className="table-responsive">
                <div ref={inputRef} id="print-header">
                  <table
                    className="table-sm"
                    id="table-to-xls"
                    border="0"
                    style={{
                      marginTop: 50,
                      width: "100%",
                      fontFamily: "Phetsarath OT",
                      // backgroundColor: "#ffffff",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td className="text-nowrap text-center " colSpan={4}>
                          <h5>ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</h5>
                          <h5>ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນະຖາວອນ</h5>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td style={{ textAlign: "left", color: "black" }}>
                          <img
                            src={logo}
                            style={{
                              width: 250,
                              marginBottom: 1,
                              marginTop: 20,
                            }}
                            alt=""
                            id="table-to-xls"
                          />
                        </td>
                        <td className="text-end">
                          <h5>
                            ເລກທີ:{" "}
                            {_data?.documentCode
                              ? _data?.documentCode
                              : "ບໍ່ມີເລກທີເອກະສານ"}
                          </h5>
                          <h5>
                            ທີ່ ນະຄອນຫຼວງວຽງຈັນ, ລົງວັນທີ:{" "}
                            {_data?.documentDate
                              ? formateDateSlashes(_data?.documentDate)
                              : "ບໍ່ມີວັນທີເອກະສານ"}
                          </h5>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td colSpan={4} className="text-center">
                          <h3>
                            <b >{_data?.typeDocument?.title}</b>
                          </h3>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td colSpan={3}>
                          <h5 style={{ marginLeft: 140 }}>
                            <b>ຮຽນ: </b>
                            {_data?.title ? _data?.title : ""}
                          </h5>
                          <br />
                          <h5 style={{ marginLeft: 140 }}>
                            <b>ເລື່ອງ: </b>
                            {_data?.subTitle ? _data?.subTitle : ""}
                          </h5>
                        </td>
                      </tr>
                      <tr></tr>
                    </tbody>
                  </table>
                  <img
                    style={{
                      opacity: 0.1,
                      transform: "rotate(-15deg)",
                      width: "900px",
                      height: "150px",
                      marginLeft: 100,
                      marginRight: 80
                    }}
                    src={logo} />
                  <div style={{ wordBreak: "break-all", padding: 10, marginTop: -150, marginRight: 80, marginLeft: 80 }}>
                    <h5>
                      <text style={{ paddingLeft: 35 }}>-   ອິງຕາມ:
                        {_data?.referenceText ? _data?.referenceText : ""}
                      </text>
                    </h5>
                  </div>
                  {_data?.subDetail ? (
                    <>
                      <div style={{ wordBreak: "break-all", marginRight: 80, marginLeft: 80 }}>
                        <h5>
                          <text style={{ paddingLeft: 55 }}>
                            {_data?.subDetail ? _data?.subDetail : ""}
                          </text>
                        </h5>
                      </div>
                    </>
                  ) : null}
                  <div style={{ wordBreak: "break-all", padding: 10, marginRight: 80, marginLeft: 80 }}>
                    <h5>
                      <text style={{ paddingLeft: 45 }}>
                        {_data?.details ? _data?.details : ""}
                      </text>
                    </h5>
                  </div>
                  <div style={{ wordBreak: "break-all", padding: 10, marginRight: 80, marginLeft: 80 }}>
                    <h5>
                      <text style={{ paddingLeft: 45 }}> 
                      {_data?.summaryText ? _data?.summaryText : ""}
                      </text>
                    </h5>
                  </div>
                  <div
                    style={{
                      wordBreak: "break-all",
                      padding: 10,
                      textAlign: "right",
                      marginRight: 80
                    }}
                  >
                    <h5>
                      <text style={{ paddingLeft: 10 }}> </text>
                      ຮຽນມາດ້ວຍຄວາມເຄົາລົບ ແລະ ນັບຖືຢ່າງສູງ
                    </h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: 80,
                      paddingRight: 80,
                    }}
                  >
                    {resultAll?.SignatureSubProviders?.data?.map(
                      (item, index) => (
                        <>
                          <span key={index}>
                            <h5 className="text-center">
                              {item?.signatureID?.title
                                ? item?.signatureID?.title
                                : "ບໍ່ມີຊື່ຫົວຂໍ້ທີ່ຈະເຊັນ"}
                              <br />
                              <SignatureNewDocument
                                onLoadData={() => setRefresh(!refresh)}
                                send_ID={item?._id}
                              />
                              <br />
                              {item?.licence ? (
                                <>
                                  <img
                                    className="img-xl rounded-circle"
                                    src={item?.licence ? item?.licence : image}
                                    style={{
                                      width: 130,
                                      height: 130,
                                    }}
                                  />
                                </>
                              ) : (
                                <div
                                  style={{
                                    width: 150,
                                    height: 150,
                                  }}
                                ></div>
                              )}
                            </h5>
                            <br />
                            <h5>ວັນທີ : {item?.sinatureDate ? formatDateDash(item?.sinatureDate) : "...../....../....."}</h5>
                            <h5>ຊື່ :
                              {item?.sinatureBy?.firstName || "................"}{" "}
                              {item?.sinatureBy?.lastName || "................"}

                            </h5>
                          </span>
                        </>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment >
  );
}

export default PrintNewDocuments;
