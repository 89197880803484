// import { useLazyQuery } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import { v4 as uuidv4 } from "uuid";
import {
  disPlayFiles,
  gcpUpload,
  getStaffLogin,
  messageError,
  messageSuccess,
} from "../../helper";
import { QUERY_TYPE_DOCUMENT } from "../typeDocuments/apollo";
import { UPDATE_DOCUMENT } from "../documents/apollo";
export default function EditDocument({ data, newLoad, setNewLoad }) {
  const _user = getStaffLogin().data;
  const [show, setShow] = useState(false);
  const [updateDocument] = useMutation(UPDATE_DOCUMENT);
  const handleClose = () => setShow(false);
  const [fileNames, setFileNames] = useState("");
  const [file, setFile] = useState(null);
  const [listTypeDocument, setListTypeDocument] = useState([]);

  const [queryTypeDocument, { data: resultData, loading }] = useLazyQuery(
    QUERY_TYPE_DOCUMENT,
    { fetchPolicy: "cache-and-network" }
  );
  useEffect(() => {
    queryTypeDocument({
      variables: {
        where: {
          departmentID: parseInt(_user.department._id),
        },
        limit: 1000,
        orderBy: "createdAt_DESC",
      },
    });
  }, []);

  useEffect(() => {
    if (resultData) {
      setListTypeDocument(resultData.documentTypes?.data);
    }
  }, [resultData]);

  const handleUpload = async (event) => {
    try {
      const _fileName =
        uuidv4() + "." + event.target.files[0].type.split("/")[1];
      const _file = event.target.files[0];
      setFile(_file);
      const res = await gcpUpload({
        file: _file,
        bucketName: "ansx",
        pathname: "docs",
        filename: _fileName,
      });
      if (res?.publicUrl) {
        setFile(_file);
        setFileNames(_fileName);
        messageSuccess("ການດຳເນິນງານສຳເລັດແລ້ວ");
      } else {
        messageError("ການດຳເນິນງານບໍ່ສຳເລັດ");
        setFile(null);
        setFileNames("");
      }
    } catch (error) {
      console.log(error);
      messageError("ການດຳເນິນງານບໍ່ສຳເລັດ");
    }
  };

  useEffect(() => {
    if (data) {
      setFile(true);
      setFileNames(data?.files);
    }
  }, [data]);

  return (
    <Formik
      initialValues={{
        title: data?.title,
        typeDocument: data?.typeDocument?._id,
        description: data?.description,
      }}
      enableReinitialize={true}
      validate={(values) => {
        const errors = {};
        if (!values.title) {
          errors.title = "error";
        }
        if (!values.typeDocument) {
          errors.typeDocument = "error";
        }
        return errors;
      }}
      onSubmit={async (values) => {
        try {
          let __updateDocument = await updateDocument({
            variables: {
              data: {
                title: String(values?.title),
                typeDocument: parseInt(values?.typeDocument),
                groupID: parseInt(data?.groupID?._id),
                files: String(fileNames),
                description: String(values?.description),
              },
              where: {
                _id: parseInt(data?._id),
              },
            },
          });

          if (__updateDocument) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setShow(false);
            setNewLoad(!newLoad);
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການເຂົ້າລະບົບມີຄວາມຂັດຂ້ອງ");
        }
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <React.Fragment>
          <a
            className="dropdown-item d-flex align-items-center gap-2"
            href="javascript:;"
            onClick={() => setShow(true)}
          >
            <svg
              className="icon-20"
              width={20}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M19.9927 18.9534H14.2984C13.7429 18.9534 13.291 19.4124 13.291 19.9767C13.291 20.5422 13.7429 21.0001 14.2984 21.0001H19.9927C20.5483 21.0001 21.0001 20.5422 21.0001 19.9767C21.0001 19.4124 20.5483 18.9534 19.9927 18.9534Z"
                fill="currentColor"
              />
              <path
                d="M10.309 6.90385L15.7049 11.2639C15.835 11.3682 15.8573 11.5596 15.7557 11.6929L9.35874 20.0282C8.95662 20.5431 8.36402 20.8344 7.72908 20.8452L4.23696 20.8882C4.05071 20.8903 3.88775 20.7613 3.84542 20.5764L3.05175 17.1258C2.91419 16.4915 3.05175 15.8358 3.45388 15.3306L9.88256 6.95545C9.98627 6.82108 10.1778 6.79743 10.309 6.90385Z"
                fill="currentColor"
              />
              <path
                opacity="0.4"
                d="M18.1208 8.66544L17.0806 9.96401C16.9758 10.0962 16.7874 10.1177 16.6573 10.0124C15.3927 8.98901 12.1545 6.36285 11.2561 5.63509C11.1249 5.52759 11.1069 5.33625 11.2127 5.20295L12.2159 3.95706C13.126 2.78534 14.7133 2.67784 15.9938 3.69906L17.4647 4.87078C18.0679 5.34377 18.47 5.96726 18.6076 6.62299C18.7663 7.3443 18.597 8.0527 18.1208 8.66544Z"
                fill="currentColor"
              />
            </svg>
            ແກ້ໄຂ
          </a>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            animation={false}
            centered
            backdrop="static"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title className="fs-5">
                <svg
                  className="icon-30 me-2"
                  width="30"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z"
                    fill="currentColor"
                  ></path>
                </svg>{" "}
                ແກ້ໄຂເອກະສານລົງກຸ່ມ
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center border-bottom pb-3 mb-4">
                <input
                  type="text"
                  className={
                    errors.title
                      ? "form-control shadow-none border-0 is-invalid"
                      : "form-control shadow-none border-0  invalid"
                  }
                  placeholder="ຫົວຂໍ້ເອກະສານ..."
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                />
              </div>
              <ul className="row flex-wrap align-items-center list-inline m-0 p-0">
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ປະເພດເອກະສານ
                    </label>
                    <select
                      className="form-control shadow-none p-3 W-100"
                      id="exampleFormControlSelect1"
                      name="typeDocument"
                      onChange={handleChange}
                    >
                      <option>ເລືອກປະເພດເອກະສານ</option>
                      {listTypeDocument.map((item, index) => (
                        <option
                          key={index}
                          value={item._id}
                          selected={data?.typeDocument?._id === item?._id}
                        >
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </li>
                <li className="col-md-12 mb-3">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      ລາຍລະອຽດເພີ່ມເຕີມ
                    </label>
                    <textarea
                      className="form-control shadow-none p-3 W-100"
                      rows="4"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </li>
                <li className="col-md-12 mb-3">
                  <div style={{ textAlign: "center" }}>
                    <input
                      type="file"
                      id="file-upload"
                      onChange={handleUpload}
                      accept=".doc,.docx,.xls,.xlsx,.ppt,pptx,.pdf, image/*"
                      hidden
                    />
                    <label for="file-upload">
                      <div>
                        {file ? (
                          <div className="card bg-transparent shadow-none">
                            <div className="iq-dashed-border align-items-center justify-content-center">
                              {disPlayFiles(fileNames, "", 150, 150)}
                            </div>
                          </div>
                        ) : (
                          <div className="card bg-transparent shadow-none">
                            <div className="iq-dashed-border">
                              <div className="align-items-center justify-content-center p-5">
                                <svg
                                  className="icon-42 text-primary"
                                  width="42"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    opacity="0.4"
                                    d="M2 7.916V16.084C2 19.623 4.276 22 7.665 22H16.335C19.724 22 22 19.623 22 16.084V7.916C22 4.378 19.723 2 16.334 2H7.665C4.276 2 2 4.378 2 7.916Z"
                                    fill="currentColor"
                                  ></path>
                                  <path
                                    d="M7.72033 12.8555L11.4683 16.6205C11.7503 16.9035 12.2493 16.9035 12.5323 16.6205L16.2803 12.8555C16.5723 12.5615 16.5713 12.0865 16.2773 11.7945C15.9833 11.5025 15.5093 11.5025 15.2163 11.7965L12.7493 14.2735V7.91846C12.7493 7.50346 12.4133 7.16846 11.9993 7.16846C11.5853 7.16846 11.2493 7.50346 11.2493 7.91846V14.2735L8.78333 11.7965C8.63633 11.6495 8.44433 11.5765 8.25133 11.5765C8.06033 11.5765 7.86833 11.6495 7.72233 11.7945C7.42933 12.0865 7.42833 12.5615 7.72033 12.8555Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                                <h4 className="text-body mb-0">ອັບໂຫຼດໄຟລ໌</h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                </li>
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary btn-lg "
                onClick={() => handleSubmit()}
              >
                <i className="icon-check-circle me-1" />
                ບັນທຶກ
              </button>
              <button
                type="button"
                className="btn btn-light btn-lg "
                onClick={() => handleClose()}
              >
                <i className="icon-x me-2" />
                ຍົກເລີກ
              </button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      )}
    </Formik>
  );
}
