/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { gcp_docs, getStaffLogin, socketServer } from "../../helper";
import { QUERY_GROUP_INVITE } from "./apollo";
import NewGroup from "./newGroup";
import bg from "../../img/top-header3.png";
import useRouter from "use-react-router";
import GroupCountTotal from "./groupCount";
import PreviewImage from "../../helper/previewImage";
export default function DocumentsGroup() {
  const _user = getStaffLogin().data;
  const [searchValue, setSearchValue] = useState("");
  const { history } = useRouter();
  const [newLoadData, setNewLoadData] = useState(false);
  const [dataInvite_group, setInviteGroup] = useState([]);

  const [queryGroupInvite, { data: resultGroupInvite }] = useLazyQuery(
    QUERY_GROUP_INVITE,
    { fetchPolicy: "cache-and-network" }
  );

  useEffect(() => {
    queryGroupInvite({
      variables: {
        where: {
          userID: _user?._id,
        },
        orderBy: "createdAt_DESC",
      },
    });
  }, [newLoadData]);

  useEffect(() => {
    const results = resultGroupInvite?.documentGroupInvites?.data;
    if (searchValue) {
      const filter = results?.filter((obj) => {
        if (obj?.groupID?.title?.includes(searchValue)) {
          return obj;
        }
      });
      setInviteGroup(filter);
    } else {
      setInviteGroup(results);
    }
  }, [resultGroupInvite, searchValue]);

  const total = resultGroupInvite?.documentGroupInvites?.total;
  
  useEffect(() => {
    socketServer.on("groupInvite", (data) => {
      setNewLoadData(!newLoadData);
    });
    socketServer.on("removeGroupInvite", (data) => {
      setNewLoadData(!newLoadData);
    });
  }, []);

  return (
    <>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <h3>ຈັດການກຸ່ມຂໍ້ມູນເອກະສານ</h3>
        <hr />
        <div className="row d-grid gap-3 d-grid-template-1fr-19">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-0 text-primary">
              ຈັດການກຸ່ມທັງໝົດ {total || 0} ກຸ່ມ
            </h4>
            <ul
              className="nav nav-pills mb-0 iq-product-filter d-flex bg-transparent align-items-center"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item dropdown d-none d-xl-block me-3">
                <div className="form-group input-group mb-0 search-input w-100">
                  <div className="dropdown me-2">
                    <input
                      type="text"
                      className="form-control w-100"
                      placeholder="ຊື່ກຸ່ມ ຫຼື ລະຫັດກຸ່ມ"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </div>
                  <span className="input-group-text">
                    <svg
                      className="icon-20"
                      width={60}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="11.7669"
                        cy="11.7666"
                        r="8.98856"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.0186 18.4851L21.5426 22"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </li>
              <li className="nav-item dropdown d-none d-xl-block">
                <NewGroup
                  newLoad={newLoadData}
                  setNewLoad={(res) => setNewLoadData(res)}
                />
              </li>
            </ul>
          </div>
          <div className="row">
            {dataInvite_group &&
              dataInvite_group.map((item) => (
                <>
                  <div className="col-12 col-lg-3 mb-2">
                    <div className="card mb-0 group-card">
                      <div className="top-bg-image">
                        <img
                          src={`${bg}`}
                          className="img-fluid w-100 rounded-0 rounded-top"
                          alt="group-bg"
                          loading="lazy"
                          style={{ height: "130px" }}
                        />
                      </div>
                      <div className="card-body text-center">
                        <div className="group-icon" style={{ marginTop: -70 }}>
                          <PreviewImage
                            image={gcp_docs + item?.groupID?.profileImage}
                            className={
                              "rounded-circle img-fluid avatar-120 bg-soft-primary"
                            }
                          />
                        </div>
                        <div className="group-info pt-3 pb-3">
                          <h4 className="fw-bolder text-primary">
                            {item?.groupID?.title}
                          </h4>
                        </div>
                        <GroupCountTotal groupID={item?.groupID?._id} />
                        <a
                          href="javascript:void(0)"
                          className="btn btn-secondary d-block w-100 "
                          onClick={() =>
                            history.push(
                              `/document_group/detail/${item?.groupID?._id}`
                            )
                          }
                        >
                          <i className="fa-solid fa-users me-1" />
                          ເຂົ້າຮ່ວມ
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* ) : null} */}
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
