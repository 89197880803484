import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  disPlayFiles,
  formatDateDash,
  getStaffLogin,
  messageError,
  messageSuccess,
} from "../../helper";
import { INBOX_SCREEN } from "../../Routes/app";
import {
  QUERY_FOR_WAR_DOCUMENTS,
  UPDATE_FOR_WAR_DOCUMENT,
} from "../send/apollo";
import useReactRouter from "use-react-router";
import Notiflix from "notiflix";
import { CodeSharp } from "@material-ui/icons";
import InboxDocumentsList from ".";
import InboxPrivateList from ".";

export default function ConfirmDocument() {
  const { history } = useReactRouter();
  const _user = getStaffLogin().data;
  const [newLoadData, setNewLoadData] = useState(false);
  const [listConfirm, setListConfirm] = useState([]);
  const [listPrivate, setListConfirmPrivate] = useState([]);
  const [updateForwar] = useMutation(UPDATE_FOR_WAR_DOCUMENT);
  const [queryFolder, { data: resultData }] = useLazyQuery(
    QUERY_FOR_WAR_DOCUMENTS,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [queryPrivate, { data: resultPrivate }] = useLazyQuery(
    QUERY_FOR_WAR_DOCUMENTS,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  useEffect(() => {
    queryFolder({
      variables: {
        where: {
          status: 0,
          department: parseInt(_user?.department?._id),
          isPrivate: 0,
        },
        limit: 500,
        orderBy: "createdAt_DESC",
      },
    });
    queryPrivate({
      variables: {
        where: {
          status: 0,
          receiptBy: parseInt(_user?._id),
          isPrivate: 1,
        },
        limit: 500,
        orderBy: "createdAt_DESC",
      },
    });
  }, [newLoadData]);

  useEffect(() => {
    if (resultData) {
      setListConfirm(resultData?.forwardDocuments?.data);
    }
  }, [resultData]);

  useEffect(() => {
    if (resultPrivate) {
      setListConfirmPrivate(resultPrivate?.forwardDocuments?.data);
    }
  }, [resultPrivate]);

  const total = resultData?.forwardDocuments?.total || 0;
  const totalPrivate = resultPrivate?.forwardDocuments?.total || 0;

  const _handleUpdate = (item) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການຢືນຢັນ ນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _updateForwar = await updateForwar({
            variables: {
              data: {
                status: 1,
                receiptDate: formatDateDash(new Date()),
              },
              where: {
                _id: parseInt(item?._id),
              },
            },
          });
          if (_updateForwar) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            if (item?.isPrivate === 0) {
              history.push(`/inbox`);
            } else {
              history.push(`/inbox_isPrivate`);
            }
            setNewLoadData(!newLoadData);
          }
        } catch (error) {
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <>
      <li className="nav-item dropdown">
        <a
          href="#"
          className="nav-link ps-3"
          id="notification-drop"
          data-bs-toggle="dropdown"
        >
          <div className="btn btn-primary btn-icon btn-sm rounded-pill btn-action hvr-pulse">
            <span className="btn-inner">
              <i className="icon-bell" />
            </span>
          </div>
          {total + totalPrivate !== 0 ? (
            <div
              className="spinner-grow spinner-grow-sm text-success"
              role="status"
            >
              <span className="sr-only" />
            </div>
          ) : null}
        </a>

        <div
          className="p-0 sub-drop dropdown-menu dropdown-menu-end"
          aria-labelledby="notification-drop"
        >
          <div className="m-0 shadow-none card">
            <div className="py-3 card-header d-flex justify-content-between bg-primary">
              <div className="header-title">
                <h5 className="mb-0 text-white">
                  <i class="fa-solid fa-envelope me-2" />
                  ເອກະສານໃໝ່
                </h5>
              </div>
            </div>
            <div className="p-0 card-body max-17 scroll-thin">
              {listConfirm?.map((item, index) => (
                <div className="iq-sub-card">
                  <div className="d-flex align-items-center">
                    {disPlayFiles(item?.document?.files)}
                    <div className="ms-3 flex-grow-1">
                      <h6 className="mb-0">{item?.description}</h6>
                      <div className="align-items-center">
                        <p className="mb-0">{formatDateDash(item?.sendDate)}</p>
                        {item?.isPrivate === 0 ? (
                          <small className="mb-0 text-success">
                            ເອກະສານທົ່ວໄປ
                          </small>
                        ) : (
                          <small className="mb-0 text-danger">
                            ເອກະສານສ່ວນຕົວ
                          </small>
                        )}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-icon text-success btn-sm hvr-pulse"
                      onClick={() => {
                        _handleUpdate(item);
                      }}
                    >
                      <i class="fa-solid fa-circle-check fs-3"></i>
                    </button>
                  </div>
                </div>
              ))}

              {listPrivate?.map((item, index) => (
                <div className="iq-sub-card">
                  <div className="d-flex align-items-center">
                    {disPlayFiles(item?.document?.files)}
                    <div className="ms-3 flex-grow-1">
                      <h6 className="mb-0">{item?.description}</h6>
                      <div className="align-items-center">
                        <p className="mb-0">{formatDateDash(item?.sendDate)}</p>
                        {item?.isPrivate === 0 ? (
                          <small className="mb-0 text-success">
                            ເອກະສານທົ່ວໄປ
                          </small>
                        ) : (
                          <small className="mb-0 text-danger">
                            ເອກະສານສ່ວນຕົວ
                          </small>
                        )}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-icon text-success btn-sm hvr-pulse"
                      onClick={() => {
                        _handleUpdate(item);
                      }}
                    >
                      <i class="fa-solid fa-circle-check fs-3"></i>
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="card-footer p-0 text-center">
              <div className="d-grid">
                <a
                  onClick={() => history.push(INBOX_SCREEN)}
                  className="btn btn-primary hvr-shutter-out-horizontal"
                >
                  <i class="fa-solid fa-right-to-bracket me-2" />
                  ກ່ອງຮັບເອກະສານ
                </a>
              </div>
            </div>
          </div>
        </div>
      </li>
    </>
  );
}
