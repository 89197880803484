import { gql } from "apollo-boost";

export const QUERY_FOR_WAR_DOCUMENTS = gql`
query ForwardDocuments($where: ForwardDocumentWhereInput, $orderBy: OrderByInput, $skip: Int, $limit: Int) {
  forwardDocuments(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
    total
    data {
      _id
      description
      status
      hash
      isPrivate
      sendDate
      receiptDate
      document {
        _id
        title
        files
        documentID
      }
      department {
        _id
        title_lao
      }
      receiptBy {
        _id
        firstName
        lastName
      }
      sendBy {
        _id
        firstName
        lastName
      }
    }
  }
}
`;
export const CREATE_FOR_WAR_DOCUMENT = gql`
  mutation CreateForwardDocument($data: ForwardDocumentInput!) {
    createForwardDocument(data: $data) {
      _id
    }
  }
`;

export const UPDATE_FOR_WAR_DOCUMENT = gql`
  mutation UpdateForwardDocument($data: ForwardDocumentInput!, $where: ForwardDocumentWhereInputOne!) {
    updateForwardDocument(data: $data, where: $where) {
      _id
    }
  }
`;

export const DELETE_FOR_WAR_DOCUMENT = gql`
  mutation DeleteForwardDocument($where: ForwardDocumentWhereInputOne!) {
    deleteForwardDocument(where: $where) {
      _id
    }
  }
`;
