import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  aws_url_emp,
  messageError,
  messageSuccess,
  messageWarning,
  placeholderImage,
  socketServer,
} from "../../helper";
import { DELETE_GROUP_INVITE, QUERY_GROUP_INVITE } from "./apollo";

export default function DeleteAdmin({ groupID, newLoad, setNewLoad }) {
  const [listGroupInvite, setListGroupInvite] = useState([]);
	const [searchValue, setSearchValue] = useState("");
  const [deleteGroupInvite] = useMutation(DELETE_GROUP_INVITE);
  const [queryGroupInvite, { data: resultGroupInvite }] = useLazyQuery(
    QUERY_GROUP_INVITE,
    { fetchPolicy: "cache-and-network" }
  );

  useEffect(() => {
    queryGroupInvite({
      variables: {
        where: {
          groupID: groupID,
        },
        limit: 1000,
        orderBy: "createdAt_DESC",
      },
    });
  }, [groupID, newLoad]);

	useEffect(() => {
    const results = resultGroupInvite?.documentGroupInvites?.data;
    if (searchValue) {
      const filter = results?.filter((obj) => {
        if (obj?.userID?.firstName?.includes(searchValue)) {
          return obj;
        }
      });
      setListGroupInvite(filter);
    } else {
      setListGroupInvite(results);
    }
  }, [resultGroupInvite, searchValue]);

  //ຍົກເລິກການເຂົ້າກຸ່ມ
  const _handleCancelInvite = async (id) => {
    try {
      let __deleteGroupInvite = await deleteGroupInvite({
        variables: {
          data: {
            status: "DELETE",
          },
          where: {
            _id: id,
          },
        },
      });
      if (__deleteGroupInvite) {
        messageSuccess("ການຍົກເລີກສຳເລັດ");
        setNewLoad(!newLoad);
      } else {
        messageError("ລະບົບມີຂໍ້ຄັດຂ້ອງ");
      }
    } catch (error) {
      messageWarning("!ທ່ານບໍ່ມີສິດລືບຂໍ້ມູນນີ້");
    }
  };

  useEffect(() => {
    socketServer.on("groupInvite", (data) => {
      setNewLoad(!newLoad);
    });
    socketServer.on("removeGroupInvite", (data) => {
      setNewLoad(!newLoad);
    });
  }, []);

  return (
    <React.Fragment>
      <li className="mb-3 pb-3 border-bottom">
        <div className="nav">
          <div className="form-group input-group mb-0 search-input w-100">
            <input
              type="text"
              className="form-control"
              placeholder="ຄົ້ນຫາ..."
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <span className="input-group-text">
              <svg
                className="icon-20"
                width={20}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11.7669"
                  cy="11.7666"
                  r="8.98856"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.0186 18.4851L21.5426 22"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
      </li>
      {listGroupInvite?.map((item, index) => (
        <li className="mb-3 d-flex align-items-center gap-3">
          <table style={{ width: "100%" }} className="mb-3" key={index}>
            <tr>
              <td width={50}>
                <img
                  src={
                    item?.userID?.profileImage
                      ? aws_url_emp + item?.userID?.profileImage
                      : placeholderImage(100)
                  }
                  alt=""
                  className="avatar-40 rounded-circle"
                />
              </td>
              <td>{item?.userID?.firstName + " " + item?.userID?.lastName}</td>
              <td>
                <span className="text-success">
                  {item?.isAdmin ? "Admin" : ""}
                </span>
              </td>
              <td>
                <a
                  href="javascript:;"
                  className="float-end"
                  onClick={() => _handleCancelInvite(item?._id)}
                >
                  <i className="icon-user-minus me-1 fs-4" />
                </a>
              </td>
            </tr>
          </table>
        </li>
      ))}
    </React.Fragment>
  );
}
