/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import useReactRouter from "use-react-router";

import {
  gcp_docs,
  formatDateDash,
  getStaffLogin,
  ITEM_PER_PAGE,
  setParams,
  nextSevenDay,
  nextOneDay,
  messageError,
  messageSuccess,
  DisPlayFiles,
} from "../../helper";
import { DELETE_DOCUMENT, QUERY_DOCUMENTS } from "./apollo";
import AddDocument from "./addDocument";
import Pagination from "../../helper/components/pagination/Pagination";
import EditDocument from "./editDocument";
import Notiflix from "notiflix";
import ListDocumentType from "../../helper/components/pagination/DocumentType";

export default function DocumentsList() {
  const _user = getStaffLogin().data;
  const { history, location } = useReactRouter();
  const queryParams = new URLSearchParams(location?.search);
  const [searchValue, setSearchValue] = useState("");
  const [newLoadData, setNewLoadData] = useState(false);
  const [listAll, setListAll] = useState();
  const [documentType, setDocumentType] = useState([]);
  const [startDate, setStartDate] = useState(nextSevenDay());
  const [endDate, setEndDate] = useState(nextOneDay());
  const [numberPage, setNumberPage] = useState(1);
  const [numberRow, setNumberRow] = useState(ITEM_PER_PAGE);
  const [deleteDoc] = useMutation(DELETE_DOCUMENT);
  const [factAll, { data: resultAll }] = useLazyQuery(QUERY_DOCUMENTS, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    factAll({
      variables: {
        where: {
          departmentID: parseInt(_user?.department._id),
          isPrivate: 0,
          createdAt_gte: startDate ? startDate : undefined,
          createdAt_lte: endDate ? endDate : undefined,
          isDeleted: 0,
          isPublic: 1,
          typeDocument: documentType?._id ? documentType?._id : undefined,
        },
        skip: searchValue ? 0 : numberRow * (numberPage - 1),
        limit: searchValue ? 1000 : numberRow,
        orderBy: "createdAt_DESC",
      },
    });
  }, [startDate, endDate, numberPage, numberRow, newLoadData, documentType]);

  useEffect(() => {
    const results = resultAll?.documents?.data;
    if (searchValue) {
      const filter = results?.filter((obj) => {
        if (
          obj?.documentID?.includes(searchValue) ||
          obj?.title?.includes(searchValue)
        ) {
          return obj;
        }
      });
      setListAll(filter);
    } else {
      setListAll(results);
    }
  }, [resultAll, searchValue]);

  // page
  useEffect(() => {
    let page = queryParams.get("page");
    let rows = queryParams.get("rows");
    let startDate = queryParams.get("startDate");
    let endDate = queryParams.get("endDate");
    if (page) {
      setNumberPage(parseInt(page));
    } else {
      setNumberPage(1);
    }
    if (rows) {
      setNumberRow(parseInt(rows));
    } else {
      setNumberRow(ITEM_PER_PAGE);
    }
    if (startDate) {
      setStartDate(startDate);
    } else {
      setStartDate(nextSevenDay());
    }
    if (endDate) {
      setEndDate(endDate);
    } else {
      setEndDate(nextOneDay());
    }
  }, [queryParams]);

  const countData = resultAll?.documents?.total;
  const countPage = [];
  for (var i = 1; i <= Math.ceil(countData / numberRow); i++) {
    countPage.push(i);
  }

  const _deleteDocument = (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການລຶບຂໍ້ມູນ ນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _deleteDoc = await deleteDoc({
            variables: {
              where: {
                _id: parseInt(id),
              },
            },
          });
          if (_deleteDoc) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            setNewLoadData(!newLoadData);
          }
        } catch (error) {
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <h3>ຂໍ້ມູນເອກະສານທົ່ວໄປ</h3>
        <hr />
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <p className="mb-md-0 mb-2 d-flex align-items-center ">
                  <div className="dropdown me-2">
                    <AddDocument
                      newLoad={newLoadData}
                      setNewLoad={setNewLoadData}
                    />
                  </div>
                </p>
                <div className="d-flex align-items-center flex-wrap mt-3">
                  <div className="dropdown me-2">
                    <ListDocumentType
                      getData={(data) => {
                        setDocumentType(data);
                      }}
                      defaultValue={documentType?.title}
                    />
                  </div>
                  <div className="dropdown me-2">
                    <input
                      type="text"
                      className="form-control w-100"
                      placeholder="ຊື່ເອກະສານ ຫຼື ລະຫັດເອກະສານ"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </div>
                  <div className="dropdown me-1">
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control"
                        value={startDate}
                        onChange={(e) =>
                          history.push({
                            search: setParams(`startDate`, e.target.value),
                          })
                        }
                      />
                      <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) =>
                          history.push({
                            search: setParams(`endDate`, e.target.value),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="dropdown me-1">
                    <select
                      className="form-select"
                      onChange={(e) =>
                        history.push({
                          search: setParams(`rows`, e.target.value),
                        })
                      }
                    >
                      <option
                        value={10}
                        selected={numberRow === 10 ? true : false}
                      >
                        10 ລາຍການ
                      </option>
                      <option
                        value={50}
                        selected={numberRow === 50 ? true : false}
                      >
                        50 ລາຍການ
                      </option>
                      <option
                        value={100}
                        selected={numberRow === 100 ? true : false}
                      >
                        100 ລາຍການ
                      </option>
                      <option
                        value={500}
                        selected={numberRow === 500 ? true : false}
                      >
                        500 ລາຍການ
                      </option>
                      <option
                        value={1000}
                        selected={numberRow === 1000 ? true : false}
                      >
                        1000 ລາຍການ
                      </option>
                    </select>
                  </div>
                  <div className="dropdown me-3 pt-2">
                    <Pagination
                      className="mt-2"
                      pageTotal={countPage}
                      currentPage={numberPage}
                      onPageChange={(page) => {
                        history.push({
                          search: setParams(`page`, page),
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 className="my-3">ເອກະສານທັງໝົດ {countData} ໄຟລ໌</h3>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
          {listAll &&
            listAll?.map((item) =>
              item?.files.split(".").pop() === "pdf" ||
              item?.files.split(".").pop() === "presentation" ||
              item?.files.split(".").pop() === "pptx" ||
              item?.files.split(".").pop() === "ppt" ||
              item?.files.split(".").pop() === "pptm" ||
              item?.files.split(".").pop() === "document" ||
              item?.files.split(".").pop() === "docx" ||
              item?.files.split(".").pop() === "doc" ||
              item?.files.split(".").pop() === "docm" ||
              item?.files.split(".").pop() === "dotx" ||
              item?.files.split(".").pop() === "xlsx" ||
              item?.files.split(".").pop() === "xls" ||
              item?.files.split(".").pop() === "xlsm" ||
              item?.files.split(".").pop() === "sheet" ? (
                <div className="col">
                  <div className="card iq-file-manager">
                    <div className="card-body card-thumbnail">
                      <div className="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
                        <DisPlayFiles
                          file={item?.files}
                          className="file"
                          width={100}
                          height={100}
                          item={item}
                        />
                      </div>
                      <div className="mt-1">
                        <div className="d-flex justify-content-between">
                          <p className="small">
                            <i className="icon-clock me-1" /> ວັນທີ່ສ້າງ:{" "}
                            {formatDateDash(item?.createdAt)}
                          </p>
                          <div className="btn-group">
                            <EditDocument
                              data={item}
                              newLoad={newLoadData}
                              setNewLoad={(res) => setNewLoadData(res)}
                            />
                            <a
                              href="javascript:void();"
                              className="ms-3 btn btn-sm btn-icon rounded-circle btn-outline-danger"
                              onClick={() => _deleteDocument(item?._id)}
                            >
                              <i className="icon-trash" />
                            </a>
                          </div>
                        </div>
                        <div className="d-flex align-items-center text-primary gap-2 mb-2">
                          <i className="icon-unfold_more" />
                          <p className="mb-0 text-primary fw-bolder me-3">
                            {item?.documentID
                              ? item?.documentID
                              : "No Document ID"}
                          </p>
                        </div>
                        <div className="d-flex align-items-center text-primary gap-2 mb-2">
                          <i className="icon-text_fields" />
                          <p className=" mb-0 text-dark me-3">
                            {item?.title?.substring(25)
                              ? item?.title?.substring(0, 25) + "..."
                              : item?.title}
                          </p>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                          <a
                            className="btn btn-secondary btn-sm w-100 rounded-pill"
                            href={gcp_docs + item?.files}
                            target="_blank"
                          >
                            <i class="icon-open_in_new me-1" />
                            ເປີດໄຟລ໌
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              ) : null
            )}
        </div>
        <h4 className="py-3 mb-0">ຮູບພາບ</h4>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
          {listAll &&
            listAll?.map((item) =>
              item?.files.split(".").pop() === "jpeg" ||
              item?.files.split(".").pop() === "jpg" ||
              item?.files.split(".").pop() === "png" ||
              item?.files.split(".").pop() === "gif" ||
              item?.files.split(".").pop() === "svg" ? (
                <div className="col">
                  <div className="card iq-file-manager">
                    <div className="card-body card-thumbnail">
                      <div className="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
                        <DisPlayFiles
                          file={item?.files}
                          className="file"
                          width={100}
                          height={100}
                          item={item}
                        />
                      </div>
                      <div className="mt-2">
                        <div className="d-flex justify-content-between">
                          <p className="small">
                            <i className="icon-clock me-1" /> ວັນທີ່ສ້າງ:{" "}
                            {formatDateDash(item?.createdAt)}
                          </p>
                          <div className="btn-group">
                            <EditDocument
                              data={item}
                              newLoad={newLoadData}
                              setNewLoad={(res) => setNewLoadData(res)}
                            />
                            <a
                              href="javascript:void();"
                              className="ms-3 btn btn-sm btn-icon rounded-circle btn-outline-danger"
                              onClick={() => _deleteDocument(item?._id)}
                            >
                              <i className="icon-trash" />
                            </a>
                          </div>
                        </div>
                        <div className="d-flex align-items-center text-primary gap-2 mb-2">
                          <i className="icon-unfold_more" />
                          <p className="mb-0 text-primary fw-bolder me-3">
                            {item?.documentID
                              ? item?.documentID
                              : "No Document ID"}
                          </p>
                        </div>
                        <div className="d-flex align-items-center text-primary gap-2 mb-2">
                          <i className="icon-text_fields" />
                          <p className=" mb-0 text-dark me-3">
                            {item?.title?.substring(30)
                              ? item?.title?.substring(0, 30) + "..."
                              : item?.title}
                          </p>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                          <a
                            className="btn btn-secondary btn-sm w-100 rounded-pill"
                            href={gcp_docs + item?.files}
                            target="_blank"
                          >
                            <i class="icon-open_in_new me-1" />
                            ເປີດໄຟລ໌
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              ) : null
            )}
        </div>
      </div>
    </>
  );
}
