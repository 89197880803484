// import { useLazyQuery } from "@apollo/client";
import React from "react";
import placeholder from "../../img/aek.pdf";

export default function User_Manual() {
  return (
    <React.Fragment>
      <a
        href={`${placeholder ? placeholder : placeholder}`}
        className="btn btn-link btn-soft-light"
        target="_blank"
      >
        ຮຽນຮູ້ເພີ່ມເຕີມ
      </a>
    </React.Fragment>
  );
}
