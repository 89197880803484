import { gql } from "apollo-boost";

export const QUERY_FOLDERS = gql`
query DocumentFolders($where: DocumentFolderWhereInput, $orderBy: OrderByInput, $skip: Int, $limit: Int) {
  documentFolders(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
    total
    data {
      _id
      folderNumber
      title
      departmentID {
        _id
        title_lao
      }
      createdAt
      createdBy {
        _id
        firstName
        lastName
      }
      loggerID {
        _id
        title
      }
    }
  }
}
`;
export const CREATE_FOLDER = gql`
mutation CreateDocumentFolder($data: DocumentFolderInput!) {
  createDocumentFolder(data: $data) {
    _id
  }
}
`;

export const UPDATE_FOLDER = gql`
mutation UpdateDocumentFolder($data: DocumentFolderInput!, $where: DocumentFolderWhereInputOne!) {
  updateDocumentFolder(data: $data, where: $where) {
    _id
  }
}
`;

export const DELETE_FOLDER = gql`
mutation DeleteDocumentFolder($where: DocumentFolderWhereInputOne!) {
  deleteDocumentFolder(where: $where) {
    _id
  }
}
`;
