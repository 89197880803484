/* eslint-disable jsx-a11y/anchor-is-valid */
// import { useLazyQuery } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  aws_url_emp,
  getStaffLogin,
  messageError,
  messageSuccess,
  placeholderImage,
} from "../../helper";
import {
  CREATE_GROUP_INVITE,
  QUERY_EMPLOYEE,
  QUERY_GROUP_INVITE,
} from "./apollo";
export default function InviteMemberGroup({ groupID, newLoad, setNewLoad }) {
  const _user = getStaffLogin().data;
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [createGroupInvite] = useMutation(CREATE_GROUP_INVITE);
  const [listMember, setListMembers] = useState([]);
  const [_admin, setAdmin] = useState([]);

  const [listGroupInvite, setListGroupInvite] = useState([]);
  const [queryMembers, { data: dataMembers }] = useLazyQuery(QUERY_EMPLOYEE, {
    fetchPolicy: "cache-and-network",
  });

  const [queryGroupInvite, { data: resultGroupInvite }] = useLazyQuery(
    QUERY_GROUP_INVITE,
    { fetchPolicy: "cache-and-network" }
  );

  const [queryGroupInviteAdmin, { data: resultGroupInviteAdmin }] = useLazyQuery(
    QUERY_GROUP_INVITE,
    { fetchPolicy: "cache-and-network" }
  );

  useEffect(() => {
    queryMembers({
      variables: {
        where: {
          status: "ACTIVE",
          firstName: searchValue ? String(searchValue) : undefined,
        },
        limit: 30,
        orderBy: "createdAt_DESC",
      },
    });
  }, [searchValue, reload]);

  useEffect(() => {
    queryGroupInvite({
      variables: {
        where: {
          groupID: groupID
        },
        limit: 1000,
        orderBy: "createdAt_DESC",
      },
    });
  }, [groupID, newLoad]);

  useEffect(() => {
    queryGroupInviteAdmin({
      variables: {
        where: {
          groupID: groupID,
          userID: _user?._id,
          isAdmin: 1,
        },
        limit: 1,
      },
    });
  }, [groupID, newLoad]);

  useEffect(() => {
    if (dataMembers) {
      setListMembers(dataMembers?.employees?.data);
    }
  }, [dataMembers]);


  useEffect(() => {
    if (resultGroupInviteAdmin)
      setAdmin(resultGroupInviteAdmin?.documentGroupInvites?.data[0]);
  }, [resultGroupInviteAdmin]);

  useEffect(() => {
    if (resultGroupInvite) {
      setListGroupInvite(resultGroupInvite.documentGroupInvites?.data);
    }
  }, [resultGroupInvite]);

  const _checkInvited = (item) => {
    let check = "";
    listGroupInvite.filter((res) => {
      if (res.userID._id === item) {
        check = "none";
      }
    });
    return check;
  };

  const _handleInvite = async (id) => {
    try {
      let __createGroupInvite = await createGroupInvite({
        variables: {
          data: {
            groupID: groupID,
            userID: id,
          },
        },
      });

      if (__createGroupInvite) {
        messageSuccess("ການດຳເນີນງານສຳເລັດ");
        setNewLoad(!newLoad);
        setReload(!reload);
      } else {
        messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
      }
    } catch (error) {
      messageError("ການເຂົ້າລະບົບມີຄວາມຂັດຂ້ອງ");
    }
  };

  return (
    <React.Fragment>
      {_admin?.isAdmin === 1 && (
        <button
          type="submit"
          className="btn btn-primary d-flex 
            align-items-center gap-2 
            hvr-shutter-out-horizontal"
          onClick={() => setShow(true)}
        >
          <svg
            width={20}
            className="icon-20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M16.6667 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0622 3.92 22 7.33333 22H16.6667C20.0711 22 22 20.0622 22 16.6667V7.33333C22 3.92889 20.0711 2 16.6667 2Z"
              fill="currentColor"
            />
            <path
              d="M15.3205 12.7083H12.7495V15.257C12.7495 15.6673 12.4139 16 12 16C11.5861 16 11.2505 15.6673 11.2505 15.257V12.7083H8.67955C8.29342 12.6687 8 12.3461 8 11.9613C8 11.5765 8.29342 11.2539 8.67955 11.2143H11.2424V8.67365C11.2824 8.29088 11.6078 8 11.996 8C12.3842 8 12.7095 8.29088 12.7495 8.67365V11.2143H15.3205C15.7066 11.2539 16 11.5765 16 11.9613C16 12.3461 15.7066 12.6687 15.3205 12.7083Z"
              fill="currentColor"
            />
          </svg>
          ເພີ່ມສະມາຊິກ
        </button>
      )}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        centered
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">
            <svg
              className="icon-30 me-2"
              width="30"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z"
                fill="currentColor"
              ></path>
              <path
                opacity="0.4"
                d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z"
                fill="currentColor"
              ></path>
              <path
                opacity="0.4"
                d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z"
                fill="currentColor"
              ></path>
              <path
                d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z"
                fill="currentColor"
              ></path>
              <path
                opacity="0.4"
                d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z"
                fill="currentColor"
              ></path>
              <path
                d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z"
                fill="currentColor"
              ></path>
            </svg>{" "}
            ເພີ່ມສະມາຊິກກຸ່ມ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card-body">
            <ul className="list-inline p-0 m-0">
              <li className="mb-3 pb-3 border-bottom">
                <div className="nav">
                  <div className="form-group input-group mb-0 search-input w-100">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ຄົ້ນຫາ..."
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <span className="input-group-text">
                      <svg
                        className="icon-20"
                        width={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="11.7669"
                          cy="11.7666"
                          r="8.98856"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.0186 18.4851L21.5426 22"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </li>
              <li className="mb-3">
                {listMember?.map((item, index) => (
                  <table style={{ width: "100%" }} className="mb-3">
                    <tr>
                      <td width={50}>
                        <img
                          src={
                            item?.profileImage
                              ? aws_url_emp + item?.profileImage
                              : placeholderImage(100)
                          }
                          alt=""
                          className="avatar-40 rounded-circle"
                        />
                      </td>
                      <td>{item?.firstName + " " + item?.lastName}</td>
                      <td>
                        {_checkInvited(item?._id) === "none" ? (
                          <a
                            href="javascript:;"
                            className="text-primary float-end"
                          >
                            <i className="icon-user-minus me-1 fs-4" />{" "}
                          </a>
                        ) : (
                          <a
                            href="javascript:;"
                            className="text-success float-end"
                            onClick={() => _handleInvite(item?._id)}
                          >
                            <i className="icon-user-plus me-1 fs-4" />{" "}
                          </a>
                        )}
                      </td>
                    </tr>
                  </table>
                ))}
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
