/* eslint-disable jsx-a11y/anchor-is-valid */
// import { useLazyQuery } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { aws_url_emp, placeholderImage } from "../../helper";
import { QUERY_DOCUMENTS } from "../documents/apollo";
import { QUERY_GROUP_INVITE } from "./apollo";
export default function GroupCountTotal({ groupID, newLoad, setAcountReturn }) {
	const [listGroupInvite, setListGroupInvite] = useState([]);

	const [queryDocument, { data: resultDocument }] =
		useLazyQuery(QUERY_DOCUMENTS, { fetchPolicy: "cache-and-network" });

	const [queryGroupInvite, { data: resultGroupInvite }] = useLazyQuery(
		QUERY_GROUP_INVITE,
		{ fetchPolicy: "cache-and-network" }
	);
	useEffect(() => {
		queryGroupInvite({
			variables: {
				where: {
					groupID: groupID
				},
				limit: 1000,
				orderBy: "createdAt_DESC",
			},
		});

		queryDocument({
			variables: {
				where: {
					groupID: groupID
				},
				limit: 1000,
				orderBy: "createdAt_DESC",
			},
		});

	}, [groupID, newLoad]);

	useEffect(() => {
		if (resultGroupInvite) {
			setListGroupInvite(resultGroupInvite?.documentGroupInvites?.data);
		}
	}, [resultGroupInvite]);

	// useEffect(() => {
	// 	if(!setAcountReturn) return;
	// 		setAcountReturn({acount: resultGroupInvite?.documentGroupInvites?.data});
	// 	}, [resultGroupInvite]);

	const totalDocument = resultDocument?.documents?.total || 0;
	const total = resultGroupInvite?.documentGroupInvites?.total || 0;
	return (
		<>
			<div className="group-details d-inline-block pb-3">
				<ul className="d-flex align-items-center justify-content-between list-inline m-0 p-0 gap-3">
					<li>
						<p className="mb-0">ເອກະສານ</p>
						<h6>{totalDocument}</h6>
					</li>
					<li>
						<p className="mb-0">ສະມາຊິກ</p>
						<h6>{total}</h6>
					</li>
				</ul>
			</div>
			<div className="group-member mb-3">
				<div className="iq-media-group">
					{listGroupInvite.map((item, index) => (
						<a href="#" className="iq-media" key={index}>
							<img
								className="img-fluid avatar-40 rounded-circle"
								src={
									item?.userID?.profileImage
										? aws_url_emp + item?.userID?.profileImage
										: placeholderImage(100)
								}
								alt=""
								loading="lazy"
							/>
						</a>
					))}
				</div>
			</div>
		</>

	);
}
