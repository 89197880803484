// import { useLazyQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import {
  getStaffLogin,
  messageError,
  messageSuccess,
  valiDate,
} from "../../helper";
import { CREATE_FOR_WAR_DOCUMENT } from "./apollo";
import SelectUser from "../../helper/componentsList/SelectUser";
import SelectDocument from "../../helper/componentsList/SelectDocument";
import SelectDepartment from "../../helper/componentsList/SelectDepartment";
export default function SendDocument({ newLoad, setNewLoad }) {
  const _user = getStaffLogin().data;
  const [show, setShow] = useState(false);
  const [listUser, setListUser] = useState();
  const [department, setListDepartment] = useState({});
  const [listDoc, setListDoc] = useState();
  const [_private, setPrivate] = useState(0);
  const [createGroup] = useMutation(CREATE_FOR_WAR_DOCUMENT);

  return (
    <Formik
      initialValues={{
        description: "",
      }}
      enableReinitialize={false}
      validate={(values) => {
        const errors = {};
        if (!values.description) {
          errors.description = "error";
        }
        if (!_private) {
          errors.isPrivate = "ເລືອກສິດການສົ່ງເອກະສານ";
        }
        if (!listDoc?._id) errors.document = "ເລືອກເອກະສານ";
        if (Number(_private) === 0) {
          if (!department?._id) errors.department = "ເລືອກພະແນກ";
        } else {
          if (!listUser?._id) errors.receiptBy = "ເລືອກສະມາຊິກ";
        }
        return errors;
      }
      }

      onSubmit={async (values) => {
        try {
          let __createGroup = await createGroup({
            variables: {
              data: {
                description: String(values?.description),
                document: listDoc?._id,
                original_department: parseInt(_user?.department?._id),
                department: parseInt(_private) === 0 ?  department?._id : 0,
                receiptBy: parseInt(_private) === 1 ? listUser?._id : 0,
                sendBy: _user?._id,
                isPrivate: parseInt(_private),
                status: 0,
              },
            },
          });

          if (__createGroup) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setShow(false);
            setNewLoad(!newLoad);
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການເຂົ້າລະບົບມີຄວາມຂັດຂ້ອງ");
        }
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <React.Fragment>
          <button className="btn btn-primary me-2 hvr-shutter-out-horizontal" onClick={() => setShow(true)}>
            <i className="fa-solid fa-paper-plane me-1" />
            ສົ່ງເອກະສານ
          </button>
          <Modal show={show} onHide={() => setShow(false)} animation={false} centered backdrop="static" size="lg">
            <Modal.Header closeButton>
              <Modal.Title className="fs-5">ຟອມສົ່ງເອກະສານ</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <div className="form-group">
                <label>ເອກະສານ {valiDate()}</label><br />
                <input type="radio"
                  value={0}
                  name="isPrivate"
                  onClick={(e) =>
                    setPrivate(e.target.value)
                  }
                  className=" mr-2 mb-2"
                /><span> ເອກະສານທົ່ວໄປ</span><br />
                <input type="radio"
                  value={1}
                  name="isPrivate"
                  onClick={(e) =>
                    setPrivate(e.target.value)
                  }
                  className=" mr-2 mb-1"
                /><span> ເອກະສານສ່ວນຕົວ</span><br />
                <i className="text-danger">{errors?.isPrivate}</i>
              </div>
              {Number(_private) === 0 ? (
                <div className="form-group">
                  <label>ສົ່ງເຖິງພະແນກ {valiDate()}</label>
                  <SelectDepartment
                    size={"lg"}
                    onChange={(data) => {
                      setListDepartment(data);
                    }}
                    value={department?._id}
                  />
                  <i className="text-danger">{errors?.department}</i>
                </div>
              ) : (
                <div className="form-group">
                  <label>ສົ່ງເຖີງ {valiDate()}</label>
                  <SelectUser
                    size={"lg"}
                    onChange={(data) => {
                      setListUser(data);
                    }}
                    value={listUser?._id}
                  />
                  <i className="text-danger">{errors?.receiptBy}</i>

                </div>
              )}

              <div className="form-group">
                <label>ຫົວເລື່ອງ {valiDate()}</label>
                <textarea
                  type="text"
                  rows={5}
                  className={
                    errors.description
                      ? "form-control form-control-lg mb-3 mt-2 is-invalid"
                      : "form-control form-control-lg mb-3 mt-2 invalid"
                  }
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  placeholder="ປ້ອນຂໍ້ມູນ..."
                />
              </div>
              <div className="form-group">
                <label>ເອກະສານ {valiDate()}</label>
                <SelectDocument
                  size={"lg"}
                  onChange={(data) => {
                    setListDoc(data);
                  }}
                  value={listDoc?._id}
                />
                <i className="text-danger">{errors?.document}</i>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary btn-lg hvr-shutter-out-horizontal"
                onClick={() => handleSubmit()}
              >
                <i className="icon-check-circle me-1" />
                ຢືນຢັນສົ່ງ
              </button>
              <button
                type="button"
                className="btn btn-light btn-lg hvr-shutter-out-horizontal"
                onClick={() => setShow(false)}
              >
                <i className="icon-x me-2" />
                ຍົກເລີກ
              </button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      )}
    </Formik>
  );
}
